<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsEarthSVG2Title">
        <strong> {{ selectedLanguage['earthWorkFill1_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493.49 199.63" id="ValuesParamsEarthSVG3Platform2">
      <title>fill_2lanes</title>
      <g id="Terreno">
        <polyline class="cls-1" points="0 199.63 493.49 199.63 493.49 130.28" />
        <line class="cls-2" x1="417.16" y1="130.38" y2="199.63" />
      </g>
      <g id="Aterro">
        <polyline
          class="cls-3"
          points="7.61 199.45 76.47 127.74 126.37 131 221.29 20.35 398.37 20.35 490.03 131"
        />
      </g>
      <g id="Lastro">
        <polyline
          class="cls-4"
          points="388.27 8.77 398.37 20.35 300.43 20.31 239.09 20.31 221.29 20.35 230.79 8.77 231.01 8.57"
        />
      </g>
      <g id="Dormente">
        <rect class="cls-5" x="247.41" y="8.57" width="45.49" height="4.25" />
        <polyline
          class="cls-5"
          points="324.45 8.32 369.94 8.32 369.94 12.57 324.45 12.57"
        />
      </g>
      <g id="Trilho">
        <polygon
          id="Direita"
          class="cls-6"
          points="289.08 7.09 289.08 8.57 284.18 8.57 284.18 7.09 285.97 7.09 285.97 2.88 285.31 2.88 285.31 0.25 287.94 0.25 287.94 2.88 287.29 2.88 287.29 7.09 289.08 7.09"
        />
        <polygon
          id="Esquerda"
          class="cls-6"
          points="256.31 7.09 256.31 8.57 251.41 8.57 251.41 7.09 253.2 7.09 253.2 2.88 252.54 2.88 252.54 0.25 255.17 0.25 255.17 2.88 254.51 2.88 254.51 7.09 256.31 7.09"
        />
        <polygon
          id="Direita-2"
          data-name="Direita"
          class="cls-6"
          points="366.13 6.84 366.13 8.32 361.23 8.32 361.23 6.84 363.02 6.84 363.02 2.64 362.36 2.64 362.36 0 364.99 0 364.99 2.64 364.34 2.64 364.34 6.84 366.13 6.84"
        />
        <polygon
          id="Esquerda-2"
          data-name="Esquerda"
          class="cls-6"
          points="333.35 6.84 333.35 8.32 328.45 8.32 328.45 6.84 330.25 6.84 330.25 2.64 329.59 2.64 329.59 0 332.22 0 332.22 2.64 331.56 2.64 331.56 6.84 333.35 6.84"
        />
      </g>
      <g id="Medidas">
        <text class="cls-7" transform="translate(33.28 78.6)">{{ paramSelected.values_params.earthwork.fill_height }} m</text>
        <text class="cls-8" transform="translate(90.99 152.72)">{{ paramSelected.values_params.earthwork.fill_enbankment_width }} m</text>
        <text class="cls-7" transform="translate(441.23 45.6)">{{ Math.round(
              (1 / paramSelected.values_params.earthwork.fill_factor) * 10
            ) / 10 }}</text>
        <text class="cls-7" transform="translate(459.43 69.37)">1</text>
        <text class="cls-7" transform="translate(89.23 116.41)">{{ paramSelected.values_params.earthwork.fill_enbankment_slope }} %</text>
        <path
          class="cls-9"
          d="M448.36,96.17V68.78l-21.14,0Z"
          transform="translate(8.07 -18.42)"
        />
        <rect x="61.19" y="130.77" width="6.69" height="0.46" />
        <rect x="61.19" y="20.12" width="6.69" height="0.46" />
        <rect x="76.89" y="122.53" width="48.96" height="0.46" />
        <polygon
          points="124.93 123.55 125.71 122.77 124.93 121.98 125.59 121.98 126.37 122.77 125.59 123.55 124.93 123.55"
        />
        <line class="cls-10" x1="78.41" y1="134.06" x2="125.43" y2="134.13" />
        <polygon
          class="cls-11"
          points="79.4 134.91 78.56 134.06 79.41 133.21 78.69 133.21 77.84 134.06 78.68 134.91 79.4 134.91"
        />
        <polygon
          class="cls-11"
          points="124.43 134.98 125.28 134.13 124.43 133.28 125.15 133.28 126 134.13 125.15 134.98 124.43 134.98"
        />
        <line class="cls-12" x1="64.5" y1="130.06" x2="64.5" y2="21.2" />
        <polygon
          points="65.35 129.06 64.5 129.91 63.65 129.06 63.65 129.78 64.5 130.63 65.35 129.78 65.35 129.06"
        />
        <polygon
          points="65.35 22.2 64.5 21.35 63.65 22.2 63.65 21.48 64.5 20.63 65.35 21.48 65.35 22.2"
        />
        <rect class="cls-11" x="125.92" y="130.71" width="0.46" height="6.69" />
        <rect class="cls-11" x="76.92" y="130.71" width="0.46" height="6.69" />
      </g>
    </svg>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsEarthworkFillHeight">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.earthwork.fill_height"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillFactor">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.earthwork.fill_factor"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text7'] }} </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillEnbankmentSlope">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text8'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.fill_enbankment_slope
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillEnbankmentWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text9'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.fill_enbankment_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["paramSelected","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.cls-1 {
  fill: #3e2d00;
}
.cls-10,
.cls-12,
.cls-2,
.cls-9 {
  fill: none;
}
.cls-3 {
  fill: #875116;
}
.cls-4 {
  fill: #ccc;
}
.cls-5 {
  fill: #666;
}
.cls-6 {
  fill: #3d3d3d;
}
.cls-7,
.cls-8 {
  font-size: 18px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
.cls-11,
.cls-8 {
  fill: #fff;
}
.cls-12,
.cls-9 {
  stroke: #000;
}
.cls-10,
.cls-12,
.cls-9 {
  stroke-miterlimit: 10;
}
.cls-10 {
  stroke: #fff;
}
.cls-10,
.cls-12 {
  stroke-width: 0.5px;
}
</style>