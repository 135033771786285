<template>
    <div>

        <v-app-bar app clipped-left :color="color" dense dark style="overflow-x: auto;">
            <v-app-bar-nav-icon to="/"><v-img :src="logoIconUrl" width="10px" alt="wwcalc"/></v-app-bar-nav-icon>
            <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="capex" @click="setChangedFields(numerosUnicosOrdenados)" v-if="capexChanged === true">Capex</v-tab>
            <v-tab key="expropriation" @click="getRasterBudgetChangesList()" v-if="notInUse === true">{{ selectedLanguage['updateDefaultParams_component_text1'] }}</v-tab>
            <v-tab key="curves" @click="setCurvesTab(listValuesCurve, valuesParamsKeys)" v-if="curvesChanged === true">{{ selectedLanguage['updateDefaultParams_component_text2'] }}</v-tab>
            <v-tab key="platform" @click="setPlatformTab(listValuesPlatform, valuesParamsKeys)" v-if="platformChanged === true">{{ selectedLanguage['updateDefaultParams_component_text3'] }}</v-tab>
            <v-tab key="earthwork" @click="setEarthWTab(listValuesEarthW, valuesParamsKeys)" v-if="earthWorkChanged === true">{{ selectedLanguage['updateDefaultParams_component_text4'] }}</v-tab>
            </v-tabs>
            <v-btn :loading="loading1" text @click="clickSaveExit()">
            {{ selectedLanguage['updateDefaultParams_component_text5'] }}
            </v-btn>
        </v-app-bar>

        <v-alert v-if="loadVersionData == true" border="top" dense color="info" dark>
            <v-progress-circular indeterminate :color="color"></v-progress-circular>
            {{ selectedLanguage['updateDefaultParams_component_text6'] }}
        </v-alert>

        <v-card v-if="paramSelected" class="elevation-0">
        <v-tabs-items v-model="tab">
            <v-tab-item key="capex" v-if="capexChanged === true" style="padding: 30px;">
                    <v-card-text>
                    <v-data-table :headers="capexHeaders" :items="selectedCapex" hide-default-footer disable-pagination item-key="name" dense class="elevation-0">
                        <template v-slot:item="{item, index}">
                        <tr v-if="item.enable" :class="capexItemStyle(item)+' trBody'" style="opacity: 0.2; display: none;" :id="index">
                            <td class="codeS">
                            {{item['Code']}}
                            </td>
                            <td style="height: auto;">
                            {{item['Description']}}
                            </td>
                            <td v-if="item.Unit!=0">
                            {{item['Unit']}}
                            </td>
                            <td v-else>
                            </td>
                            <td v-if="item.Unit!=0">
                            <v-edit-dialog :return-value.sync="item['Unit Cost']" @save="save" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large> {{item['Unit Cost']}}
                                <template v-slot:input>
                                <v-text-field v-model.number="item['Unit Cost']" label="Edit" single-line></v-text-field>
                                </template>
                            </v-edit-dialog>
                            </td>
                            <td v-else>
                            </td>
                            <td v-if="item.Unit!=0">
                            <v-edit-dialog :return-value.sync="item['Risk']" @save="save" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large> {{item['Risk']}}
                                <template v-slot:input>
                                <v-text-field v-model.number="item['Risk']" label="Edit" single-line></v-text-field>
                                </template>
                            </v-edit-dialog>
                            </td>
                            <td v-else>
                            </td>
                        </tr>
                        </template>
                    </v-data-table>
                    </v-card-text>
                </v-tab-item>

            <v-tab-item key="expropriation" @click="getRasterBudgetChangesList()" v-if="notInUse === true" style="padding: 30px;">
                    <v-card-text>
                    <v-data-table :headers="expropriationHeaders" :items="selectedExpropriation" hide-default-footer disable-pagination item-key="Code" dense class="elevation-0">
                        <template v-slot:item="{item, index}">
                        <tr :class="expropriationItemStyle(item)+' trBody'" style="opacity: 0.2; display: none;" :id="'protectClass'+(index-1)">
                            <td class="codeS">
                            {{item['Code']}}
                            </td>
                            <td style="height: auto;">
                            {{item['Description']}}
                            </td>
                            <td>
                            <v-edit-dialog :return-value.sync="item['Cost']" @save="save" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large> {{item['Cost']}}
                                <template v-slot:input>
                                <v-text-field v-model.number="item['Cost']" label="Edit" single-line></v-text-field>
                                </template>
                            </v-edit-dialog>
                            </td>
                        </tr>
                        </template>
                    </v-data-table>
                    </v-card-text>
                </v-tab-item>
            <v-tab-item key="curves" v-if="curvesChanged === true" style="padding: 30px;">
                    <!-- <div style="display: flex; flex-direction: row; justify-content: center;">
                        <div id="curveNewItems" style="display: flex; flex-direction: column; width: 60vw; justify-content: center; align-items: center;"></div>
                    </div> -->
                    <Curves/>
                </v-tab-item>
            <v-tab-item key="platform" v-if="platformChanged === true" style="padding: 30px;">
                    <!-- <div style="display: flex; flex-direction: row; justify-content: center;">
                        <div id="platformNewItems" style="display: flex; flex-direction: column; width: 60vw; justify-content: center; align-items: center;"></div>
                    </div> -->
                <v-card-text>
                    <v-row>
                    <v-col style="align-self: center;" id="ValuesParamsPlatformLanes">
                        <v-select disabled outlined v-model="paramSelected.values_params.platform.lanes" :items="[1,2]" item-text="name" item-value="id" :label="selectedLanguage['railway_params_lable_1']"/>
                    </v-col>
                    </v-row>
                    <PlatformAquisition1Lane v-if="paramSelected.values_params.platform.lanes==1"/>
                    <PlatformAquisition2Lanes v-else/>
                    <br><v-divider id="divider1"/><br>
                    <v-row v-if="paramSelected.values_params.platform.lanes==1">
                    <v-col></v-col>
                    <v-col cols="12">
                        <Platform1Lane />
                    </v-col>
                    <v-col></v-col>
                    </v-row>
                    <v-row v-else>
                    <v-col></v-col>
                    <v-col cols="12">
                        <Platform2Lanes />
                    </v-col>
                    <v-col></v-col>
                    </v-row>
                    <br><v-divider id="divider2"/><br>
                    <PlatformSleepers />
                    <br><v-divider id="divider3"/><br>
                    <PlatformBridge/>
                    <br>
                    <v-col col="12">
                    <v-spacer/>
                    <PlatformBridge1Lane v-if="paramSelected.values_params.platform.lanes==1"/>
                    <PlatformBridge2Lanes v-else/>
                    <v-spacer/>
                    </v-col>
                    <br><v-divider id="divider4"/><br>
                    <PlatformTunnel/>
                    <br><br>
                    <v-col col="12">
                    <v-spacer/>
                    <PlatformTunnel1Lane v-if="paramSelected.values_params.platform.lanes==1"/>
                    <PlatformTunnel2Lanes v-else/>
                    <v-spacer/>
                    </v-col>
                </v-card-text>
                </v-tab-item>
            <v-tab-item key="earthwork" v-if="earthWorkChanged === true" style="padding: 30px;">
                    <!-- <div style="display: flex; flex-direction: row; justify-content: center;">
                        <div id="earthworkNewItems" style="display: flex; flex-direction: column; width: 60vw; justify-content: center; align-items: center;"></div>
                    </div> -->
                <v-card-text>
                    <EarthworkTransport/>
                    <br><v-divider id="divider5"/><br>
                    <EarthworkCut1Lane v-if="paramSelected.values_params.platform.lanes==1"/>
                    <EarthworkCut2Lanes v-else/>
                    <br><v-divider id="divider6"/><br>
                    <EarthworkFill1Lane v-if="paramSelected.values_params.platform.lanes==1"/>
                    <EarthworkFill2Lanes v-else/>
                </v-card-text>
                </v-tab-item>
        </v-tabs-items>
        </v-card>
        </div>
    </template>

    <script>
        import { mapActions, mapGetters } from 'vuex'
        import Curves from './paramsDefaults/Curves.vue'
        import PlatformAquisition1Lane from './paramsDefaults/PlatformAquisition1Lane.vue'
        import PlatformAquisition2Lanes from './paramsDefaults/PlatformAquisition2Lanes.vue'
        import Platform1Lane from './paramsDefaults/Platform1Lane.vue'
        import Platform2Lanes from './paramsDefaults/Platform2Lanes.vue'
        import PlatformBridge from './paramsDefaults/PlatformBridge.vue'
        import PlatformBridge1Lane from './paramsDefaults/PlatformBridge1Lane.vue'
        import PlatformBridge2Lanes from './paramsDefaults/PlatformBridge2Lanes.vue'
        import PlatformTunnel from './paramsDefaults/PlatformTunnel.vue'
        import PlatformTunnel1Lane from './paramsDefaults/PlatformTunnel1Lane.vue'
        import PlatformTunnel2Lanes from './paramsDefaults/PlatformTunnel2Lanes.vue'
        import PlatformSleepers from './paramsDefaults/PlatformSleepers.vue'
        import EarthworkTransport from './paramsDefaults/EarthworkTransport.vue';
        import EarthworkCut1Lane from './paramsDefaults/EarthworkCut1Lane.vue';
        import EarthworkCut2Lanes from './paramsDefaults/EarthworkCut2Lanes.vue';
        import EarthworkFill1Lane from './paramsDefaults/EarthworkFill1Lane.vue';
        import EarthworkFill2Lanes from './paramsDefaults/EarthworkFill2Lanes.vue';
        import { listValuesParams } from '../../../../listValuesParams.js'
        import { listValuesParamsPlatform } from '../../../../listValuesParamsPlatform.js'
        import { listValuesParamsEarthW } from '../../../../listValuesParamsEarthW.js'
        import { portuguese } from '../../../languages/pt-br'
        import { english } from '../../../languages/en'

        export default {
        name: 'AdjustPrices',
        components: {
            Curves,
            PlatformSleepers,
            EarthworkTransport,
            EarthworkCut1Lane,
            EarthworkCut2Lanes,
            EarthworkFill1Lane,
            EarthworkFill2Lanes,
            Platform1Lane,
            Platform2Lanes,
            PlatformAquisition1Lane,
            PlatformAquisition2Lanes,
            PlatformBridge,
            PlatformBridge1Lane,
            PlatformBridge2Lanes,
            PlatformTunnel,
            PlatformTunnel1Lane,
            PlatformTunnel2Lanes,
        },
        async mounted(){
            await this.getConfigById({id: this.$route.params.id})
            await this.getChangesList()
            .then(()=>{
                setTimeout(()=>{
                    if(this.capexChanged === true ){
                        this.setChangedFields(this.numerosUnicosOrdenados, this.valuesParamsKeys)
                        .then(()=>{
                            if(this.fieldCapexChanged == false){
                                // this.clickSaveExit()
                            }
                        })
                    } else if( this.capexChanged === false && this.curvesChanged === true ){
                        this.setCurvesTab(this.listValuesCurve, this.valuesParamsKeys)
                        .then(()=>{
                            if(this.fieldCurvesChanged == false){
                                // this.clickSaveExit()
                            }
                        })
                    } else if( this.capexChanged === false && this.curvesChanged === false && this.platformChanged === true ){
                        this.setPlatformTab(this.listValuesPlatform, this.valuesParamsKeys)
                        .then(()=>{
                            if(this.fieldPlatformChanged == false){
                                // this.clickSaveExit()
                            }
                        })
                    } else if( this.capexChanged === false && this.curvesChanged === false && this.platformChanged === false && this.earthWorkChanged === true ){
                        this.setEarthWTab(this.listValuesEarthW, this.valuesParamsKeys)
                        .then(()=>{
                            if(this.fieldEarthWorkChanged == false){
                                // this.clickSaveExit()
                            }
                        })
                    }else{
                        // this.clickSaveExit()
                    }
                    this.loadVersionData = false
                },500)
            })
        },
        data(){
            return{
                listValuesCurve: listValuesParams,
                listValuesPlatform: listValuesParamsPlatform,
                listValuesEarthW: listValuesParamsEarthW,
                
                capexChanged: false,
                curvesChanged: false,
                platformChanged: false,
                earthWorkChanged: false,

                fieldCapexChanged: false,
                fieldCurvesChanged: false,
                fieldPlatformChanged: false,
                fieldEarthWorkChanged: false,

                loadVersionData: true,

                numerosUnicosOrdenados: undefined,
                ListNewEarthworkItems: undefined,
                ListNewPlatformItems: undefined,
                ListNewCurveItems: undefined,
                valuesParamsKeys: undefined,
                notInUse: false,
                logoIconUrl: '/img/icons/logo_icon.webp',
                permanent: false,
                drawer: true,
                tab: 'expropriation',
                color: '#0A7373',
                loading1: false,
                loading2: false,
                capexChangeList: undefined,
            }
        },
        computed: {
            steps () {
            return this.$store.getters.steps('railway')
            },
            status () {
            return this.$store.getters.status(this.$route.params.id)
            },
            ...mapGetters(['profile','selectedExpropriation','paramSelected', 'selectedCapex', 'paramsChanges', 'configDefaults', 'newDefault','newCapexDetails','newExpropriation','newCapex','newRailTypes','newCurves']),
            expropriationHeaders () {
            try{
                return [
                { text: 'Code', value: 'Code', sortable: false},
                { text: 'Description', value: 'Description', sortable: false},
                { text: 'Cost ('+this.paramSelected.currency+'/m²)', value: 'Cost', sortable: false},
                ]
            } catch(error) {
                return [
                { text: 'Name', value: 'name', sortable: false},
                { text: 'Value (/m²)', value: 'value', sortable: false},
                ]
            }
            },
            capexHeaders () {
            try {
                return [
                { text: 'Code', value: 'Code', sortable: false},
                { text: 'Description', value: 'Description', sortable: false},
                { text: 'Unit', value: 'Unit', sortable: false},
                { text: 'Unit Cost ('+this.paramSelected.currency+')', value: 'Unit Cost', sortable: false},
                { text: 'Risk (%)', value: 'Risk', sortable: false},
                ]
            } catch(error) {
                return [
                { text: 'Code', value: 'Code', sortable: false},
                { text: 'Description', value: 'Description', sortable: false},
                { text: 'Unit', value: 'Unit', sortable: false},
                { text: 'Unit Cost ()', value: 'Unit Cost', sortable: false},
                { text: 'Risk (%)', value: 'Risk', sortable: false},
                ]
            }
            },
            selectedLanguage() {
                if (this.profile['language'] === 'en') {
                    return english;
                } else {
                    return portuguese;
                }
            },
        },
        methods: {
            async getChangesList(){

                const changesList  = this.paramSelected.version_change

                const regex = /\d+/

                let numerosSet = new Set()

                changesList.change.forEach(item => {
                if (item.startsWith('CapexCapex')) {
                    const numerosEncontrados = item.match(regex)
                    if (numerosEncontrados) {
                    numerosEncontrados.forEach(numero => numerosSet.add(Number(numero)))
                    }
                }
                });

                let numerosUnicosOrdenados = Array.from(numerosSet).sort((a, b) => a - b);

                this.numerosUnicosOrdenados = numerosUnicosOrdenados

                const dataArray = [...changesList.add, ...changesList.change];

                for (let i = 0 ; i < dataArray.length; i++) {
                    if (dataArray[i].startsWith('ValuesParams')) {
                        if (dataArray[i].startsWith('ValuesParamsStructure') ||
                        dataArray[i].startsWith('ValuesParamsPlatform') ||
                        dataArray[i].startsWith('ValuesParamsSuperstructure') ||
                        dataArray[i] == 'ValuesParamsEarthworkAvgEarthworkWidth' ||
                        dataArray[i] == 'ValuesParamsEarthworkMaxFill' ||
                        dataArray[i] == 'ValuesParamsEarthworkMinStrucHeightUp' ||
                        dataArray[i] == 'ValuesParamsEarthworkMaxCut' ||
                        dataArray[i] == 'ValuesParamsEarthworkMinStrucHeightDown' ||
                        dataArray[i].startsWith('ValuesParamsOthers')) {
                            this.platformChanged = true
                        } else if (dataArray[i].startsWith('ValuesParamsSuperelevation') || dataArray[i].startsWith('ValuesParamsAxisGeometry')) {
                            this.curvesChanged = true
                        } else if ( dataArray[i].startsWith('ValuesParamsEarthwork') && 
                        dataArray[i] != 'ValuesParamsEarthworkAvgEarthworkWidth' &&
                        dataArray[i] != 'ValuesParamsEarthworkMaxFill'&&
                        dataArray[i] != 'ValuesParamsEarthworkMinStrucHeightUp'&&
                        dataArray[i] != 'ValuesParamsEarthworkMaxCut'&&
                        dataArray[i] != 'ValuesParamsEarthworkMinStrucHeightDown'
                        ) {
                            this.earthWorkChanged = true
                        }
                    } else if (dataArray[i].startsWith('Capex')){
                        this.capexChanged = true
                    }
                }

                const valuesParamsKeys = await Object.values(dataArray).filter(value => value.startsWith('ValuesParams'))

                this.valuesParamsKeys = valuesParamsKeys

            },

            async setChangedFields(numerosUnicosOrdenados){
                var finalIs = numerosUnicosOrdenados.length
                var currentIs = 0
                    for(let i = 0; i < numerosUnicosOrdenados.length; i++){
                        if(document.getElementById(numerosUnicosOrdenados[i])){
                            document.getElementById(numerosUnicosOrdenados[i]).style.display = "table-row"
                            document.getElementById(numerosUnicosOrdenados[i]).style.opacity = "1"
                            document.getElementById(numerosUnicosOrdenados[i]).style.fontWeight = "500"
                            this.fieldCapexChanged = true
                        }
                        currentIs = i
                        if((currentIs+1) === finalIs){
                            if(this.fieldCapexChanged === false){
                                this.capexChanged = false
                                this.tab = 0
                            }
                        }
                    }
            },

            async setCurvesTab(unset, set){
                //var newList = []
                //var divSelected = document.getElementById('curveNewItems')
                    for(let i = 0; i < unset.length; i++){
                        if(document.getElementById(unset[i])){
                            // document.getElementById(unset[i]).style.opacity = "0.2"
                            document.getElementById(unset[i]).style.display = "none"
                            // console.log('removendo items')
                        }
                    }

                var finalIs = set.length
                var currentIs = 0

                    for(let i = 0; i < set.length; i++){
                        if (set[i].startsWith('ValuesParamsSuperelevation') || set[i].startsWith('ValuesParamsAxisGeometry')){
                            if(document.getElementById(set[i])){
                                document.getElementById(set[i]).style.display = "flex"
                                document.getElementById(set[i]).style.margin = "10px"
                                document.getElementById(set[i]).style.opacity = "1"
                                this.fieldCurvesChanged = true
                            }
                        }
                        currentIs = i
                        //else {
                            //if (set[i].startsWith('ValuesParams')) {
                                //if (set[i].startsWith('ValuesParamsSuperelevation') || set[i].startsWith('ValuesParamsAxisGeometry')) {
                                    //divSelected.innerHTML+='<div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;"><div>'+set[i]+'</div><input type="text" style="border: 2px solid; width: 50%; border-radius: 5px;" id="'+set[i]+'"></div'
                                    //newList.push(set[i])
                                    //console.log('adicionando items')
                                //} else {
                                    //do nothing
                                //}
                            //}
                        //}

                        //this.ListNewCurveItems = newList

                        if((currentIs+1) === finalIs){
                            if(this.fieldCurvesChanged === false){
                                this.curvesChanged = false
                                this.tab = 0
                            }
                        }

                    }
                    //this.checkField(this.ListNewCurveItems)
            },
            async setPlatformTab(unset, set){
                // var newList = []
                // var divSelected = document.getElementById('platformNewItems')
                    for(let i = 0; i < unset.length; i++){
                        if(document.getElementById(unset[i])){
                            // document.getElementById(unset[i]).style.opacity = "0.2"
                            document.getElementById(unset[i]).style.display = "none"

                            // console.log('removendo items')
                        }
                    }

                var finalIs = set.length
                var currentIs = 0

                    for(let i = 0; i < set.length; i++){
                        if (set[i].startsWith('ValuesParamsStructure') ||
                        set[i].startsWith('ValuesParamsPlatform') ||
                        set[i].startsWith('ValuesParamsSuperstructure') ||
                        set[i] == 'ValuesParamsEarthworkAvgEarthworkWidth' ||
                        set[i] == 'ValuesParamsEarthworkMaxFill' ||
                        set[i] == 'ValuesParamsEarthworkMinStrucHeightUp' ||
                        set[i] == 'ValuesParamsEarthworkMaxCut' ||
                        set[i] == 'ValuesParamsEarthworkMinStrucHeightDown' ||
                        set[i].startsWith('ValuesParamsOthers')){
                            if(document.getElementById(set[i])){
                                document.getElementById(set[i]).style.display = "flex"
                                document.getElementById(set[i]).style.margin = "10px"
                                document.getElementById(set[i]).style.opacity = "1"
                                this.fieldPlatformChanged = true
                                
                                console.log('platform ', set[i])
                                
                                console.log('platform tab ', this.tab)
                            }
                        }
                        currentIs = i
                        // else {
                        //     if (set[i].startsWith('ValuesParams')) {
                        //         if (set[i].startsWith('ValuesParamsStructure') ||
                        //         set[i].startsWith('ValuesParamsPlatform') ||
                        //         set[i].startsWith('ValuesParamsSuperstructure') ||
                        //         set[i].startsWith('ValuesParamsOthers')) {
                        //             divSelected.innerHTML+='<div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;"><div>'+set[i]+'</div><input type="text" style="border: 2px solid; width: 50%; border-radius: 5px;" id="'+set[i]+'"></div'
                        //             newList.push(set[i])
                        //             console.log('adicionando items')
                        //         } else {
                        //             //do nothing
                        //         }
                        //     }
                        // }

                        // this.ListNewPlatformItems = newList

                        if((currentIs+1) === finalIs){
                            if(this.fieldPlatformChanged === false){
                                this.platformChanged = false
                                this.tab = 0
                            }
                        }

                    }
                    // this.checkField(this.ListNewPlatformItems)
            },
            async setEarthWTab(unset, set){
                // var newList = []
                // var divSelected = document.getElementById('earthworkNewItems')
                    for(let i = 0; i < unset.length; i++){
                        if(document.getElementById(unset[i])){
                            // document.getElementById(unset[i]).style.opacity = "0.2"
                            document.getElementById(unset[i]).style.display = "none"
                        }
                    }

                var finalIs = set.length
                var currentIs = 0

                    for(let i = 0; i < set.length; i++){
                        if ( set[i].startsWith('ValuesParamsEarthwork')  && 
                        set[i] != 'ValuesParamsEarthworkAvgEarthworkWidth' &&
                        set[i] != 'ValuesParamsEarthworkMaxFill'&&
                        set[i] != 'ValuesParamsEarthworkMinStrucHeightUp'&&
                        set[i] != 'ValuesParamsEarthworkMaxCut'&&
                        set[i] != 'ValuesParamsEarthworkMinStrucHeightDown'){
                            if(document.getElementById(set[i])){
                                document.getElementById(set[i]).style.display = "flex"
                                document.getElementById(set[i]).style.margin = "10px"
                                document.getElementById(set[i]).style.opacity = "1"
                                this.fieldEarthWorkChanged = true
                                
                                console.log('earthwork ', set[i])
                                
                                console.log('earthwork tab ', this.tab)
                            }
                        }
                        currentIs = i
                        // else {
                        //     if (set[i].startsWith('ValuesParams')) {
                        //         if (set[i].startsWith('ValuesParamsEarthwork')) {
                        //             divSelected.innerHTML+='<div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;"><div>'+set[i]+'</div><input type="text" style="border: 2px solid; width: 50%; border-radius: 5px;" id="'+set[i]+'"></div'
                        //             newList.push(set[i])
                        //             console.log('adicionando items')
                        //         } else {
                        //             //do nothing
                        //         }
                        //     }
                        // }

                        // this.ListNewEarthworkItems = newList

                        if((currentIs+1) === finalIs){
                            if(this.fieldEarthWorkChanged === false){
                                console
                                this.earthWorkChanged = false
                                this.tab = 0
                            }
                        }

                    }
                    // this.checkField(this.ListNewEarthworkItems)
            },

            // checkField(lista){
            //     var j = 0;
            //     var character='';
            //     for(let i = 0; i < lista.length; i++){

            //         lista[i].replace('ValuesParams', 'values_params')

            //         while (j <= lista[i].length){
            //         character = lista[i].charAt(i);
            //         if (!isNaN(character * 1)){
            //             console.log('é número', character)
            //         }else{
            //             if (character == character.toUpperCase()) {
            //                 console.log('Colocar ponto aqui', character)
            //             }
            //             if (character == character.toLowerCase()){
            //                 console.log('não fazer nada, não é caixa alto', character)
            //             }
            //         }
            //         j++;
            //     }
            //     }
            // },

            redirectFunc(){
            if(process.env.VUE_APP_VAR_SELECT== 'prd'){
                this.$router.push({ name: 'billingMercadoPago' })
                } else {
                this.$router.push({ name: 'billing' })
                }
            },
            ...mapActions(['getConfigs','updateSelectedDeprecated','updateSelectedExpropriation','updateSelectedStore','getAppDefaults','getConfigById','updateSelectedCapex','updateSelectedRasterBudget','updateSelectedParams','getUpdatedDefault']),
            save() {},
            cancel() {},
            open() {},
            close() {},
            expropriationItemStyle(item) {
            if(item.Cost!=undefined){ return 'capexLastLevel'}
            if(item.Level==0){ return 'capexZeroLevel'}
            if(item.Level==1){ return 'capexFirstLevel'}
            else{ return 'capexLastLevel'}
            },
            capexItemStyle(item) {
            if(item.Unit!=0){ return 'capexLastLevel'}
            if(item.Level==0){ return 'capexZeroLevel'}
            if(item.Level==1){ return 'capexFirstLevel'}
            if(item.Level==2){ return 'capexSecondLevel'}
            if(item.Level==3){ return 'capexThirdLevel'}
            return ''
            },
            async clickSaveExit() {
            this.loading1 = true
            await (
                this.updateSelectedRasterBudget(),
                this.updateSelectedCapex(),
                this.updateSelectedParams(),
                this.getConfigs()
            )
            this.$router.push({ name: 'new_railway' })
            this.loading1 = false
            },
            async clickCancelExit() {
            this.loading2 = true
            await this.goStep(1)
            this.loading2 = false
            }
        },
        }
    </script>

    <style scoped>
        .capexLastLevel {
        font-style: italic
        }
        .capexZeroLevel {
        background-color: #0A7373;
        font-weight: bold
        }
        .capexFirstLevel {
        background-color: #338D8C;
        font-weight: bold
        }
        .capexSecondLevel {
        background-color: #36bebc;
        font-weight: bold
        }
        .capexThirdLevel {
        background-color: #41e2df;
        font-weight: bold
        }
    
        @media only screen and (max-width: 576px) {
            .codeS {
                display: none;
            }
        .trBody{
            display: flex;
            justify-content: space-between;
        }
        }
    </style>