<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.29 87.01" id="ValuesParamsPlatformSVG5">
      <title>bridge_section_1lane</title>
      <g id="Lastro">
        <polyline
          class="cls-1"
          points="6.04 9.43 146.84 9.43 147.63 26.57 6.04 26.45"
        />
        <line class="cls-1" x1="4.18" y1="22.97" x2="6.04" y2="23.04" />
      </g>
      <g id="Dormente">
        <rect class="cls-2" x="35.22" y="9.2" width="79.34" height="6.88" />
      </g>
      <g id="Estrutura">
        <path
          class="cls-3"
          d="M160.9,75.93"
          transform="translate(-10.54 -10.5)"
        />
        <path
          class="cls-3"
          d="M11.64,33.54"
          transform="translate(-10.54 -10.5)"
        />
        <path
          class="cls-3"
          d="M10.55,47.59c8.67,0,8,1,16.71,1,1.93,0,56.87.46,58.8.46h0l51-.23h0c2.1,0,23.66-1.6,25.76-1.6"
          transform="translate(-10.54 -10.5)"
        />
        <polygon
          class="cls-2"
          points="152.27 36.72 152.27 47.27 146.81 47.37 146.81 47.37 133.62 65.38 133.62 65.38 76.53 65.38 76.53 65.38 19.89 65.59 19.89 65.59 6.25 46.12 6.25 46.12 0.02 46.12 0.02 37.09 0.02 37.09 0.02 0.11 6.04 0.11 6.04 23.04 6.04 23.04 76.53 25.71 146.68 23.33 146.68 23.33 146.68 0 152.27 0 152.27 36.72 152.27 36.72"
        />
      </g>
      <g id="Trilho">
        <polygon
          class="cls-4"
          points="57.06 7.92 57.06 9.4 52.16 9.4 52.16 7.92 53.95 7.92 53.95 3.72 53.29 3.72 53.29 1.08 55.93 1.08 55.93 3.72 55.27 3.72 55.27 7.92 57.06 7.92"
        />
        <polygon
          class="cls-4"
          points="99.06 7.92 99.06 9.4 94.16 9.4 94.16 7.92 95.95 7.92 95.95 3.72 95.29 3.72 95.29 1.08 97.93 1.08 97.93 3.72 97.27 3.72 97.27 7.92 99.06 7.92"
        />
      </g>
      <g id="Medidas">
        <line class="cls-5" x1="0.25" y1="77.79" x2="0.25" y2="87.01" />
        <line class="cls-5" x1="152.04" y1="77.79" x2="152.04" y2="86.55" />
        <text class="cls-6" transform="translate(66.97 80.49)">{{paramSelected.values_params.structure.bridge_width}} m</text>
        <line class="cls-5" x1="1.15" y1="83.19" x2="150.7" y2="82.87" />
        <polygon
          points="2.15 84.04 1.3 83.19 2.15 82.34 1.43 82.35 0.58 83.2 1.43 84.04 2.15 84.04"
        />
        <polygon
          points="149.71 83.72 150.55 82.87 149.7 82.03 150.42 82.03 151.27 82.87 150.43 83.72 149.71 83.72"
        />
      </g>
    </svg>
    <v-row>
      <v-col>
        <v-row id="ValuesParamsStructureBridgeWidth">
          <v-col style="align-self: center;"> {{ selectedLanguage['platformBridge1Lane_component_text4'] }} </v-col>
          <v-col style="align-self: center;">
            <v-text-field dense hide-details class="mb-2" clearable outlined v-model.number="paramSelected.values_params.structure.bridge_width" style="border-radius: 10px !important;"/>
          </v-col>
          <v-col style="align-self: center;"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["paramSelected","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #ccc;
}
.cls-2 {
  fill: #666;
}
.cls-3,
.cls-5 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
}
.cls-4 {
  fill: #3d3d3d;
}
.cls-5 {
  stroke-width: 0.5px;
}
.cls-6 {
  font-size: 14px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
</style>