<template>
  <v-container v-if="paramSelected">
    <v-row>
      <v-col style="font-size: 20px; text-align: center" id="ValuesParamsAxisGeometryGradeTitle">
        <strong> {{ selectedLanguage['curves_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <v-row style="margin: 12px;">
      <div id="ValuesParamsAxisGeometryMaxGrade" class="adjustGroup">
        <v-text-field
          dense
          outlined
          hide-details="auto"
          :label="selectedLanguage['curves_component_text2']"
          v-model.number="paramSelected.values_params.axis_geometry.max_grade"
        />
        <v-tooltip max-width="600" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
            <v-icon style="margin-left: 10px;">
              mdi-information-outline
            </v-icon>
            </v-btn>
          </template>
          <span>{{ selectedLanguage['help_text_1_tooltip'] }}</span>
        </v-tooltip>
      </div>
      <v-spacer />
      <div id="ValuesParamsAxisGeometryMinGrade" class="adjustGroup">
        <v-text-field
          dense
          outlined
          hide-details="auto"
          :label="selectedLanguage['curves_component_text3']"
          v-model.number="paramSelected.values_params.axis_geometry.min_grade"
        />
      </div>
        <v-tooltip max-width="600" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
            <v-icon style="margin-left: 10px;">
              mdi-information-outline
            </v-icon>
            </v-btn>
          </template>
          <span>{{ selectedLanguage['help_text_2_tooltip'] }}</span>
        </v-tooltip>
      <v-spacer />
      <div id="ValuesParamsAxisGeometryVerticalCurveLength" class="adjustGroup">
        <v-text-field
          dense
          outlined
          hide-details="auto"
          :label="selectedLanguage['curves_component_text4']"
          v-model.number="
            paramSelected.values_params.axis_geometry.vertical_curve_length
          "
        />
        <v-tooltip max-width="600" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
            <v-icon style="margin-left: 10px;">
              mdi-information-outline
            </v-icon>
            </v-btn>
          </template>
          <span>{{ selectedLanguage['help_text_3_tooltip'] }}</span>
        </v-tooltip>
      </div>
    </v-row>
    <br /><v-divider id="ValuesParamsAxisGeometryDivider" /><br />
    <svg viewBox="0 0 749 439" id="ValuesParamsAxisGeometry">
      <g>
        <path
          class="st0"
          d="M38.5,72.5l47,218l229-53l-48-214c0,0-76-3-118,6C105.54,38.7,38.5,72.5,38.5,72.5z"
        />
        <g>
          <g>
            <polyline
              class="st1"
              points="225.36,156.51 176.21,157 177.49,395.36 			"
            />
            <g>
              <polygon
                class="st2"
                points="223.35,154.84 225.06,156.51 223.38,158.23 224.82,158.21 226.5,156.5 224.79,154.82 				"
              />
            </g>
            <g>
              <polygon
                class="st2"
                points="175.79,393.37 177.49,395.06 179.18,393.36 179.19,394.8 177.5,396.5 175.8,394.81 				"
              />
            </g>
          </g>
        </g>
        <polygon
          class="st0"
          points="273.5,255.5 287.5,255.5 301.5,321.5 287.5,327.5 	"
        />
        <polygon
          class="st0"
          points="129.5,283.5 119.5,291.5 134.5,359.5 147.5,359.5 	"
        />
        <polygon
          class="st0"
          points="118.5,324.5 126.12,321.5 128.05,330.27 119.5,330.5 	"
        />
        <polygon
          class="st0"
          points="301.5,281.5 304.5,291.5 295.51,293.26 293.44,283.5 	"
        />
        <polygon
          class="st0"
          points="137.5,317.5 278.5,287.5 281.32,295.72 140.24,328.87 	"
        />
        <polygon
          class="st0"
          points="86.5,388.5 355.5,332.5 360.5,352.5 91.5,408.5 	"
        />
        <polygon
          class="st3"
          points="302.5,324.5 298.5,327.5 301.5,337.5 314.5,337.5 315.4,339.15 292.44,344.1 292.5,342.5 298.5,338.5 295.5,328.5 289.5,328.5 289.5,326.64 300.5,322.5 	"
        />
        <polygon
          class="st3"
          points="135.5,361.5 138.5,362.5 139.5,372.5 130.5,376.5 130.96,378.91 155.43,373.64 154.5,371.5 143.5,371.5 142.5,362.5 145.5,361.5 145.5,359.5 135.13,359.5 	"
        />
        <line class="st4" x1="376.5" y1="318.5" x2="376.5" y2="362.5" />
        <line class="st5" x1="382.5" y1="325.5" x2="297.5" y2="325.5" />
        <line class="st6" x1="397.5" y1="360.5" x2="69.5" y2="360.5" />
        <line class="st7" x1="176.5" y1="399.5" x2="231.5" y2="399.5" />
        <line class="st6" x1="238.5" y1="429.5" x2="142.5" y2="7.5" />
        <g>
          <g>
            <line class="st1" x1="229.5" y1="157" x2="229.5" y2="162" />
            <line class="st8" x1="229.5" y1="171.88" x2="229.5" y2="364.56" />
            <line class="st1" x1="229.5" y1="369.5" x2="229.5" y2="374.5" />
          </g>
        </g>
        <text
          transform="matrix(1 0 0 1 155.3525 407.0654)"
          class="st2 st9 st10"
        >
          W
        </text>
        <text
          transform="matrix(1 0 0 1 177.8506 148.9951)"
          class="st11 st9 st12"
        >
          Vg
        </text>
        <text
          transform="matrix(1 0 0 1 234.8506 169.9951)"
          class="st2 st9 st12"
        >
          Cf
        </text>
        <text
          transform="matrix(1 0 0 1 384.8506 346.9951)"
          class="st13 st9 st14"
        >
          Ts
        </text>
        <text transform="matrix(1 0 0 1 242.1499 429.375)" class="st9 st15">
          y
        </text>
        <text transform="matrix(1 0 0 1 150.1499 17.375)" class="st9 st15">
          y
        </text>
      </g>
      <g>
        <text
          transform="matrix(1 0 0 1 708.8496 106.375)"
          class="st13 st9 st15"
        >
          Ts
        </text>
        <polygon
          class="st0"
          points="341.5,159.5 678.5,85.5 684.5,108.5 345.5,183.5 	"
        />
        <polygon
          class="st3"
          points="400.5,120.5 400.5,124.5 404.5,124.5 405.5,138.5 394.5,142.5 394.5,147.5 423.55,141.48 422.5,137.5 
          409.5,137.5 408.5,124.5 411.5,124.5 411.5,120.5 	"
        />
        <polygon
          class="st3"
          points="597.5,80.5 608.5,77.5 609.5,80.5 605.5,81.5 609.5,93.5 620.5,93.5 621.5,97.5 596.94,102.63 
          596.5,98.5 604.5,95.5 602.5,82.5 598.5,83.5 	"
        />
        <line class="st16" x1="741.5" y1="120.5" x2="318.5" y2="120.5" />
        <line class="st16" x1="316.5" y1="140.5" x2="732.5" y2="48.5" />
        <line class="st17" x1="703.5" y1="71.5" x2="703.5" y2="126.5" />
        <line class="st5" x1="708.5" y1="77.5" x2="612.5" y2="77.5" />
        <line class="st18" x1="406.5" y1="104.5" x2="397.5" y2="106.5" />
        <line class="st18" x1="603.16" y1="60.72" x2="594.16" y2="62.72" />
        <line class="st18" x1="405.5" y1="99.5" x2="407.5" y2="109.5" />
        <line class="st17" x1="600.5" y1="21.5" x2="382.5" y2="69.5" />
        <line class="st17" x1="380.5" y1="59.5" x2="384.5" y2="78.5" />
        <line class="st17" x1="598.5" y1="11.5" x2="602.5" y2="30.5" />
        <g>
          <text
            transform="matrix(0.9788 -0.2049 0.2049 0.9788 446.5105 45.1729)"
            class="st13 st9 st15"
          >
            {{ paramSelected.values_params.superelevation.gauge }} m
          </text>
        </g>
        <line class="st18" x1="396.5" y1="101.5" x2="398.5" y2="111.5" />
        <line class="st18" x1="602.12" y1="55.49" x2="604.12" y2="65.49" />
        <line class="st18" x1="593.12" y1="57.49" x2="595.12" y2="67.49" />
      </g>
      <text transform="matrix(1 0 0 1 390.7871 216.7432)">
        <tspan x="0" y="0" class="st9 st19">{{ selectedLanguage['curves_component_text17'] }}</tspan>
        <tspan x="0" y="18.79" class="st9 st19">
          {{ selectedLanguage['curves_component_text18'] }}
        </tspan>
        <tspan x="0" y="37.59" class="st9 st19">
          {{ selectedLanguage['curves_component_text19'] }}
        </tspan>
        <tspan x="0" y="56.38" class="st9 st19">{{ selectedLanguage['curves_component_text20'] }}</tspan>
        <tspan x="0" y="75.17" class="st9 st19">{{ selectedLanguage['curves_component_text21'] }}</tspan>
      </text>
      <line class="st20" x1="165" y1="157" x2="165" y2="262" />
      <line class="st20" x1="158" y1="156.5" x2="173" y2="156.5" />
      <line class="st20" x1="158" y1="262.5" x2="173" y2="262.5" />
      <g>
      </g>
      <g>
        <text
          contentEditable="true"
          transform="matrix(0.9775 -0.2108 0.2108 0.9775 376.3245 101.0949)"
          class="st22 st9 st15"
        >
          {{ selectedRailTypeModel.Boleto }} mm
        </text>
      </g>
    </svg>
    <v-col class="formCardStyle">
      <v-row id="ValuesParamsSuperelevationGauge">
        <v-col style="align-self: center"> {{ selectedLanguage['curves_component_text22'] }} </v-col>
        <v-col style="align-self: center">
          <v-combobox
            v-model.number="paramSelected.values_params.superelevation.gauge"
            :items="gaugeList"
            :label="selectedLanguage['railway_params_lable_2']"
            @change="recalculateSuperelevation"
          />
        </v-col>
        <v-col style="align-self: center"> m </v-col>
      </v-row>
      <v-row id="ValuesParamsSuperelevationBoletoIndex">
        <v-col style="align-self: center"> {{ selectedLanguage['curves_component_text23'] }} </v-col>
        <v-col style="align-self: center">
          <v-combobox
            v-model="selectedRailTypeModel"
            :items="selectedRailTypes"
            @change="onChangeSelectedRailType"
            item-text="Model"
            item-value="Id"
            :label="selectedLanguage['railway_params_lable_3']"
            return-object
          />
        </v-col>
        <v-col style="align-self: center"></v-col>
      </v-row>
      <v-row id="ValuesParamsSuperelevationOperationalSpeed">
        <v-col style="align-self: center"> {{ selectedLanguage['curves_component_text24'] }} </v-col>
        <v-col style="align-self: center">
          <v-combobox
            v-model.number="
              paramSelected.values_params.superelevation.operational_speed
            "
            :items="speedList"
            :label="selectedLanguage['railway_params_lable_4']"
            @change="recalculateSuperelevation"
          />
        </v-col>
        <v-col style="align-self: center"> km/h </v-col>
      </v-row>
      <v-row id="ValuesParamsSuperelevationSecurityCoeficient">
        <v-col style="align-self: center"> {{ selectedLanguage['curves_component_text25'] }} </v-col>
        <v-col style="align-self: center">
          <v-text-field
            dense
            hide-details
            class="mb-2"
            clearable
            type="number"
            v-model.number="
              paramSelected.values_params.superelevation.security_coeficient
            "
            style="border-radius: 10px !important"
          />
        </v-col>
        <v-col style="align-self: center"></v-col>
      </v-row>
      <v-row id="ValuesParamsSuperelevationSpeedCoeficient">
        <v-col style="align-self: center"> {{ selectedLanguage['curves_component_text26'] }} </v-col>
        <v-col style="align-self: center">
          <v-combobox
            :items="
              paramSelected.values_params.superelevation.superelevation_option
            "
            v-model="
              paramSelected.values_params.superelevation.speed_coeficient
            "
            @change="recalculateSuperelevation"
          />
        </v-col>
        <v-col style="align-self: center"></v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-row style="justify-content: center" id="ValuesParamsAxisGeometryMinCircularCurveRadius">
        <v-col> {{ selectedLanguage['curves_component_text27'] }} </v-col>
        <v-col>
          <v-text-field
            dense
            hide-details
            class="mb-2"
            type="number"
            v-model.number="
              paramSelected.values_params.axis_geometry.min_circular_curve_radius
            "
          />
        </v-col>
        <v-col> m </v-col>
      </v-row>
      <v-row style="justify-content: center" id="ValuesParamsCurveChart">
        <v-data-table
          :headers="curvesHeaders"
          :hide-default-footer="true"
          :items="selectedCurves"
          class="elevation-0"
          disable-pagination
        >
          <template v-slot:top>
            <v-row class="mt-4" style="margin: 12px;">
              <v-toolbar flat>
                <v-toolbar-title>{{ selectedLanguage['curves_component_text11'] }}</v-toolbar-title>
              </v-toolbar>
              <DialogComponent
                :buttonText="selectedLanguage['curves_component_text12']"
                :titleDialog="selectedLanguage['curves_component_text12']"
                @save-item-dialog="handleNewItemCurve"
              >
                <v-row>
                  <v-col cols="4" sm="6" md="4">
                    <v-text-field
                      v-model="newItem.Radius"
                      label="Radius (m)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="6" md="4">
                    <v-text-field
                      v-model="newItem.Ls"
                      label="Spiral (m)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="6" md="4">
                    <v-text-field
                      v-model="newItem.Penalty"
                      :label="`${selectedLanguage['curves_component_text15']} (${paramSelected.currency}/m)`"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </DialogComponent>
            </v-row>
          </template>
          <template v-slot:item.Radius="props">
            <v-edit-dialog
              color="primary"
              :return-value.sync="props.item.Radius"
              large
              @save="editTable(props.item)"
            >
              <div>{{ props.item.Radius }}</div>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Radius"
                  :label="selectedLanguage['curves_component_text16']"
                  single-line
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.Ls="props">
            <v-edit-dialog
              color="primary"
              :return-value.sync="props.item.Ls"
              large
              @save="editTable(props.item)"
            >
              <div>{{ props.item.Ls }}</div>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Ls"
                  :label="selectedLanguage['curves_component_text16']"
                  single-line
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.Penalty="props">
            <v-edit-dialog
              color="primary"
              :return-value.sync="props.item.Penalty"
              large
              @save="editTable(props.item)"
            >
              <div>{{ props.item.Penalty }}</div>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Penalty"
                  :label="selectedLanguage['curves_component_text16']"
                  single-line
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DialogComponent from "@/components/DialogComponent.vue";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  components: { DialogComponent },
  mounted: async function () {
    this.selectedRailTypeModel =
      this.selectedRailTypes[
        this.paramSelected.values_params.superelevation.boleto_index
      ];
    this.delay(1000).then(() => this.recalculateSuperelevation())
    await this.initCurvesTableTranslation()
  },
  computed: {
    ...mapGetters(["paramSelected", "selectedCurves", "selectedRailTypes","profile"]),
    safety() {
      return (
        this.paramSelected.values_params.superelevation.security_coeficient * 1
      );
    },
    curvesHeaders() {
      return this.listArrOfObject
    },
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  data() {
    return {
      color: "#0A7373",
      gaugeList: [1.0, 1.435, 1.6],
      speedList: [
        30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180,
        190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300,
      ],
      selectedRailTypeModel: { Boleto: 0 },
      newItem: { Radius: "", Ls: null, Penalty: null },
      listArrOfObject: [
        { text: "Radius (m)", value: "Radius" },
        { text: "Spiral (m)", value: "Ls" },
        { text: "Penalty (/m)", value: "Penalty", },
        { text: "Superelevation (%)", value: "Superelevation" },
        { text: "Actions", value: "actions", sortable: false },
        ],
    };
  },
  methods: {
    initCurvesTableTranslation(){
      this.listArrOfObject = this.selectedLanguage['curves_comp_list_arr_of_obj_1']
    },
    delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    recalculateSuperelevation() {
      this.selectedCurves.forEach((item) => {
        let scoef;
        if (
          this.paramSelected.values_params.superelevation.speed_coeficient ==
          "3/4 (v)"
        ) {
          scoef = 0.75;
        } else {
          if (
            this.paramSelected.values_params.superelevation.speed_coeficient ==
            "2/3 (v)"
          ) {
            scoef = (2 / 3).toFixed(2);
          } else {
            if (
              this.paramSelected.values_params.superelevation.speed_coeficient ==
              "1/√2 (v)"
            ) {
              scoef = (1 / Math.pow(2, 0.5)).toFixed(2);
            } else {
              scoef = 1;
            }
          }
        }
        item.Superelevation = (
          (((this.paramSelected.values_params.superelevation.gauge * 1000 +
            this.selectedRailTypeModel.Boleto) *
            Math.pow(
              this.paramSelected.values_params.superelevation.operational_speed *
                scoef,
              2
            )) /
          (127 * item.Radius) /
          1000)
          *100).toFixed(2);
        if (
          this.paramSelected.values_params.superelevation.speed_coeficient ==
          "2/3 (St)"
        ) {
          item.Superelevation = (item.Superelevation * 2) / 3;
        }
        if (item.Superelevation > 8.0) {
          item.Superelevation = 8.0;
        }
        this.paramSelected.values_params.superelevation.boleto_width = this.selectedRailTypeModel.Boleto
        this.paramSelected.values_params.superelevation.security_coeficient = scoef
      });
    },
    onChangeSelectedRailType() {
      this.paramSelected.values_params.superelevation.boleto_index =
        this.selectedRailTypeModel.Id;
      this.recalculateSuperelevation();
    },
    async editTable() {
      this.recalculateSuperelevation();
    },
    deleteItem(itemToDelete) {
      const indexToDelete = this.selectedCurves.findIndex(
        (item) => item === itemToDelete
      );

      if (indexToDelete !== -1) {
        this.selectedCurves.splice(indexToDelete, 1);
        this.paramSelected.values_params.axis_geometry.list_curves.splice(indexToDelete, 1);
      }
    },
    handleNewItemCurve() {
      this.selectedCurves.push(this.newItem);
      this.recalculateSuperelevation();
      this.resetNewItem();
    },
    resetNewItem() {
      const defaultValues = {
        Radius: "",
        Ls: null,
        Penalty: null,
      };

      this.newItem = defaultValues;
    },
  },
};
</script>

<style scoped>
.adjustGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.st0 {
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st1 {
  fill: none;
  stroke: #3e5aa9;
  stroke-miterlimit: 10;
}
.st2 {
  fill: #3e5aa9;
}
.st3 {
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st4 {
  fill: none;
  stroke: #0c7f40;
  stroke-miterlimit: 10;
}
.st5 {
  fill: none;
  stroke: #0c7f40;
  stroke-miterlimit: 10;
  stroke-dasharray: 10;
}
.st6 {
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 10;
  stroke-dasharray: 10;
}
.st7 {
  fill: none;
  stroke: #3e5aa9;
  stroke-miterlimit: 10;
  stroke-dasharray: 10;
}
.st8 {
  fill: none;
  stroke: #3e5aa9;
  stroke-miterlimit: 10;
  stroke-dasharray: 9.881, 9.881;
}
.st9 {
  font-family: "MyriadPro-Regular";
}
.st10 {
  font-size: 15.7386px;
}
.st11 {
  fill: #ff7f00;
}
.st12 {
  font-size: 24.018px;
}
.st13 {
  fill: #5b9f71;
}
.st14 {
  font-size: 18.5px;
}
.st15 {
  font-size: 18.4984px;
}
.st16 {
  fill: none;
  stroke: #f04838;
  stroke-miterlimit: 10;
  stroke-dasharray: 10;
}
.st17 {
  fill: none;
  stroke: #5b9f71;
  stroke-miterlimit: 10;
}
.st18 {
  fill: none;
  stroke: #ff0659;
  stroke-miterlimit: 10;
}
.st19 {
  font-size: 15.6606px;
}
.st20 {
  fill: none;
  stroke: #ff7f00;
  stroke-miterlimit: 10;
}
.st21 {
  font-size: 16.7774px;
}
.st22 {
  fill: #ff0659;
}
.curve_st0 {
  clip-path: url(#SVGID_2_);
  fill: #00ccc0;
}
.curve_st1 {
  clip-path: url(#SVGID_2_);
  fill: #72f2eb;
}
.curve_st2 {
  clip-path: url(#SVGID_2_);
  fill: none;
  stroke: #0a4741;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.curve_st3 {
  clip-path: url(#SVGID_2_);
  fill: #b1fcf6;
}
.curve_st4 {
  clip-path: url(#SVGID_2_);
  fill: none;
  stroke: #1b7f79;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.curve_st5 {
  clip-path: url(#SVGID_2_);
}
.curve_st6 {
  fill: #00ccc0;
}
.curve_st7 {
  fill: #72f2eb;
}
.curve_st8 {
  clip-path: url(#SVGID_2_);
  fill: #747f7f;
}
.curve_st9 {
  fill: none;
  stroke: #00ccc0;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.curve_st10 {
  fill: #ffffff;
}
.curveImage {
  padding: 10px;
  max-height: 472px;
}
@media only screen and (max-width: 1280px) {
  .curveImage {
    padding: 10px;
    max-height: auto;
  }
}
.nextstep2 {
  padding: 5px;
  background-color: #ff8409;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  min-width: 290px;
  height: 40px;
}
</style>
