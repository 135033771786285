<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsPlatformTitleSVG2">
        <strong> {{ selectedLanguage['platformAquisition1Lane_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 513 251"
      id="ValuesParamsPlatformSVG2"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            class="cls-1"
            d="M378.59,22.49a11.39,11.39,0,0,0-10.32-6.94A11.52,11.52,0,0,0,357.4,24a10,10,0,0,0-2.14-.23,9.2,9.2,0,0,0-8.81,5.89,9.23,9.23,0,0,0-2.6-.36c-4.76,0-8.62,3.45-8.62,7.7a7,7,0,0,0,1,3.66c-6,.61-21.52,1.54-22.89,2-1.68.53,14.35,1,19.57,1.38,1.1.09,2.28.75,2.28,1.19,0,4.54,5.24,8.22,11.69,8.22a15.06,15.06,0,0,0,6.92-1.6,9.14,9.14,0,0,0,7.45,3.62,9.29,9.29,0,0,0,7.07-3.12,11.86,11.86,0,0,0,7,2.12,11.36,11.36,0,0,0,7.79-2.79A16.48,16.48,0,0,0,391,53.39c5.78,0,10.48-2.37,10.54-5.31a72,72,0,0,1,8.12-.59c7.67-.12,22.25-.28,23.22-.86s-8.22-2-20.69-2.76a2.6,2.6,0,0,0,.56-1.58c0-1.86-2.17-3.38-4.85-3.38a6.32,6.32,0,0,0-2.89.67c-.16-3.13-3.58-5.64-7.76-5.64a10,10,0,0,0-1.35.09,7.43,7.43,0,0,0-3-4c-.4-4.68-4.74-8.37-10-8.37A11,11,0,0,0,378.59,22.49Z"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            class="cls-1"
            d="M379.13,102c-.49-3-3.65-5.3-7.47-5.3s-7.2,2.48-7.5,5.63a2.65,2.65,0,0,0-2.17,1.82,3.36,3.36,0,0,0-1.06-.17,3.31,3.31,0,0,0-1.75.49,5.33,5.33,0,0,0-3.55-1.33,5.28,5.28,0,0,0-5.06,3.65,5.63,5.63,0,0,0-1.75-.28,5.2,5.2,0,0,0-5.27,4.27c-1.94.17-3.45,1.35-3.45,2.79a2,2,0,0,0,.15.76h0a1.37,1.37,0,0,0-1.45,1.26,1.11,1.11,0,0,0,.14.54c-6.25.52-13.94,1-14.76,1.09-1.39.17,104.05-.22,104.05-.22s-6.57-.42-10.39-.89c-2.74-.33-2-.76-2.23-1.31-.3-.74-1.48-1.3-2.88-1.3a4.81,4.81,0,0,0-1.3.17c-.11-1.62-2.12-2.91-4.58-2.91a6.08,6.08,0,0,0-3,.72,3.27,3.27,0,0,0-3.11-2.54,5.06,5.06,0,0,0-5-4,5.29,5.29,0,0,0-2.63.69,5.48,5.48,0,0,0-5.1-2.87,6.31,6.31,0,0,0-3.35.92,4.42,4.42,0,0,0-3.79-2.06A4.57,4.57,0,0,0,379.13,102Z"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            class="cls-1"
            d="M88.19,92.54C87,85.25,79.31,79.61,70,79.61c-9.64,0-17.55,6-18.29,13.73a6.46,6.46,0,0,0-5.29,4.45,8.17,8.17,0,0,0-2.59-.42,8,8,0,0,0-4.26,1.2,13,13,0,0,0-8.65-3.26,12.88,12.88,0,0,0-12.33,8.9,14,14,0,0,0-4.28-.67c-6.57,0-12,4.54-12.85,10.42-4.73.4-8.4,3.29-8.4,6.78a5,5,0,0,0,.37,1.87h-.11a3.32,3.32,0,0,0-3.53,3.06A2.73,2.73,0,0,0-9.87,127c-15.24,1.27-34,2.4-36,2.65-3.4.41,253.63-.54,253.63-.54s-16-1-25.33-2.15c-6.67-.81-4.91-1.87-5.44-3.2-.72-1.82-3.59-3.18-7-3.18a11.8,11.8,0,0,0-3.17.42c-.25-3.94-5.16-7.08-11.16-7.08a14.9,14.9,0,0,0-7.21,1.74c-.45-3.24-3.6-5.82-7.58-6.2a12.32,12.32,0,0,0-12.25-9.62,13,13,0,0,0-6.43,1.69c-1.8-4.08-6.69-7-12.43-7a15.31,15.31,0,0,0-8.16,2.26,10.78,10.78,0,0,0-9.25-5A11.25,11.25,0,0,0,88.19,92.54Z"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <path
            class="cls-1"
            d="M174.72,83.64a8.69,8.69,0,0,0-7-3.29c-4.09,0-7.5,2.6-8.24,6.05-3.81.66-6.67,3.54-6.67,7a6.31,6.31,0,0,0,.11,1.15,7.77,7.77,0,0,0-2.88,6,8.1,8.1,0,0,0,5.63,7.54c0,.16,0,.33,0,.49,0,4.12,3.83,7.46,8.54,7.46a9.21,9.21,0,0,0,5.52-1.77,10,10,0,0,0,3,.47,9.82,9.82,0,0,0,4.62-1.15A11.47,11.47,0,0,0,184.6,116a11.19,11.19,0,0,0,8.26-3.44,11,11,0,0,0,6.86,2.14,11.91,11.91,0,0,0,5.6-1.31,8.7,8.7,0,0,0,4.74,1.31c3.67,0,6.67-2,6.84-4.44,9-.13,17.67-.22,20.63-.37,0,0-16.07-2.36-17.59-3.64l-.11-.1a3.62,3.62,0,0,0,.38-1.63c0-2.34-2.29-4.24-5.11-4.24a5.75,5.75,0,0,0-3.48,1.13,6.56,6.56,0,0,0-5-2,7.49,7.49,0,0,0-3.36.76c-.1-3.18-3.15-5.73-6.9-5.73a8.33,8.33,0,0,0-1.33.11,4.52,4.52,0,0,0,.06-.74c0-3.09-2.88-5.59-6.42-5.59a7.21,7.21,0,0,0-2.15.32,6.39,6.39,0,0,0-1.44-3,4.75,4.75,0,0,0-2.14-1.73,8.87,8.87,0,0,0-8.18-.12Z"
          />
        </clipPath>
        <clipPath id="clip-path-5">
          <path
            class="cls-2"
            d="M89.85,101.51C82.19,93.1,79.46,90,77.71,90.33c-4.9,1-17.39,15.85-19.21,13.56s-17.58-15.63-23.77-22c-1.54-1.6-3.49-3.5-5.63-5.53s-4.49-4.17-6.82-6.24c-2.33-4.92-4.65-5.43-6.75-5.84C12,61.5,9,55.51,6.68,57.57c-2.27.73-3.79.78-4.36-1.34C2,56.14,1.79,58.51,1.56,59c-.23-.75-.44,3.67-.62,6.29C-1.82,104.71.45,257.46.45,257.46s280.43-21.43,270.63-30S148.26,131.22,145.86,129c-1.38-1.28-4.77,2.45-8.87,6-.39.34-2.57-.83-3-.5-2.63,2.26-4.06,5.67-5.76,6.16-1.37.39-6.06-4.1-12.06-10.54-.37-.62-.76-1.28-1.16-2-2.21-3.8-12-15.54-12.54-16.51s-2.24-3.86-2.24-3.86c-.67-1.12-2.4-.49-2.92-.4-1,.17-2-2.31-2.74-3.45C92.41,100.32,91.46,101.42,89.85,101.51Z"
          />
        </clipPath>
        <clipPath id="clip-path-6">
          <path
            class="cls-2"
            d="M245.11,221c-8.05-2.75-102.85-68.52-109.16-62.58-4.31,4.07-11.9,8.18-17.26,7.61-7.75-5.32-20.76-20.37-23.8-18C89.25,152.46,3.12,261.74,10.71,255.7c4.39-3.49,250,7.6,250,7.6S253.15,223.71,245.11,221Z"
          />
        </clipPath>
        <clipPath id="clip-path-7">
          <path
            class="cls-2"
            d="M221.41,260c-9-9.56-41.3-74.28-48.84-74.59s-13.64,8.52-17.95,5-10.12-12.75-14-17.66-12.69-15.18-15.92-18.53c-3.44-3.56-10.38,3.15-15.32,7.54,0,0-8.87-12-19.69-20.61S70.1,109.51,67.94,115s-23.55,128.64-23.19,133,5,24.42,18.67,18.67S230.38,269.53,221.41,260Z"
          />
        </clipPath>
        <clipPath id="clip-path-8">
          <polygon
            class="cls-2"
            points="373.04 169.69 275.41 182.48 234.84 192.37 234.75 214.31 239.32 246.49 255.21 265.13 366.06 255.06 373.04 169.69"
          />
        </clipPath>
        <clipPath id="clip-path-9">
          <path
            class="cls-2"
            d="M321.57,240.45c4.11-9.16,25.21-75.25,36.14-75.61,11.52-.38,21.09,5.37,24.39,0s11-19.29,15.76-23,18.38-46.08,32.67-40.65,42.07,33,42.07,33l16.49,103S317.47,249.61,321.57,240.45Z"
          />
        </clipPath>
        <clipPath id="clip-path-10">
          <polygon
            class="cls-2"
            points="490.8 195.23 393.17 208.03 352.6 217.92 352.51 239.86 357.08 272.04 372.96 290.68 483.81 280.61 490.8 195.23"
          />
        </clipPath>
        <clipPath id="clip-path-11">
          <path
            class="cls-2"
            d="M439.33,266c4.1-9.16,25.21-75.24,36.13-75.6,11.52-.38,21.1,5.36,24.4,0s11-19.29,15.75-23,18.39-46.07,32.68-40.64,42.07,33,42.07,33l16.48,103S435.23,275.15,439.33,266Z"
          />
        </clipPath>
      </defs>
      <title>aquisition_1lane</title>
      <g class="cls-3">
        <g id="Fundo">
          <path class="cls-4" d="M2,251.11H513V1.11H2v250Z" />
          <path
            class="cls-5"
            d="M361.81,77.26c-44,4.06-85,17.5-127.13,27.45S146.24,121.13,104.19,111c-20.79-5-40-13.92-61.86-15.54-20.4-1.52-40.57,3.57-59.4,9.35S-54.66,117.25-75,119.1c-16.95,1.54-35.66-1-48.37-8.68-2.54-1.54-4.84-2.72-6.84-5.26.42-4.7.83-5.61,1.25-9.89.42-3.54.83-8.84,1.25-9.89.41-3.75.83-7,1.25-9.88.41-3.48.83-7.94,1.25-9.89.41-1.51.83-6.59,1.25-9.89l1.24-9.88,10.9,2.91c3.65,1.57,7.31,2,11,2.89s7.37-1,11.07,2.81c3.71,5.12,7.43,1.7,11.16,2.72s7.47.87,11.21,2.58L-61.77,61c1.88.4,3.76.8,5.65,1.18,3.76-.83,7.54.94,11.32,2.2s7.56,1,11.35,2c3.79-.58,7.59-2,11.38,1.67,3.8-.44,7.6-.49,11.4,1.35q5.7.59,11.4,1c22.8,1.63,45.58.93,68-3.37C80,64.78,91.35,61.68,103,62.53c21.83,1.6,36.53,16,54.77,24.68,23.08,11,54.45,12.46,79.41,3.82,22.2-7.69,40.56-22.91,65.25-23.57,15.12-.41,29.56,4.93,44.69,5.09,12.39.13,24.3-3.21,36.58-4.42a127,127,0,0,1,40.67,3.08C436.46,74,454,78.69,463,85.34c10.29,7.6-13.26,1-17-.22a181.48,181.48,0,0,0-38.29-7.78A241.77,241.77,0,0,0,361.81,77.26Z"
          />
          <path
            class="cls-5"
            d="M359,108.21c-28.13,1.58-55,8.56-82.28,13.62s-56.56,8.2-83.43,2.1c-13.53-3.07-26.14-8.42-40.21-9.84-22.14-2.22-43.52,5.56-63.74,12.34s-43.48,12.87-64.43,7.3C12.66,130.46,3,123.61-8.3,119c-26-10.7-58.54-9-87.84-4.2s-58.16,12.39-88.23,12.49c44.7-2.7,90.51-5.34,133.76,3.12,31.31,6.12,61.6,18,94,16.27,22.9-1.24,44.2-9.31,67.07-10.77,28.84-1.85,56.93,6.91,85.83,8.19,33.31,1.48,65.66-7,97.57-13.94s66.51-12.42,98.25-5.1c8,1.85,15.76,4.5,24.1,5.37,6.35.66,20.46.69,17.21-5.84-2.34-4.72-15.17-8.66-21-10.54C395.79,108.71,377,107.21,359,108.21Z"
          />
          <path
            class="cls-5"
            d="M391.34,34c-25.2,7.53-49.37,17-75.62,22.4s-55.72,6.43-79.9-2.62C220.43,48,207.48,38.35,190.39,36c-29.7-4.12-59.57,15.41-88.45,9-9.78-2.17-17.85-7.08-27.1-10.2-2.29-.78-4.61-4-7-2-2.34.4-4.71.72-7.1-1.34-2.39-.6-4.8-1.4-7.23-.78-1.82.59-3.65-3.32-5.5-.25-1.84,3.32-3.69-2.26-5.55,0-1.86-.31-3.72-2.85-5.6-1.55C35,27.75,33.15,30.58,31.27,31c-1.89,1.78-3.78-4.7-5.68.51-1.9-.32-3.8,1-5.7.74a29.45,29.45,0,0,1-5.73.66,19.29,19.29,0,0,0-5.74,1C4.59,32.7.76,38.48-3.07,35.74c-1.91.31-3.82.6-5.73.89l-5.72.84a32.09,32.09,0,0,0-11.37,1.41c-4,1.26-8,4.14-12,.91-4-1.32-8,1.12-12.05.25-4,0-8-.16-12-.4-28-1.68-55.46-8.55-78.53-20,25.36,14.88,61.5,16.07,94.13,13,3.62-.34,7.25-1.58,10.87-1.14,3.62-1.35,7.24-.38,10.86-1.3l10.85-1.36q5.43-.68,10.85-1.33c3.62-2.41,7.24-2.31,10.86-1.2,3.62-2.21,7.24-.6,10.87-1,1.81-1.73,3.62-1.81,5.44-2.55,1.81,3.49,3.62.34,5.44,1.92,1.81,1.23,3.63-.8,5.45,0,1.81,1,3.63-.72,5.45-.24,1.82-3.52,3.63-.29,5.45.1,1.82-6.05,3.65,2.72,5.47.23,3.81-2.11,7.63,2.56,11.45,1,3.81,2,7.63.89,11.44,1.4,22.88,3.05,45.66,7.21,68,3.41,15.47-2.62,30.29-9,46.08-7.64,11.94,1,22.11,6.36,33,9.94,39,12.83,85.58,2.73,122.58-12.81C369,9.68,396.27-3.8,424.31,1.43c7.65,1.42,46,17.67,32.5,21.37-4.41,1.21-11.92.39-16.78.89-5.58.56-11.11,1.39-16.57,2.39A308,308,0,0,0,391.34,34Z"
          />
          <path
            class="cls-6"
            d="M378.59,22.49a11.39,11.39,0,0,0-10.32-6.94A11.52,11.52,0,0,0,357.4,24a10,10,0,0,0-2.14-.23,9.2,9.2,0,0,0-8.81,5.89,9.23,9.23,0,0,0-2.6-.36c-4.76,0-8.62,3.45-8.62,7.7a7,7,0,0,0,1,3.66c-6,.61-21.52,1.54-22.89,2-1.68.53,14.35,1,19.57,1.38,1.1.09,2.28.75,2.28,1.19,0,4.54,5.24,8.22,11.69,8.22a15.06,15.06,0,0,0,6.92-1.6,9.14,9.14,0,0,0,7.45,3.62,9.29,9.29,0,0,0,7.07-3.12,11.86,11.86,0,0,0,7,2.12,11.36,11.36,0,0,0,7.79-2.79A16.48,16.48,0,0,0,391,53.39c5.78,0,10.48-2.37,10.54-5.31a72,72,0,0,1,8.12-.59c7.67-.12,22.25-.28,23.22-.86s-8.22-2-20.69-2.76a2.6,2.6,0,0,0,.56-1.58c0-1.86-2.17-3.38-4.85-3.38a6.32,6.32,0,0,0-2.89.67c-.16-3.13-3.58-5.64-7.76-5.64a10,10,0,0,0-1.35.09,7.43,7.43,0,0,0-3-4c-.4-4.68-4.74-8.37-10-8.37A11,11,0,0,0,378.59,22.49Z"
          />
          <path
            class="cls-6"
            d="M378.59,22.49a11.39,11.39,0,0,0-10.32-6.94A11.52,11.52,0,0,0,357.4,24a10,10,0,0,0-2.14-.23,9.2,9.2,0,0,0-8.81,5.89,9.23,9.23,0,0,0-2.6-.36c-4.76,0-8.62,3.45-8.62,7.7a7,7,0,0,0,1,3.66c-6,.61-21.52,1.54-22.89,2-1.68.53,14.35,1,19.57,1.38,1.1.09,2.28.75,2.28,1.19,0,4.54,5.24,8.22,11.69,8.22a15.06,15.06,0,0,0,6.92-1.6,9.14,9.14,0,0,0,7.45,3.62,9.29,9.29,0,0,0,7.07-3.12,11.86,11.86,0,0,0,7,2.12,11.36,11.36,0,0,0,7.79-2.79A16.48,16.48,0,0,0,391,53.39c5.78,0,10.48-2.37,10.54-5.31a72,72,0,0,1,8.12-.59c7.67-.12,22.25-.28,23.22-.86s-8.22-2-20.69-2.76a2.6,2.6,0,0,0,.56-1.58c0-1.86-2.17-3.38-4.85-3.38a6.32,6.32,0,0,0-2.89.67c-.16-3.13-3.58-5.64-7.76-5.64a10,10,0,0,0-1.35.09,7.43,7.43,0,0,0-3-4c-.4-4.68-4.74-8.37-10-8.37A11,11,0,0,0,378.59,22.49Z"
          />
          <g class="cls-7">
            <path
              class="cls-6"
              d="M379.67,18.72a11.41,11.41,0,0,0-10.32-6.95,11.53,11.53,0,0,0-10.87,8.42,10,10,0,0,0-2.14-.23,9.2,9.2,0,0,0-8.81,5.88,9.68,9.68,0,0,0-2.61-.36c-4.75,0-9.89,2.56-9.89,6.81,0,1.32-1.48,2.89-.82,4-6,.62-21.82,5.87-23.2,6.31-1.67.53,17.62-.75,22.84-.34,1.1.08,2.29.75,2.29,1.19,0,4.54,5.41,6.18,11.86,6.18A15.18,15.18,0,0,0,354.92,48a9.51,9.51,0,0,0,14.51.5,11.91,11.91,0,0,0,7,2.11,11.34,11.34,0,0,0,7.79-2.79A16.35,16.35,0,0,0,392,49.61c5.78,0,10.48-2.37,10.55-5.3,1,1.92,2.66,1.82,6.19,1.75,2.56,0,25.49.62,26.46,0s-9.53-5.23-22-6a2.55,2.55,0,0,0,.56-1.57c0-1.87-2.17-3.38-4.85-3.38a6.29,6.29,0,0,0-2.89.67c-.16-3.14-3.58-5.64-7.77-5.64a11.9,11.9,0,0,0-1.34.08,7.56,7.56,0,0,0-3-4c-.41-4.67-4.74-8.36-10-8.36A11,11,0,0,0,379.67,18.72Z"
            />
          </g>
          <path
            class="cls-6"
            d="M386.7,42a5.42,5.42,0,0,1-10,3.83,5.17,5.17,0,0,0,.19.64,5.39,5.39,0,0,0,10.16-3.62A6,6,0,0,0,386.7,42Z"
          />
          <path
            class="cls-6"
            d="M403.91,40.29a5,5,0,0,1-9.13,3.49,3.92,3.92,0,0,0,.16.56A5,5,0,0,0,404.25,41,4.43,4.43,0,0,0,403.91,40.29Z"
          />
          <path
            class="cls-6"
            d="M376.74,30.28a5.41,5.41,0,0,1,9.09,5.57,4.19,4.19,0,0,0,.4-.54,5.39,5.39,0,0,0-9.05-5.86A5.5,5.5,0,0,0,376.74,30.28Z"
          />
          <path
            class="cls-6"
            d="M370.12,43.31a5.42,5.42,0,0,1-9.95,3.83,3.83,3.83,0,0,0,.18.64,5.39,5.39,0,0,0,10.16-3.62A6,6,0,0,0,370.12,43.31Z"
          />
          <path
            class="cls-6"
            d="M364.44,33.71A5.42,5.42,0,0,1,372,26.18c-.13-.17-.28-.35-.43-.51a5.4,5.4,0,0,0-7.83,7.42A5.17,5.17,0,0,0,364.44,33.71Z"
          />
          <path
            class="cls-6"
            d="M349.32,41.86a6.27,6.27,0,0,1,8.89-8.55,6.89,6.89,0,0,0-.49-.61,6.24,6.24,0,0,0-9.2,8.44A5.42,5.42,0,0,0,349.32,41.86Z"
          />
          <path
            class="cls-6"
            d="M379.13,102c-.49-3-3.65-5.3-7.47-5.3s-7.2,2.48-7.5,5.63a2.65,2.65,0,0,0-2.17,1.82,3.36,3.36,0,0,0-1.06-.17,3.31,3.31,0,0,0-1.75.49,5.33,5.33,0,0,0-3.55-1.33,5.28,5.28,0,0,0-5.06,3.65,5.63,5.63,0,0,0-1.75-.28,5.2,5.2,0,0,0-5.27,4.27c-1.94.17-3.45,1.35-3.45,2.79a2,2,0,0,0,.15.76h0a1.37,1.37,0,0,0-1.45,1.26,1.11,1.11,0,0,0,.14.54c-6.25.52-13.94,1-14.76,1.09-1.39.17,104.05-.22,104.05-.22s-6.57-.42-10.39-.89c-2.74-.33-2-.76-2.23-1.31-.3-.74-1.48-1.3-2.88-1.3a4.81,4.81,0,0,0-1.3.17c-.11-1.62-2.12-2.91-4.58-2.91a6.08,6.08,0,0,0-3,.72,3.27,3.27,0,0,0-3.11-2.54,5.06,5.06,0,0,0-5-4,5.29,5.29,0,0,0-2.63.69,5.48,5.48,0,0,0-5.1-2.87,6.31,6.31,0,0,0-3.35.92,4.42,4.42,0,0,0-3.79-2.06A4.57,4.57,0,0,0,379.13,102Z"
          />
          <path
            class="cls-6"
            d="M379.13,102c-.49-3-3.65-5.3-7.47-5.3s-7.2,2.48-7.5,5.63a2.65,2.65,0,0,0-2.17,1.82,3.36,3.36,0,0,0-1.06-.17,3.31,3.31,0,0,0-1.75.49,5.33,5.33,0,0,0-3.55-1.33,5.28,5.28,0,0,0-5.06,3.65,5.63,5.63,0,0,0-1.75-.28,5.2,5.2,0,0,0-5.27,4.27c-1.94.17-3.45,1.35-3.45,2.79a2,2,0,0,0,.15.76h0a1.37,1.37,0,0,0-1.45,1.26,1.11,1.11,0,0,0,.14.54c-6.25.52-13.94,1-14.76,1.09-1.39.17,104.05-.22,104.05-.22s-6.57-.42-10.39-.89c-2.74-.33-2-.76-2.23-1.31-.3-.74-1.48-1.3-2.88-1.3a4.81,4.81,0,0,0-1.3.17c-.11-1.62-2.12-2.91-4.58-2.91a6.08,6.08,0,0,0-3,.72,3.27,3.27,0,0,0-3.11-2.54,5.06,5.06,0,0,0-5-4,5.29,5.29,0,0,0-2.63.69,5.48,5.48,0,0,0-5.1-2.87,6.31,6.31,0,0,0-3.35.92,4.42,4.42,0,0,0-3.79-2.06A4.57,4.57,0,0,0,379.13,102Z"
          />
          <g class="cls-8">
            <path
              class="cls-6"
              d="M392.93,114.84c.14.5.81.86,1.25,1a5.19,5.19,0,0,0,4.08-.42c.4-.24,1.06-1.08,1.57-1s.89.71,1.26,1a4.08,4.08,0,0,0,2.27,1,4.8,4.8,0,0,0,3-.82c.37-.24.94-.93,1.4-.94.28,0,.56.4.72.58a2.76,2.76,0,0,0,1.28.84,5.71,5.71,0,0,0,2.95-.28,7,7,0,0,1,1-.35c.45-.07.81.53,1.25.63a3.28,3.28,0,0,0,3.47-1.78c.91-1.83-1-3.15-2.33-4.13a13.77,13.77,0,0,0-6.39-2.49c-1.71-.26-3.47-.16-5-1.06-5.1-2.26-8.54-6.57-13.58-9-7-3.4-14.77-3.53-22.41-3-5,.36-9.93,1-14.45,3.27a22,22,0,0,0-6.75,5.36c-1.65,2-2.82,5-5,6.46a2.32,2.32,0,0,0-.69,1.25,3.12,3.12,0,0,0,1.28,3.52A3.64,3.64,0,0,0,346,115c.55-.13,1.17-.59,1.75-.48s.94.59,1.38.86a6.56,6.56,0,0,0,3.05,1,9.43,9.43,0,0,0,4.71-1.3,7,7,0,0,0,1.34-.93c.17-.16.42-.48.69-.48.52,0,1.22,1,1.63,1.27a6.57,6.57,0,0,0,3,.91,10.06,10.06,0,0,0,5.07-.94,15,15,0,0,0,1.52-.84c.23-.15.55-.48.85-.48.59,0,1.23,1.06,1.59,1.42a4.22,4.22,0,0,0,2.57,1.19,17.55,17.55,0,0,0,3.76-.28c1.6-.14,3.11.07,4.6-.66a6,6,0,0,0,1.75-1.42c.21-.23.52-.79.84-.88s.28.53.31.63a2.68,2.68,0,0,0,1,1.77c1.58,1,4,.31,5.44-.54"
            />
          </g>
          <path
            class="cls-6"
            d="M361.28,107.34a5.86,5.86,0,0,1-11,3.22,5.78,5.78,0,1,0,11.33-2.31A5.16,5.16,0,0,0,361.28,107.34Z"
          />
          <path
            class="cls-6"
            d="M387.72,108a5.85,5.85,0,0,1-11,3.23,5.58,5.58,0,0,0,.13.69A5.8,5.8,0,1,0,387.72,108Z"
          />
          <path
            class="cls-6"
            d="M377.18,114.58a4.76,4.76,0,0,1-5.67-7.4l-.48.3a4.72,4.72,0,0,0,5.55,7.62A4.31,4.31,0,0,0,377.18,114.58Z"
          />
          <path
            class="cls-6"
            d="M400.57,110.09a4.76,4.76,0,0,1-9.22,1.38,3.64,3.64,0,0,0,0,.57,4.72,4.72,0,0,0,9.35-1.18A5,5,0,0,0,400.57,110.09Z"
          />
          <path
            class="cls-6"
            d="M88.19,92.54C87,85.25,79.31,79.61,70,79.61c-9.64,0-17.55,6-18.29,13.73a6.46,6.46,0,0,0-5.29,4.45,8.17,8.17,0,0,0-2.59-.42,8,8,0,0,0-4.26,1.2,13,13,0,0,0-8.65-3.26,12.88,12.88,0,0,0-12.33,8.9,14,14,0,0,0-4.28-.67c-6.57,0-12,4.54-12.85,10.42-4.73.4-8.4,3.29-8.4,6.78a5,5,0,0,0,.37,1.87h-.11a3.32,3.32,0,0,0-3.53,3.06A2.73,2.73,0,0,0-9.87,127c-15.24,1.27-34,2.4-36,2.65-3.4.41,253.63-.54,253.63-.54s-16-1-25.33-2.15c-6.67-.81-4.91-1.87-5.44-3.2-.72-1.82-3.59-3.18-7-3.18a11.8,11.8,0,0,0-3.17.42c-.25-3.94-5.16-7.08-11.16-7.08a14.9,14.9,0,0,0-7.21,1.74c-.45-3.24-3.6-5.82-7.58-6.2a12.32,12.32,0,0,0-12.25-9.62,13,13,0,0,0-6.43,1.69c-1.8-4.08-6.69-7-12.43-7a15.31,15.31,0,0,0-8.16,2.26,10.78,10.78,0,0,0-9.25-5A11.25,11.25,0,0,0,88.19,92.54Z"
          />
          <path
            class="cls-6"
            d="M88.19,92.54C87,85.25,79.31,79.61,70,79.61c-9.64,0-17.55,6-18.29,13.73a6.46,6.46,0,0,0-5.29,4.45,8.17,8.17,0,0,0-2.59-.42,8,8,0,0,0-4.26,1.2,13,13,0,0,0-8.65-3.26,12.88,12.88,0,0,0-12.33,8.9,14,14,0,0,0-4.28-.67c-6.57,0-12,4.54-12.85,10.42-4.73.4-8.4,3.29-8.4,6.78a5,5,0,0,0,.37,1.87h-.11a3.32,3.32,0,0,0-3.53,3.06A2.73,2.73,0,0,0-9.87,127c-15.24,1.27-34,2.4-36,2.65-3.4.41,253.63-.54,253.63-.54s-16-1-25.33-2.15c-6.67-.81-4.91-1.87-5.44-3.2-.72-1.82-3.59-3.18-7-3.18a11.8,11.8,0,0,0-3.17.42c-.25-3.94-5.16-7.08-11.16-7.08a14.9,14.9,0,0,0-7.21,1.74c-.45-3.24-3.6-5.82-7.58-6.2a12.32,12.32,0,0,0-12.25-9.62,13,13,0,0,0-6.43,1.69c-1.8-4.08-6.69-7-12.43-7a15.31,15.31,0,0,0-8.16,2.26,10.78,10.78,0,0,0-9.25-5A11.25,11.25,0,0,0,88.19,92.54Z"
          />
          <g class="cls-9">
            <path
              class="cls-6"
              d="M121.83,123.89c.35,1.21,2,2.09,3,2.45a12.6,12.6,0,0,0,9.94-1c1-.57,2.6-2.63,3.84-2.44s2.18,1.74,3.07,2.48a10,10,0,0,0,5.54,2.31,11.84,11.84,0,0,0,7.38-2c.89-.57,2.28-2.26,3.4-2.28.69,0,1.37,1,1.77,1.4a6.77,6.77,0,0,0,3.12,2.06,14,14,0,0,0,7.18-.68,18.14,18.14,0,0,1,2.44-.86c1.11-.16,2,1.29,3.05,1.54,3.37.75,7-1.36,8.46-4.34,2.22-4.47-2.52-7.67-5.67-10.06-4.66-3.52-9.86-5.17-15.58-6.07-4.16-.65-8.46-.4-12.21-2.58-12.44-5.53-20.81-16-33.1-22-17.18-8.3-36-8.63-54.64-7.3-12.17.87-24.21,2.35-35.22,8A53.85,53.85,0,0,0,11.19,95.57c-4,4.8-6.86,12.07-12.07,15.73a5.67,5.67,0,0,0-1.7,3,7.61,7.61,0,0,0,3.12,8.58,8.86,8.86,0,0,0,6.91,1.28c1.34-.32,2.85-1.44,4.27-1.16,1.27.25,2.27,1.43,3.35,2.08a15.65,15.65,0,0,0,7.44,2.51A23,23,0,0,0,34,124.47a16.58,16.58,0,0,0,3.25-2.26c.42-.4,1-1.18,1.68-1.18,1.26,0,3,2.45,4,3.1a16.13,16.13,0,0,0,7.38,2.23,24.53,24.53,0,0,0,12.37-2.31,30.85,30.85,0,0,0,3.69-2c.57-.37,1.36-1.16,2.09-1.17,1.44,0,3,2.58,3.88,3.46a10.28,10.28,0,0,0,6.25,2.91c3.12.35,6.09-.4,9.18-.68,3.88-.35,7.56.17,11.21-1.62a14.94,14.94,0,0,0,4.26-3.46c.5-.55,1.26-1.92,2-2.14.52-.14.68,1.28.75,1.54.4,1.58.88,3.42,2.37,4.31,3.85,2.31,9.64.76,13.25-1.32"
            />
          </g>
          <path
            class="cls-6"
            d="M44.68,105.6c.85,6.58-3.57,13-10.51,14.9a13.94,13.94,0,0,1-16.28-7,12,12,0,0,0,.33,1.67c1.91,7.13,9.57,11.29,17.1,9.27S47.41,115,45.5,107.82A12.5,12.5,0,0,0,44.68,105.6Z"
          />
          <path
            class="cls-6"
            d="M109.14,107.2c.85,6.59-3.57,13-10.52,14.91a13.94,13.94,0,0,1-16.28-7,16.42,16.42,0,0,0,.33,1.67c1.92,7.13,9.58,11.29,17.11,9.27s12.09-9.45,10.18-16.58A13.78,13.78,0,0,0,109.14,107.2Z"
          />
          <path
            class="cls-6"
            d="M83.44,123.25a11.6,11.6,0,0,1-13.8-18,11.1,11.1,0,0,0-1.18.74c-4.85,3.54-5.76,10.57-2,15.7s10.71,6.41,15.56,2.87A10.39,10.39,0,0,0,83.44,123.25Z"
          />
          <path
            class="cls-6"
            d="M140.46,112.31A11.6,11.6,0,0,1,118,115.68a9.28,9.28,0,0,0,.08,1.38c.75,6,6.46,10.15,12.75,9.36s10.79-6.27,10-12.23A10.47,10.47,0,0,0,140.46,112.31Z"
          />
          <path
            class="cls-6"
            d="M174.72,83.64a8.69,8.69,0,0,0-7-3.29c-4.09,0-7.5,2.6-8.24,6.05-3.81.66-6.67,3.54-6.67,7a6.31,6.31,0,0,0,.11,1.15,7.77,7.77,0,0,0-2.88,6,8.1,8.1,0,0,0,5.63,7.54c0,.16,0,.33,0,.49,0,4.12,3.83,7.46,8.54,7.46a9.21,9.21,0,0,0,5.52-1.77,10,10,0,0,0,3,.47,9.82,9.82,0,0,0,4.62-1.15A11.47,11.47,0,0,0,184.6,116a11.19,11.19,0,0,0,8.26-3.44,11,11,0,0,0,6.86,2.14,11.91,11.91,0,0,0,5.6-1.31,8.7,8.7,0,0,0,4.74,1.31c3.67,0,6.67-2,6.84-4.44,9-.13,17.67-.22,20.63-.37,0,0-16.07-2.36-17.59-3.64l-.11-.1a3.62,3.62,0,0,0,.38-1.63c0-2.34-2.29-4.24-5.11-4.24a5.75,5.75,0,0,0-3.48,1.13,6.56,6.56,0,0,0-5-2,7.49,7.49,0,0,0-3.36.76c-.1-3.18-3.15-5.73-6.9-5.73a8.33,8.33,0,0,0-1.33.11,4.52,4.52,0,0,0,.06-.74c0-3.09-2.88-5.59-6.42-5.59a7.21,7.21,0,0,0-2.15.32,6.39,6.39,0,0,0-1.44-3,4.75,4.75,0,0,0-2.14-1.73,8.87,8.87,0,0,0-8.18-.12Z"
          />
          <path
            class="cls-6"
            d="M174.72,83.64a8.69,8.69,0,0,0-7-3.29c-4.09,0-7.5,2.6-8.24,6.05-3.81.66-6.67,3.54-6.67,7a6.31,6.31,0,0,0,.11,1.15,7.77,7.77,0,0,0-2.88,6,8.1,8.1,0,0,0,5.63,7.54c0,.16,0,.33,0,.49,0,4.12,3.83,7.46,8.54,7.46a9.21,9.21,0,0,0,5.52-1.77,10,10,0,0,0,3,.47,9.82,9.82,0,0,0,4.62-1.15A11.47,11.47,0,0,0,184.6,116a11.19,11.19,0,0,0,8.26-3.44,11,11,0,0,0,6.86,2.14,11.91,11.91,0,0,0,5.6-1.31,8.7,8.7,0,0,0,4.74,1.31c3.67,0,6.67-2,6.84-4.44,9-.13,17.67-.22,20.63-.37,0,0-16.07-2.36-17.59-3.64l-.11-.1a3.62,3.62,0,0,0,.38-1.63c0-2.34-2.29-4.24-5.11-4.24a5.75,5.75,0,0,0-3.48,1.13,6.56,6.56,0,0,0-5-2,7.49,7.49,0,0,0-3.36.76c-.1-3.18-3.15-5.73-6.9-5.73a8.33,8.33,0,0,0-1.33.11,4.52,4.52,0,0,0,.06-.74c0-3.09-2.88-5.59-6.42-5.59a7.21,7.21,0,0,0-2.15.32,6.39,6.39,0,0,0-1.44-3,4.75,4.75,0,0,0-2.14-1.73,8.87,8.87,0,0,0-8.18-.12Z"
          />
          <g class="cls-10">
            <path
              class="cls-6"
              d="M175.51,80.23a8.71,8.71,0,0,0-7-3.29c-4.1,0-7.51,2.6-8.25,6-3.8.67-6.66,3.55-6.66,7a6.12,6.12,0,0,0,.1,1.14,7.83,7.83,0,0,0-2.87,6,8.1,8.1,0,0,0,5.63,7.55c0,.16,0,.32,0,.49,0,4.12,3.83,7.46,8.54,7.46a9.3,9.3,0,0,0,5.52-1.77,10,10,0,0,0,3,.46,9.82,9.82,0,0,0,4.63-1.14,11.45,11.45,0,0,0,7.21,2.45,11.17,11.17,0,0,0,8.25-3.44,11.05,11.05,0,0,0,6.87,2.13,12,12,0,0,0,5.6-1.3,8.69,8.69,0,0,0,4.74,1.3c3.67,0,4.81-1.11,5-3.57,8.43,1.11,20.21,2.65,23.17,2.49,0,0-16.74-6.09-18.26-7.36l-.12-.1a3.63,3.63,0,0,0,.39-1.63c0-2.34-2.29-4.25-5.11-4.25A5.67,5.67,0,0,0,212.41,98a6.57,6.57,0,0,0-5-2.06,7.57,7.57,0,0,0-3.37.76c-.1-3.17-3.15-5.72-6.89-5.72a7.24,7.24,0,0,0-1.33.11,4.6,4.6,0,0,0,.05-.74c0-3.09-2.87-5.6-6.41-5.6a7.17,7.17,0,0,0-2.15.33,6.35,6.35,0,0,0-1.44-3,4.86,4.86,0,0,0-2.15-1.74,8.77,8.77,0,0,0-4.2-1A8.64,8.64,0,0,0,175.51,80.23Z"
            />
          </g>
          <path
            class="cls-6"
            d="M163.26,107.38c-1.12-1.68-.63-4.2,1.21-5.89s4.24-1.94,5.81-.82a4,4,0,0,0-.31-.41c-1.51-1.64-4.35-1.48-6.35.35s-2.4,4.65-.9,6.29A3.09,3.09,0,0,0,163.26,107.38Z"
          />
          <path
            class="cls-6"
            d="M203.56,107.28a6.27,6.27,0,0,1-11.08-5.44,5.25,5.25,0,0,0-.4.66,6.24,6.24,0,0,0,11.06,5.78A6.23,6.23,0,0,0,203.56,107.28Z"
          />
          <path
            class="cls-6"
            d="M213.39,103.25a3.66,3.66,0,0,1,2.67,4,3.61,3.61,0,0,1-3.93,2.58,3.94,3.94,0,0,0,.49.14,3.45,3.45,0,1,0,1.46-6.67A5.46,5.46,0,0,0,213.39,103.25Z"
          />
          <path
            class="cls-6"
            d="M179.46,105.92a5.11,5.11,0,0,1-9.29,3,2.4,2.4,0,0,0,.12.53c.68,2.25,3.35,3.44,6,2.65s4.18-3.26,3.5-5.51A3.33,3.33,0,0,0,179.46,105.92Z"
          />
          <path
            class="cls-6"
            d="M170.49,93.12a5.41,5.41,0,0,1,9.42-5,4.78,4.78,0,0,0-.26-.62A5.39,5.39,0,0,0,170,92.33,4.54,4.54,0,0,0,170.49,93.12Z"
          />
          <g class="cls-11">
            <path
              class="cls-12"
              d="M89.85,101.51C82.19,93.1,79.46,90,77.71,90.33c-4.9,1-17.39,15.85-19.21,13.56s-17.58-15.63-23.77-22c-1.54-1.6-3.49-3.5-5.63-5.53s-4.49-4.17-6.82-6.24c-2.33-6.64-4.65-5.14-6.75-5.84C12,63.68,9,56.9,6.68,57.57c-2.27-.9-3.79-1.71-4.36-1.34-.28.38-.53-1.34-.76,2.79-.23,2.41-.44,3.67-.62,6.29C-1.82,104.71.45,257.46.45,257.46s280.43-21.43,270.63-30S148.26,131.22,145.86,129c-1.38-1.28-4.77,2.45-8.87,6-.39.34-2.57-.83-3-.5-2.63,2.26-4.06,5.67-5.76,6.16-1.37.39-6.06-4.1-12.06-10.54-.37-.62-.76-1.28-1.16-2-2.21-3.8-12-15.54-12.54-16.51s-2.24-3.86-2.24-3.86c-.67-1.12-2.4-.49-2.92-.4-1,.17-2-2.31-2.74-3.45C92.41,100.32,91.46,101.42,89.85,101.51Z"
            />
            <g class="cls-13">
              <path
                class="cls-14"
                d="M39.3,73.72,33.53,71c-3.47-1.61-8.09-2-12.72-6-4.62-2.12-9.24-4.18-12.71-6-3.46-2.44-5.78-3.78-5.78-2.73,0-2.43,1,.7,2.78,3.48,1.73,3.5,4.15,5.94,6.93,8.69l9,11.3,18.71,23.46-7.45,1.2L65,147.76l5,12.72v5.33L94.1,186.7,80.35,160.48,74.2,142l7-7.17L65.19,116l-6.5-12Z"
              />
              <polygon
                class="cls-14"
                points="118.03 117.02 79.93 88.73 77.72 90.33 99.46 121.92 95.36 121.92 103.04 130.5 107.99 138.19 115.85 149.8 123.36 158.03 148.8 181.55 133.43 151.16 132.92 143.65 145.4 128.76 124.23 130.87 118.03 117.02"
              />
              <path
                class="cls-15"
                d="M80.48,83.11l-9.92,12L86.62,119.5l-7.24,2,4.09,6.08,12.36,37.24,43.92,8.41s-6.19-20.23-10.09-22-18.86-19.89-21.23-22.85-5.07-20-6.77-23.48S80.48,83.11,80.48,83.11Z"
              />
              <path
                class="cls-15"
                d="M28.25,58a11.26,11.26,0,0,0-8.18-.5c-3.84-2.27-8.28,0-11.75-.92-3.48-3.31-6,1-6-.49,0,2.68,1.18,3,2.93,6.13C7,66.55,9.35,70.76,11.7,75.66l9.37,19.63s-1,7.18-2.47,10.46,3.9,10.3,5.75,15.71,10.26,32.13,11.28,35.29,34.93,37.42,34.93,37.42l18-18s-10.89-11.35-13.35-17.71-3.08-27.62-10.06-30.32-17-13-17-13,.89-5.54,0-9.44-20-30.27-20-30.27.25-1.35.55-3.3a58.35,58.35,0,0,0,.91-9C29.76,60.08,29.43,60.5,28.25,58Z"
              />
              <path
                class="cls-15"
                d="M188.44,152.43c-5.61-3.85-43-23.67-43-23.67s6.8,15.1,9.25,16.5a71.18,71.18,0,0,1,7.18,5.42l-4.21,6.07s4.38,5,7.18,7.41,36.41,32.22,36.41,32.22,21.71-6.93,21.19-9.68S194,156.29,188.44,152.43Z"
              />
            </g>
            <path
              class="cls-16"
              d="M245.11,221c-8.05-2.75-102.85-68.52-109.16-62.58-4.31,4.07-11.9,8.18-17.26,7.61-7.75-5.32-20.76-20.37-23.8-18C89.25,152.46,3.12,261.74,10.71,255.7c4.39-3.49,250,7.6,250,7.6S253.15,223.71,245.11,221Z"
            />
            <g class="cls-17">
              <polygon
                class="cls-18"
                points="124.7 165.14 118.69 165.99 118.21 194.19 118.21 201.5 120 208.61 126.9 218.35 141.12 231.48 126.02 211.13 125.04 205.53 127.33 200.19 121.64 188.27 132.26 192.53 122.52 178.2 125.17 171.85 134.29 168.21 137.18 158 124.7 165.14"
              />
              <path
                class="cls-19"
                d="M201.94,186.7c-4.2-.16-44.82-25.37-48.79-26.07s-16.1-3.88-16.1-3.88L152.92,180l-5.13,1.87,1.63,4.83-.7,9.68,11.2,21.9,26.61,33.85s3.74-46.45,6.77-50.19S206.14,186.86,201.94,186.7Z"
              />
              <path
                class="cls-19"
                d="M76.4,156.75c3.78-3.59,15.16-13.15,17.7-10.93s5.42,11.12,3.87,14-4.23,7.61,1.11,13.36,11.34,18.87,11.34,18.87L99.08,186.7S112,209.84,116.42,215s9.78,12.67,12,19.11,7.78,27.79,5.12,25.35-48.24-22.46-51.8-22-26-16.67-24.45-20S72.63,160.34,76.4,156.75Z"
              />
            </g>
          </g>
          <path
            class="cls-20"
            d="M195.5,213.34a30.67,30.67,0,0,1-3.6-9.53c-1.66,5.93-4.36,9.51-7.19,13.17l1.63-.65-3.09,4.08,2.39-1.75-2.18,3.44,1.89-1.13-6.58,11.86,1.39-.61-1,1.84,1-.61s-4.62,6.46-4.2,6,3.59-3,3.59-3l-7,13.54,1.26-.68-1.42,1.91s-3.19,4.61-2.52,4.12c.39-.28,1.85-1.43,3-2.34l-1.12,2.81,6.11-.09,6.54-.57,2.36.19,10.44.6s1.16.3,2.14.6,1.52-2.29,1.52-2.29a28.64,28.64,0,0,1,3.73,2c1.15.84-.24-2.6-.24-2.6a16,16,0,0,1,2.46,2.06c1.07,1.14-.62-2.23-.62-2.23s1.53-.15,4,1.73c1.16.9.58.06-1-2.63l2.93,2.3s-2.58-4.91-2.33-4.87-3.2-4.68-3.63-5.45,1.91,1.17,2.07,1.39-1.7-3-2.13-3.59-4.56-8.33-4.56-8.33.54.33,1.09.73-1.14-2.74-1.38-3.58,1.52,1.34,2.26,2.06-.34-.72-2.56-4.18a89.33,89.33,0,0,1-4.77-9.17,5.07,5.07,0,0,1,1.29,1c.44.53-.91-3-1.1-3.89s1.68,1.53,2.16,2.16S197.31,216.57,195.5,213.34Z"
          />
          <path
            class="cls-20"
            d="M125.23,213.34a31.23,31.23,0,0,1-3.61-9.53c-1.66,5.93-4.35,9.51-7.19,13.17l1.64-.65L113,220.41l2.39-1.75-2.18,3.44,1.89-1.13-6.58,11.86,1.39-.61-1,1.84,1-.61s-4.62,6.46-4.2,6,3.59-3,3.59-3l-7,13.54,1.26-.68-1.43,1.91s-3.19,4.61-2.52,4.12c.4-.28,1.86-1.43,3-2.34-.71,1.78-1.13,2.81-1.13,2.81l6.12-.09,6.53-.57,2.37.19,10.44.6s1.15.3,2.13.6,1.53-2.29,1.53-2.29a28.75,28.75,0,0,1,3.72,2c1.16.84-.23-2.6-.23-2.6a16,16,0,0,1,2.46,2.06c1.06,1.14-.63-2.23-.63-2.23s1.54-.15,4,1.73c1.16.9.59.06-1-2.63l2.93,2.3s-2.58-4.91-2.34-4.87-3.19-4.68-3.62-5.45,1.9,1.17,2.06,1.39-1.69-3-2.12-3.59-4.56-8.33-4.56-8.33.54.33,1.08.73-1.13-2.74-1.38-3.58,1.53,1.34,2.27,2.06-.35-.72-2.56-4.18a90.29,90.29,0,0,1-4.78-9.17,5,5,0,0,1,1.3,1c.43.53-.91-3-1.1-3.89s1.68,1.53,2.16,2.16S127,216.57,125.23,213.34Z"
          />
          <path
            class="cls-20"
            d="M103.91,214.85a30.57,30.57,0,0,1-3.61-9.52c-1.66,5.93-4.35,9.5-7.19,13.17l1.64-.65-3.09,4.08,2.39-1.75-2.18,3.44,1.88-1.14-6.57,11.87,1.38-.62-1,1.85,1-.62s-4.61,6.46-4.19,6S88,238,88,238l-7,13.54,1.26-.68-1.43,1.91s-3.19,4.61-2.52,4.12c.39-.29,1.86-1.43,3-2.34-.72,1.77-1.13,2.81-1.13,2.81l6.12-.1,6.53-.56,2.37.19,10.43.6s1.16.3,2.14.6,1.52-2.29,1.52-2.29a28.64,28.64,0,0,1,3.73,2c1.16.84-.23-2.6-.23-2.6a15,15,0,0,1,2.45,2.06c1.07,1.14-.62-2.23-.62-2.23s1.54-.15,4,1.73c1.16.9.58.06-1-2.63l2.93,2.3s-2.59-4.91-2.34-4.87-3.2-4.68-3.63-5.45,1.91,1.17,2.07,1.38-1.7-3-2.13-3.59-4.55-8.32-4.55-8.32.54.33,1.08.72-1.13-2.74-1.38-3.58,1.53,1.35,2.26,2.07-.34-.72-2.55-4.18a88.1,88.1,0,0,1-4.78-9.17,5,5,0,0,1,1.3,1c.43.52-.91-3-1.11-3.89s1.68,1.53,2.16,2.16S105.71,218.09,103.91,214.85Z"
          />
          <path
            class="cls-21"
            d="M118.28,252.21,125,255.1l-4.23-9.19A9.28,9.28,0,0,1,122.2,247c.42.48-1.14-2.18-1.39-2.79s1.63,1.66,1.63,1.66l-4.73-13.32s1.3,1.74,1.64,2.09-2.27-4.81-2.46-5.16.43.38.82.73-1-2.4-1-2.4l1.6,2.09c.53.69-5.25-14.47-5.25-14.47l1.62,1.6-2.2-5.49.81.81c.31.31-1.5-2.76-1.62-3.06s1,.86,1.51,1.45-4.21-8.84-4.38-9.16c0,0,0,0,0,.06l-2.58,6.18.87-.8-2.47,7.67,1.32-1.22s-2.65,6.39-2.49,6.21l.75-.93s-1,2.57-.89,2.37.65-.73.65-.73-4.71,10.93-4.4,10.46a26.84,26.84,0,0,1,2-2.1l-2,3.61.78-.44-3.77,10a24.92,24.92,0,0,1,2.29-3.13c-.11.34-1.05,2.32-.8,2.11a1.31,1.31,0,0,1,.51-.26s-1.87,4.17-3.7,7.56c0,0,1.7-1.13,1.7-1.13l-.94,2.42s-2,4-1.28,3.58a17,17,0,0,1,3.68-.91l8.93,0Z"
          />
          <path
            class="cls-20"
            d="M144.51,208.59h0l-3.3,5.81.94-.79-1.46,2.6,1.08-.92-3,7.51,1-.88-2.22,4,.69-.67-.46,1.36.54-.55-2.2,3.56.85-.82-.54,1.25-.54,1.58.87-1.17-2.66,5.3.94-1.24-3.93,7.86,1.33-.92-.91,1.83,1.06-.91L128,250.5l1.18-1-.9,2.53,1.5-1.36-2.34,5.53h.52c-.41,1.32-.6,2-.5,2l.11,0,0,0,.11-.05c2.85-.42,35.1-1.06,35.1-1.06h1l-4.6-9.67,1.82,1.31-1.51-3.55.75.32-2.58-3.26,1.37.72-1.44-2.36,1.58,1-4.38-8,.8.39-1-1.61,1.1.8-1.35-1.59h.25l-.39-1,1.09,1.34-5.32-9.09.89.46-.81-1.33.95.76-1.44-2.35.63.61L148,216.81l.67.81L147,213.49l.5.19-3-5.05v0Z"
          />
          <path
            class="cls-21"
            d="M144.11,255.49l6.75,2.88-4.22-9.18a9.28,9.28,0,0,1,1.39,1.13c.42.48-1.15-2.18-1.39-2.79s1.63,1.66,1.63,1.66l-4.74-13.33s1.31,1.75,1.65,2.09-2.27-4.8-2.47-5.15.44.38.82.73-1-2.4-1-2.4l1.59,2.09c.53.69-5.24-14.47-5.24-14.47l1.62,1.6-2.2-5.49s.5.51.81.81-1.51-2.76-1.62-3.06,1,.86,1.5,1.45-4.21-8.84-4.37-9.16c0,0,0,0,0,.06l-2.58,6.18.86-.8L130.46,218l1.32-1.22s-2.65,6.39-2.5,6.21.76-.93.76-.93-1,2.57-.89,2.37.65-.73.65-.73-4.71,10.93-4.4,10.46a26.84,26.84,0,0,1,1.95-2.1l-1.95,3.61.78-.44-3.78,10a23.88,23.88,0,0,1,2.3-3.13c-.11.34-1,2.32-.8,2.11a1.25,1.25,0,0,1,.51-.26s-1.88,4.17-3.71,7.56c0,0,1.7-1.13,1.7-1.13l-.94,2.42s-2,4-1.27,3.57a17.59,17.59,0,0,1,3.68-.91l8.93,0Z"
          />
          <path
            class="cls-21"
            d="M157.72,209.67v0l-3.3,5.8.94-.78-1.46,2.59,1.09-.91-3,7.5,1-.87-2.21,4,.69-.67-.46,1.35.53-.55-2.19,3.56.84-.82-.54,1.25-.53,1.58.87-1.16-2.66,5.29.93-1.24-3.93,7.86,1.33-.92-.91,1.83,1.06-.9-4.62,8.14,1.17-1-.9,2.53,1.51-1.36-2.35,5.52h.53a7.07,7.07,0,0,0-.51,2l.11,0,0,0,.1,0c2.85-.42,35.11-1.06,35.11-1.06l1,0-4.59-9.66,1.81,1.31-1.51-3.56.76.32-2.59-3.25,1.37.71-1.44-2.35,1.58,1-4.38-8,.81.39-1-1.6,1.09.8-1.35-1.6h.26l-.4-1,1.1,1.33-5.33-9.08.89.45-.8-1.32.94.75-1.44-2.35.64.61-2.09-4.08.68.81-1.68-4.12.5.19-3-5.06,0,0A0,0,0,0,0,157.72,209.67Z"
          />
          <path
            class="cls-21"
            d="M177.54,209.67v0l-3.29,5.8.93-.78-1.46,2.59,1.09-.91-3,7.5,1-.87-2.21,4,.69-.67-.46,1.35.53-.55-2.19,3.56.84-.82-.54,1.25-.53,1.58.87-1.16-2.66,5.29.93-1.24-3.93,7.86,1.33-.92-.91,1.83,1.06-.9L161,251.58l1.17-1-.9,2.53,1.51-1.36-2.35,5.52H161c-.41,1.32-.6,2.05-.5,2l.1,0,0,0,.1,0c2.86-.42,35.11-1.06,35.11-1.06l1,0-4.6-9.66,1.81,1.31-1.51-3.56.76.32-2.59-3.25L192,244l-1.44-2.35,1.58,1-4.37-8,.8.39-1-1.6,1.09.8-1.35-1.6h.26l-.4-1,1.1,1.33-5.32-9.08.88.45L183,223l.94.75-1.43-2.35.63.61L181,217.89l.68.81L180,214.58l.5.19-3-5.06,0,0A0,0,0,0,0,177.54,209.67Z"
          />
          <path
            class="cls-22"
            d="M106.54,221.93a21.13,21.13,0,0,1-2.46-6.48,24.21,24.21,0,0,1-4.89,9l1.12-.45-2.11,2.78,1.63-1.19-1.48,2.34,1.28-.77-4.47,8.07.94-.42L95.39,236l.71-.42S93,240,93.25,239.71s2.43-2.05,2.43-2.05l-4.77,9.21.86-.46-1,1.29s-2.17,3.14-1.72,2.81l2-1.59-.77,1.9,4.16-.06,4.45-.38,1.61.12,7.1.41s.79.21,1.45.41,1-1.55,1-1.55a17.8,17.8,0,0,1,2.53,1.35c.79.57-.15-1.77-.15-1.77a10.69,10.69,0,0,1,1.67,1.4c.72.78-.43-1.52-.43-1.52a4.31,4.31,0,0,1,2.7,1.18c.79.61.4,0-.71-1.79l2,1.56a32.06,32.06,0,0,1-1.59-3.31c.16,0-2.18-3.18-2.47-3.7s1.3.79,1.41.94-1.16-2-1.45-2.45-3.1-5.66-3.1-5.66.37.22.74.49-.77-1.86-.94-2.43,1,.91,1.54,1.4-.24-.49-1.74-2.84a59.38,59.38,0,0,1-3.25-6.24,3.37,3.37,0,0,1,.88.67c.29.36-.62-2-.75-2.64s1.14,1,1.47,1.47A22.82,22.82,0,0,0,106.54,221.93Z"
          />
          <path
            class="cls-22"
            d="M152.13,225.13a22.69,22.69,0,0,1-2.66-7,26.5,26.5,0,0,1-5.31,9.73l1.21-.49-2.29,3,1.77-1.29-1.61,2.54,1.39-.84-4.85,8.76,1-.45-.78,1.36.78-.46s-3.41,4.77-3.1,4.46,2.65-2.24,2.65-2.24l-5.18,10,.93-.5L135,253.1s-2.35,3.4-1.85,3c.29-.21,1.37-1.06,2.22-1.73l-.84,2.08,4.52-.07,4.82-.42,1.75.14,7.71.44s.85.22,1.57.45,1.13-1.69,1.13-1.69a20.35,20.35,0,0,1,2.75,1.46c.85.63-.17-1.92-.17-1.92a12,12,0,0,1,1.81,1.52c.79.85-.46-1.64-.46-1.64a4.67,4.67,0,0,1,2.93,1.28c.86.66.43,0-.77-1.94l2.16,1.69a35.38,35.38,0,0,1-1.72-3.59c.18,0-2.36-3.46-2.68-4s1.41.87,1.53,1-1.25-2.19-1.57-2.66-3.37-6.14-3.37-6.14.4.24.8.53-.83-2-1-2.64,1.12,1,1.67,1.53-.26-.54-1.89-3.09a67.75,67.75,0,0,1-3.53-6.77,3.79,3.79,0,0,1,1,.73c.32.39-.67-2.19-.82-2.87s1.25,1.13,1.6,1.59A26.11,26.11,0,0,0,152.13,225.13Z"
          />
          <path
            class="cls-22"
            d="M170.51,227.72a20.9,20.9,0,0,1-2.45-6.48,24.22,24.22,0,0,1-4.9,9l1.12-.45-2.1,2.78,1.62-1.19-1.48,2.34,1.28-.77L159.13,241l.94-.42-.71,1.26.71-.42s-3.14,4.39-2.85,4.1,2.44-2.06,2.44-2.06l-4.78,9.21.86-.46-1,1.3s-2.17,3.14-1.71,2.8c.26-.19,1.26-1,2-1.59-.48,1.21-.77,1.91-.77,1.91l4.17-.06,4.44-.39,1.61.13,7.1.41s.79.2,1.45.41,1-1.56,1-1.56a19.52,19.52,0,0,1,2.54,1.35c.78.58-.16-1.76-.16-1.76a10.69,10.69,0,0,1,1.67,1.4c.73.77-.42-1.52-.42-1.52a4.33,4.33,0,0,1,2.7,1.18c.78.61.39,0-.71-1.79l2,1.56a32.06,32.06,0,0,1-1.59-3.31c.17,0-2.17-3.19-2.47-3.71s1.3.8,1.41,1-1.16-2-1.45-2.45-3.1-5.66-3.1-5.66.37.22.74.49-.77-1.86-.94-2.43,1,.91,1.54,1.4-.23-.49-1.74-2.84a62.69,62.69,0,0,1-3.25-6.24,3.37,3.37,0,0,1,.88.67c.3.36-.62-2-.75-2.64s1.15,1,1.47,1.47A22.82,22.82,0,0,0,170.51,227.72Z"
          />
          <path
            class="cls-22"
            d="M192.66,252.3l5.05,2.15-3.15-6.86a7.3,7.3,0,0,1,1,.84c.32.36-.85-1.62-1-2.08s1.22,1.24,1.22,1.24l-3.54-10s1,1.3,1.22,1.56a42.39,42.39,0,0,0-1.84-3.85c-.14-.26.33.29.62.55s-.72-1.8-.72-1.8l1.19,1.56c.39.53-3.92-10.81-3.92-10.81L190,226l-1.65-4.1.61.6c.23.23-1.13-2.06-1.21-2.29s.72.65,1.12,1.08-3.14-6.6-3.27-6.84c0,0,0,0,0,0l-1.93,4.63.65-.6-1.85,5.73,1-.91s-2,4.77-1.86,4.64l.56-.69a18.65,18.65,0,0,0-.66,1.77c.11-.15.48-.55.48-.55s-3.52,8.17-3.29,7.82,1.46-1.57,1.46-1.57l-1.46,2.69.58-.32-2.81,7.49a17.46,17.46,0,0,1,1.71-2.35c-.08.26-.79,1.74-.6,1.58a1,1,0,0,1,.38-.19s-1.4,3.12-2.77,5.65c0,0,1.28-.85,1.28-.85l-.71,1.81s-1.48,3-1,2.67a12.9,12.9,0,0,1,2.75-.68l6.67,0Z"
          />
          <path
            class="cls-22"
            d="M136.19,258.25l6.87,2.51-4.3-8a9.58,9.58,0,0,1,1.41,1c.43.41-1.16-1.9-1.41-2.43s1.66,1.44,1.66,1.44l-4.82-11.6s1.33,1.52,1.67,1.82a46.08,46.08,0,0,0-2.5-4.49c-.2-.3.44.33.83.64s-1-2.1-1-2.1l1.62,1.82c.54.61-5.33-12.59-5.33-12.59l1.65,1.39-2.24-4.78.82.7c.32.27-1.53-2.4-1.65-2.67s1,.76,1.54,1.27-4.28-7.7-4.45-8c0,0,0,0,0,.05l-2.61,5.39.87-.7-2.51,6.68,1.35-1.06s-2.7,5.56-2.54,5.41l.77-.81a21.34,21.34,0,0,0-.9,2.06c.15-.16.65-.64.65-.64s-4.78,9.53-4.47,9.12a26,26,0,0,1,2-1.83l-2,3.14.79-.39-3.83,8.73a22.48,22.48,0,0,1,2.33-2.73c-.11.3-1.07,2-.81,1.85a1.31,1.31,0,0,1,.51-.23s-1.9,3.63-3.76,6.58c0,0,1.73-1,1.73-1l-1,2.12s-2,3.51-1.29,3.11a19.61,19.61,0,0,1,3.73-.8l9.09,0Z"
          />
          <path
            class="cls-23"
            d="M424.18,126.53a41.17,41.17,0,0,1-5.28-12.38c-1.86,7.92-5.22,12.78-8.77,17.78l2.13-1-3.86,5.56,3.06-2.45-2.69,4.67,2.43-1.61-8,16,1.79-.89-1.28,2.49,1.35-.87s-5.74,8.78-5.21,8.19,4.57-4.19,4.57-4.19l-8.52,18.26,1.62-1-1.77,2.59s-4,6.26-3.1,5.58c.5-.4,2.37-2,3.84-3.25l-1.34,3.76,8.07-.45,8.6-1.11,3.13.12,13.81.22s1.54.34,2.85.68,1.89-3.1,1.89-3.1a38.11,38.11,0,0,1,5,2.41c1.57,1.05-.45-3.41-.45-3.41a20.6,20.6,0,0,1,3.35,2.58c1.47,1.45-.94-2.91-.94-2.91s2-.28,5.33,2.07c1.58,1.12.77,0-1.52-3.41l4,2.87s-3.67-6.34-3.35-6.3-4.47-6-5.08-7,2.58,1.45,2.8,1.72-2.4-3.82-3-4.63-6.47-10.73-6.47-10.73.73.4,1.47.89-1.65-3.55-2-4.65,2.09,1.69,3.1,2.61-.49-.94-3.6-5.38a118.93,118.93,0,0,1-6.81-11.84,6.54,6.54,0,0,1,1.77,1.23c.6.67-1.37-3.86-1.67-5.08s2.3,1.93,3,2.74S426.75,130.7,424.18,126.53Z"
          />
          <path
            class="cls-23"
            d="M331.42,130.37A40.73,40.73,0,0,1,326.14,118c-1.87,7.92-5.22,12.79-8.77,17.78l2.13-1-3.86,5.56,3.06-2.44L316,142.6l2.43-1.6-8,16,1.79-.89-1.28,2.5,1.35-.87s-5.74,8.77-5.21,8.18,4.57-4.18,4.57-4.18L303.11,180l1.62-1-1.78,2.6s-3.95,6.26-3.09,5.57c.5-.4,2.37-2,3.84-3.25-.85,2.38-1.34,3.77-1.34,3.77l8.07-.46,8.59-1.1,3.13.12,13.82.22s1.54.33,2.85.67,1.89-3.1,1.89-3.1a36.78,36.78,0,0,1,5,2.42c1.57,1.05-.45-3.42-.45-3.42a20.45,20.45,0,0,1,3.36,2.58c1.47,1.45-.95-2.9-.95-2.9s2-.28,5.34,2.06c1.58,1.12.77,0-1.52-3.41l4,2.87s-3.68-6.34-3.35-6.29-4.47-6-5.08-7,2.57,1.44,2.8,1.71-2.4-3.81-3-4.62-6.46-10.74-6.46-10.74.73.4,1.46.9-1.64-3.56-2-4.65,2.08,1.69,3.1,2.6-.5-.93-3.61-5.38a116.82,116.82,0,0,1-6.8-11.84,6.66,6.66,0,0,1,1.76,1.23c.6.67-1.36-3.85-1.67-5.07s2.31,1.93,3,2.74S334,134.54,331.42,130.37Z"
          />
          <path
            class="cls-23"
            d="M303.36,133.54a40.73,40.73,0,0,1-5.28-12.38c-1.87,7.92-5.23,12.79-8.77,17.78l2.12-.95-3.85,5.56,3.06-2.44-2.7,4.66,2.43-1.6-8,16,1.8-.89-1.29,2.5,1.35-.87s-5.73,8.77-5.2,8.18,4.57-4.18,4.57-4.18L275,183.18l1.63-1-1.78,2.59s-4,6.27-3.1,5.58c.5-.4,2.37-2,3.84-3.25l-1.33,3.77,8.07-.46,8.59-1.1,3.13.12,13.81.21s1.55.34,2.86.68,1.88-3.1,1.88-3.1a36.7,36.7,0,0,1,5,2.42c1.57,1-.45-3.42-.45-3.42a20.92,20.92,0,0,1,3.36,2.58c1.47,1.45-.95-2.9-.95-2.9s2-.29,5.34,2.06c1.57,1.12.77.05-1.52-3.41l4,2.87s-3.68-6.34-3.35-6.3-4.48-6-5.09-7,2.58,1.44,2.81,1.71-2.41-3.81-3-4.62-6.47-10.74-6.47-10.74.73.4,1.47.9-1.64-3.56-2-4.65,2.08,1.69,3.09,2.6-.49-.93-3.6-5.38a118,118,0,0,1-6.8-11.84,6.83,6.83,0,0,1,1.76,1.23c.6.67-1.36-3.85-1.67-5.07s2.3,1.93,3,2.74S305.92,137.71,303.36,133.54Z"
          />
          <path
            class="cls-24"
            d="M324.38,182.06l9.07,3.44-6.07-11.89a12.48,12.48,0,0,1,1.89,1.42c.58.6-1.63-2.82-2-3.61s2.24,2.1,2.24,2.1l-7-17.33s1.81,2.23,2.28,2.67-3.26-6.22-3.53-6.67.59.48,1.12.92S321,150,321,150l2.22,2.67c.74.89-7.71-18.81-7.71-18.81l2.22,2-3.2-7.13,1.11,1c.43.38-2.14-3.56-2.31-4s1.33,1.09,2.07,1.83-6-11.43-6.27-11.85c0,0,0,0,0,.08l-3.06,8.31,1.09-1.11-2.83,10.26,1.67-1.69s-3.14,8.58-3,8.34,1-1.27,1-1.27-1.24,3.45-1.05,3.18.81-1,.81-1-5.61,14.7-5.23,14.06a36.67,36.67,0,0,1,2.47-2.88l-2.39,4.87,1-.62-4.43,13.43a32.56,32.56,0,0,1,2.85-4.26c-.12.45-1.26,3.12-.93,2.83a1.66,1.66,0,0,1,.65-.37s-2.24,5.61-4.47,10.19c0,0,2.18-1.6,2.18-1.6l-1.11,3.26s-2.39,5.43-1.48,4.78,4.8-1.4,4.8-1.4l11.79-.53Z"
          />
          <path
            class="cls-23"
            d="M356.61,123s0,0,0,0l-4,7.84,1.2-1.08-1.79,3.5,1.38-1.27-3.57,10.08,1.29-1.22-2.71,5.35.88-.92-.54,1.81.68-.76-2.7,4.82,1.06-1.12-.64,1.68-.62,2.11,1.09-1.58-3.22,7.13,1.16-1.69-4.75,10.59,1.7-1.28-1.1,2.47,1.35-1.26-5.66,11,1.49-1.43-1.05,3.38,1.92-1.87-2.8,7.42.7,0a9,9,0,0,0-.56,2.66l.14,0,0,0,.14-.06c3.74-.71,46.28-3.32,46.28-3.32l1.29-.08-6.6-12.5,2.47,1.63-2.19-4.61,1,.38-3.59-4.16,1.85.88-2-3,2.13,1.18-6.21-10.32,1.08.46-1.43-2.06,1.49,1-1.86-2,.33,0-.57-1.3,1.52,1.7-7.53-11.7,1.2.56-1.14-1.72,1.29.95-2-3,.87.77-3-5.27.94,1-2.44-5.35.67.22-4.19-6.51s0,0,0,0Z"
          />
          <path
            class="cls-24"
            d="M358.65,185l9.07,3.44-6.07-11.9a12.48,12.48,0,0,1,1.89,1.42c.59.61-1.63-2.81-2-3.6s2.24,2.1,2.24,2.1l-7-17.33s1.81,2.22,2.28,2.67-3.26-6.23-3.53-6.67.59.48,1.12.92-1.4-3.12-1.4-3.12l2.21,2.67c.74.89-7.71-18.81-7.71-18.81l2.23,2-3.21-7.13,1.11,1c.43.39-2.13-3.56-2.3-4s1.32,1.09,2.06,1.83-6-11.43-6.27-11.84c0,0,0,0,0,.07l-3.06,8.31,1.09-1.1-2.83,10.26,1.67-1.69s-3.14,8.58-2.95,8.33.95-1.26.95-1.26-1.24,3.44-1,3.18.81-1,.81-1-5.61,14.69-5.24,14.06a36.67,36.67,0,0,1,2.47-2.88l-2.39,4.86,1-.62-4.43,13.44a33.49,33.49,0,0,1,2.85-4.27c-.12.46-1.25,3.13-.93,2.84a1.66,1.66,0,0,1,.65-.37s-2.24,5.61-4.47,10.18c0,0,2.18-1.59,2.18-1.59l-1.11,3.25s-2.39,5.43-1.48,4.79,4.8-1.41,4.8-1.41l11.79-.53Z"
          />
          <path
            class="cls-24"
            d="M374.11,123.75a0,0,0,0,0,0,0l-4,7.84,1.19-1.09L369.47,134l1.39-1.27-3.58,10.07,1.29-1.21-2.7,5.35.87-.92-.53,1.81.67-.76-2.7,4.82,1.07-1.13-.64,1.69L364,154.6l1.09-1.58-3.22,7.13,1.17-1.69-4.76,10.59,1.7-1.28-1.1,2.46,1.35-1.25-5.65,11,1.49-1.43L355,181.94l1.91-1.87-2.8,7.42.7,0c-.47,1.76-.68,2.73-.55,2.66l.13,0,0,0,.14-.07c3.74-.7,46.28-3.31,46.28-3.31l1.29-.08-6.59-12.51,2.46,1.64-2.18-4.61,1,.38-3.59-4.16,1.85.87-2-3,2.13,1.18-6.21-10.32,1.08.46-1.42-2.06,1.49,1-1.87-2,.33,0-.57-1.3,1.52,1.7L382,142.15l1.2.55L382,141l1.29.95-2-3,.86.78-3-5.27.94,1-2.45-5.36.67.23-4.18-6.51,0,0A0,0,0,0,0,374.11,123.75Z"
          />
          <path
            class="cls-24"
            d="M400.28,122.67s0,0,0,0l-4,7.85,1.19-1.09L395.64,133l1.38-1.27-3.57,10.07,1.29-1.21L392,145.89l.87-.92-.53,1.82.68-.76-2.71,4.82,1.07-1.13-.64,1.68-.62,2.12,1.08-1.59L388,159.07l1.16-1.69L384.42,168l1.71-1.28-1.1,2.47,1.34-1.25-5.65,11,1.49-1.43-1,3.39,1.91-1.88-2.79,7.42.69,0c-.47,1.76-.68,2.73-.55,2.66a.42.42,0,0,1,.14,0l0,0,.14-.07c3.74-.71,46.28-3.32,46.28-3.32l1.29-.07-6.6-12.51,2.46,1.63-2.18-4.61,1,.38-3.59-4.16,1.85.88-2-3,2.14,1.18L415.08,155l1.08.47-1.42-2.06,1.49,1-1.87-2h.34l-.58-1.31,1.52,1.7-7.52-11.7,1.19.56-1.13-1.71,1.29,1-2-3,.87.77-3-5.27.94,1L403.83,129l.67.22-4.19-6.51,0,0Z"
          />
          <path
            class="cls-25"
            d="M307.22,151.83a27.47,27.47,0,0,1-3.59-8.43c-1.27,5.39-3.56,8.7-6,12.1l1.45-.65-2.63,3.79,2.08-1.67-1.83,3.18,1.66-1.09L292.92,170l1.22-.61-.87,1.7.92-.59s-3.91,6-3.54,5.57,3.1-2.85,3.1-2.85L288,185.6l1.1-.66-1.21,1.77s-2.69,4.26-2.11,3.79c.35-.27,1.62-1.35,2.62-2.21-.58,1.62-.91,2.56-.91,2.56l5.49-.31,5.85-.75,2.12.08,9.4.15s1.05.23,1.94.46,1.29-2.11,1.29-2.11A24.63,24.63,0,0,1,317,190c1.07.71-.3-2.33-.3-2.33a13.94,13.94,0,0,1,2.28,1.76c1,1-.64-2-.64-2s1.37-.19,3.63,1.41c1.07.76.52,0-1-2.32l2.72,1.95a43.68,43.68,0,0,1-2.28-4.29c.22,0-3-4.08-3.46-4.75s1.76,1,1.91,1.16-1.64-2.59-2-3.14-4.4-7.31-4.4-7.31.5.27,1,.61-1.12-2.42-1.37-3.16,1.42,1.15,2.11,1.77-.34-.64-2.45-3.66a79.88,79.88,0,0,1-4.63-8.06,4.6,4.6,0,0,1,1.2.84c.41.46-.93-2.62-1.14-3.45s1.57,1.31,2,1.86S309,154.66,307.22,151.83Z"
          />
          <path
            class="cls-25"
            d="M367.57,144.46a30.46,30.46,0,0,1-3.9-9.14c-1.37,5.85-3.85,9.44-6.47,13.13l1.57-.7-2.85,4.1,2.26-1.8-2,3.44,1.8-1.18-5.93,11.83,1.32-.66-.95,1.84,1-.64s-4.24,6.48-3.84,6,3.37-3.09,3.37-3.09l-6.29,13.47,1.2-.71-1.32,1.92s-2.92,4.62-2.28,4.12c.37-.3,1.75-1.47,2.83-2.4l-1,2.78,5.95-.34,6.35-.81,2.31.08,10.2.17s1.14.24,2.11.5,1.39-2.29,1.39-2.29a27.52,27.52,0,0,1,3.71,1.78c1.16.78-.33-2.52-.33-2.52a15.18,15.18,0,0,1,2.48,1.9c1.08,1.07-.7-2.14-.7-2.14s1.49-.21,3.94,1.52c1.16.83.57,0-1.12-2.52l2.94,2.12s-2.71-4.68-2.47-4.65-3.3-4.43-3.75-5.16,1.9,1.06,2.06,1.27-1.77-2.82-2.21-3.42-4.78-7.93-4.78-7.93.54.3,1.08.66-1.21-2.62-1.48-3.43,1.54,1.25,2.28,1.92-.36-.69-2.66-4a87.79,87.79,0,0,1-5-8.74,4.87,4.87,0,0,1,1.3.91c.44.49-1-2.85-1.23-3.75s1.7,1.42,2.19,2S369.47,147.54,367.57,144.46Z"
          />
          <path
            class="cls-25"
            d="M392,146.88a27.47,27.47,0,0,1-3.59-8.43c-1.27,5.39-3.56,8.7-6,12.1l1.45-.65-2.63,3.79,2.09-1.67-1.84,3.18,1.66-1.09L377.68,165l1.22-.61-.87,1.7.92-.59s-3.9,6-3.54,5.57,3.11-2.85,3.11-2.85l-5.8,12.42,1.1-.66-1.21,1.77s-2.69,4.26-2.1,3.79c.34-.27,1.61-1.35,2.61-2.21-.58,1.62-.91,2.56-.91,2.56l5.49-.31,5.85-.75,2.13.08,9.39.15s1.05.23,1.94.46,1.29-2.11,1.29-2.11a24.56,24.56,0,0,1,3.42,1.65c1.07.71-.31-2.33-.31-2.33a13.94,13.94,0,0,1,2.28,1.76c1,1-.64-2-.64-2s1.38-.19,3.63,1.41c1.07.76.53,0-1-2.32l2.71,1.95a44.71,44.71,0,0,1-2.28-4.28c.22,0-3-4.09-3.46-4.76s1.76,1,1.91,1.16-1.63-2.59-2-3.14-4.4-7.31-4.4-7.31.49.27,1,.61-1.11-2.42-1.37-3.16,1.42,1.15,2.11,1.77-.33-.64-2.45-3.66a82.05,82.05,0,0,1-4.63-8.06,4.6,4.6,0,0,1,1.2.84c.41.46-.93-2.62-1.13-3.45s1.56,1.31,2,1.86S393.72,149.71,392,146.88Z"
          />
          <path
            class="cls-25"
            d="M422.57,178.11l6.78,2.57-4.54-8.89a10,10,0,0,1,1.42,1.06c.43.46-1.22-2.1-1.49-2.69s1.68,1.57,1.68,1.57l-5.22-12.95s1.36,1.66,1.71,2-2.44-4.65-2.64-5,.44.36.84.68-1.05-2.33-1.05-2.33l1.65,2c.56.67-5.76-14.06-5.76-14.06l1.66,1.52-2.39-5.33.83.77c.32.29-1.6-2.66-1.72-3s1,.81,1.54,1.37-4.51-8.55-4.69-8.86c0,0,0,0,0,.06l-2.29,6.21.82-.83-2.12,7.67,1.25-1.26s-2.35,6.41-2.2,6.23l.71-.95s-.93,2.58-.78,2.38.6-.76.6-.76-4.19,11-3.91,10.51a26.32,26.32,0,0,1,1.84-2.15l-1.78,3.63.75-.46-3.31,10a25.93,25.93,0,0,1,2.13-3.19c-.09.34-.94,2.34-.69,2.12a1.28,1.28,0,0,1,.48-.27s-1.67,4.19-3.34,7.61c0,0,1.63-1.2,1.63-1.2l-.83,2.44s-1.79,4.06-1.11,3.57a16.76,16.76,0,0,1,3.59-1l8.81-.4Z"
          />
          <path
            class="cls-25"
            d="M348.34,189.05l9.21,2.95-6.11-10.33a12.67,12.67,0,0,1,1.92,1.23c.59.52-1.64-2.44-2-3.13s2.27,1.81,2.27,1.81l-7-15.05s1.83,1.93,2.3,2.31-3.27-5.4-3.55-5.79.6.42,1.14.8-1.41-2.71-1.41-2.71l2.23,2.31c.75.77-7.73-16.34-7.73-16.34l2.26,1.76-3.22-6.19,1.13.88c.43.34-2.15-3.09-2.32-3.43s1.33.94,2.09,1.58-6.07-9.92-6.31-10.28c0,0,0,0,0,.06l-3.16,7.26,1.12-1-2.95,8.95,1.72-1.48s-3.25,7.49-3.05,7.28,1-1.1,1-1.1a28.53,28.53,0,0,0-1.08,2.77c.2-.23.83-.88.83-.88s-5.8,12.83-5.41,12.27,2.52-2.52,2.52-2.52l-2.45,4.25,1-.54-4.59,11.73a29,29,0,0,1,2.93-3.73c-.13.39-1.3,2.73-1,2.47a2,2,0,0,1,.67-.33s-2.32,4.9-4.61,8.9c0,0,2.23-1.4,2.23-1.4l-1.15,2.84s-2.47,4.75-1.54,4.18a25.81,25.81,0,0,1,4.89-1.25l12-.54Z"
          />
          <path
            class="cls-26"
            d="M221.41,260c-9-9.56-41.3-74.28-48.84-74.59s-13.64,8.52-17.95,5-10.12-12.75-14-17.66-12.69-15.18-15.92-18.53c-3.44-3.56-10.38,3.15-15.32,7.54,0,0-8.87-12-19.69-20.61S70.1,109.51,67.94,115s-23.55,128.64-23.19,133,5,24.42,18.67,18.67S230.38,269.53,221.41,260Z"
          />
          <g class="cls-27">
            <polygon
              class="cls-28"
              points="104.43 126.62 124.7 154.19 127.29 176.41 131.89 196.62 126.01 204.1 139.55 223.7 151.84 248.3 112.48 202.21 118.47 190.86 109.18 181.35 112.9 172.72 109.38 161.72 104.43 126.62"
            />
            <polygon
              class="cls-28"
              points="169.04 183.7 171.09 184.44 174.57 187.97 170.69 201.9 179.04 216.72 188.78 226.38 189.25 231.23 197.66 250.02 187.97 233.85 181.83 226.34 166.12 215.82 161.49 209.46 159.72 205.61 151.32 197.47 149.56 195.37 161.73 189.45 165.38 185.31 169.04 183.7"
            />
            <path
              class="cls-29"
              d="M159.57,188c-1.24-.94-23.76-23.94-25.71-26.11s-10-10-9-7.5,15.6,33.71,15.6,33.71l-3.85,2.74s2.71,3.27,3.54,4.2,17.68,29.38,17.68,29.38l15.23,15.42L175.18,252l16.23,4.29s-12-17.2-16.23-21.44-11.32-13.68-11.32-13.68l1.73-6.36-4.3-10.89-11.36-8.12,8.89-5S160.82,189,159.57,188Z"
            />
            <polygon
              class="cls-29"
              points="108.69 153.91 81.78 128.86 95.47 165.76 94.48 176.02 95.08 186.28 105.53 209.56 97.84 222.98 127.24 256.29 141.84 251 115.99 222.59 133.26 231.27 115.8 201.12 122.02 187.07 114.42 179.97 116.19 169.51 108.69 153.91"
            />
          </g>
          <polygon
            class="cls-30"
            points="373.04 169.69 275.41 182.48 234.84 192.37 234.75 214.31 239.32 246.49 255.21 265.13 366.06 255.06 373.04 169.69"
          />
          <g class="cls-31">
            <polygon
              class="cls-32"
              points="245.07 270.06 234.18 225.4 228.01 208.09 235.11 189.82 234.84 192.37 266.22 194.83 276.53 212.3 287.28 217.3 294.06 221.1 302.99 234 315.57 267.09 245.07 270.06"
            />
            <polygon
              class="cls-33"
              points="230.17 240.41 234.75 214.31 260.88 227.91 267.6 247.77 262.86 267.35 237.87 265.13 230.17 240.41"
            />
            <polygon
              class="cls-34"
              points="276.49 226.45 266.22 194.83 285.14 187.1 311.76 184.96 325.92 183.94 367.16 171.5 365.48 217.3 345.74 233.93 317.02 253.52 276.49 226.45"
            />
            <path
              class="cls-35"
              d="M329.64,210.31C329,208.58,311.76,185,311.76,185s43.13-19,44.83-19.11,21.46,1.7,23.16,1.86-9.52,36.34-9.52,36.34S330.26,212,329.64,210.31Z"
            />
          </g>
          <path
            class="cls-36"
            d="M321.57,240.45c4.11-9.16,25.21-75.25,36.14-75.61,11.52-.38,21.09,5.37,24.39,0s11-19.29,15.76-23,18.38-46.08,32.67-40.65,42.07,33,42.07,33l94.94,73.91S317.47,249.61,321.57,240.45Z"
          />
          <g class="cls-37">
            <polygon
              class="cls-38"
              points="398.39 109.98 427.51 100 415.58 146.52 422.52 170.44 419.47 195.3 431.67 219.24 433.88 243.09 409.76 200.3 400.84 173.76 380.37 166.28 356.24 163.89 398.39 109.98"
            />
            <path
              class="cls-38"
              d="M303.75,257.92s31-68.29,36.16-75.92,16.33-18.11,16.33-18.11S363,180.05,366,181.82s14.55,6,16.68,7.1-1.59,9.59-3.55,13-7.12,12.42-8.8,15.26,10.22,21.47,11.64,23.25-2.84,7.74-2.84,7.74Z"
            />
          </g>
          <polygon
            class="cls-30"
            points="490.8 195.23 393.17 208.03 352.6 217.92 352.51 239.86 357.08 272.04 372.96 290.68 483.81 280.61 490.8 195.23"
          />
          <g class="cls-39">
            <polygon
              class="cls-32"
              points="362.82 295.61 351.94 250.95 345.77 233.64 352.87 215.37 352.6 217.92 383.97 220.38 394.28 237.84 405.04 242.85 411.82 246.65 420.75 259.55 433.32 292.63 362.82 295.61"
            />
            <polygon
              class="cls-33"
              points="347.93 265.95 352.51 239.86 378.64 253.45 385.35 273.32 380.61 292.9 355.63 290.68 347.93 265.95"
            />
            <polygon
              class="cls-34"
              points="394.24 252 383.97 220.38 402.9 212.64 429.52 210.51 443.67 209.49 484.92 197.05 483.24 242.85 463.5 259.48 434.78 279.07 394.24 252"
            />
            <path
              class="cls-35"
              d="M447.4,235.86c-.62-1.73-17.88-25.35-17.88-25.35s43.13-18.95,44.83-19.11,21.45,1.7,23.15,1.85S488,229.6,488,229.6,448,237.59,447.4,235.86Z"
            />
          </g>
          <path
            class="cls-36"
            d="M439.33,266c4.1-9.16,25.21-75.24,36.13-75.6,11.52-.38,21.1,5.36,24.4,0s11-19.29,15.75-23,18.39-46.07,32.68-40.64,42.07,33,42.07,33l94.94,73.91S435.23,275.15,439.33,266Z"
          />
          <g class="cls-40">
            <polygon
              class="cls-38"
              points="516.15 135.53 545.26 125.55 533.34 172.06 540.27 195.98 537.22 220.84 549.42 244.79 551.64 268.64 527.52 225.85 518.6 199.31 498.12 191.82 474 189.44 516.15 135.53"
            />
            <path
              class="cls-38"
              d="M421.51,283.46s31-68.28,36.16-75.91S474,189.44,474,189.44s6.76,16.16,9.78,17.93,14.55,6,16.68,7.1-1.6,9.58-3.55,12.95-7.13,12.43-8.8,15.27,10.22,21.47,11.64,23.25-2.84,7.74-2.84,7.74Z"
            />
          </g>
        </g>
        <g id="Plataforma">
          <polygon
            class="cls-41"
            points="318.5 178.78 308.54 178.74 260.02 178.74 250.06 178.78 254.11 175.05 314.44 175.05 318.5 178.78"
          />
          <polygon
            class="cls-42"
            points="341.56 196.37 324.79 196.31 243.06 196.31 226.29 196.37 243.06 179.08 243.35 178.78 324.5 178.78 324.79 179.08 341.56 196.37"
          />
        </g>
        <g id="Lastro">
          <polygon
            class="cls-43"
            points="318.5 178.78 308.54 178.75 260.02 178.75 250.06 178.78 260.02 169.62 260.19 169.46 308.37 169.46 308.54 169.62 318.5 178.78"
          />
        </g>
        <g id="Dormente">
          <rect
            class="cls-44"
            x="266.6"
            y="169.35"
            width="35.98"
            height="3.36"
          />
        </g>
        <g id="Trilho">
          <polygon
            class="cls-45"
            points="299.56 167.96 299.56 169.13 295.69 169.13 295.69 167.96 297.1 167.96 297.1 164.64 296.58 164.64 296.58 162.55 298.67 162.55 298.67 164.64 298.14 164.64 298.14 167.96 299.56 167.96"
          />
          <polygon
            class="cls-45"
            points="273.64 167.96 273.64 169.13 269.76 169.13 269.76 167.96 271.18 167.96 271.18 164.64 270.66 164.64 270.66 162.55 272.74 162.55 272.74 164.64 272.22 164.64 272.22 167.96 273.64 167.96"
          />
        </g>
        <g id="Trem">
          <rect
            class="cls-46"
            x="261.21"
            y="141.91"
            width="47.73"
            height="9.74"
          />
          <rect
            class="cls-47"
            x="261.21"
            y="150.97"
            width="47.73"
            height="0.68"
          />
          <rect
            class="cls-48"
            x="260.65"
            y="151.65"
            width="48.85"
            height="1.43"
          />
          <rect
            class="cls-49"
            x="260.65"
            y="152.56"
            width="48.85"
            height="0.52"
          />
          <polygon
            class="cls-50"
            points="309.82 162.19 285.07 167.55 260.33 162.19 261.99 153.08 308.16 153.08 309.82 162.19"
          />
          <path
            class="cls-47"
            d="M308.16,153.08H262l-1.66,9.11,24.74,5.36,24.75-5.36ZM285.07,167l-23.94-5.19,1.6-8.52h22.34Z"
          />
          <polygon
            class="cls-48"
            points="310.98 163.98 285.07 169.41 259.16 163.78 259.56 162.02 285.07 167.55 310.59 162.02 310.98 163.98"
          />
          <polygon
            class="cls-51"
            points="275.8 154.88 274.92 163.33 277.79 163.84 278.45 154.88 275.8 154.88"
          />
          <polygon
            class="cls-51"
            points="265.19 154.88 263.76 161.34 266.5 161.83 267.84 154.88 265.19 154.88"
          />
          <polygon
            class="cls-51"
            points="270.49 154.88 269.28 162.32 272.08 162.82 273.14 154.88 270.49 154.88"
          />
          <polygon
            class="cls-51"
            points="281.1 154.88 280.68 164.35 283.61 164.88 283.75 154.88 281.1 154.88"
          />
          <polygon
            class="cls-51"
            points="289.05 154.88 286.4 154.88 286.55 164.88 289.48 164.35 289.05 154.88"
          />
          <polygon
            class="cls-51"
            points="306.38 161.34 304.95 154.88 302.3 154.88 303.64 161.83 306.38 161.34"
          />
          <polygon
            class="cls-51"
            points="297 154.88 298.06 162.82 300.87 162.32 299.65 154.88 297 154.88"
          />
          <polygon
            class="cls-51"
            points="291.7 154.88 292.37 163.84 295.23 163.33 294.35 154.88 291.7 154.88"
          />
          <rect
            class="cls-52"
            x="273.48"
            y="141.91"
            width="23.2"
            height="6.85"
          />
          <rect
            class="cls-50"
            x="273.48"
            y="148.76"
            width="23.2"
            height="2.89"
          />
          <rect
            class="cls-47"
            x="273.48"
            y="148.76"
            width="23.2"
            height="0.65"
          />
          <rect
            class="cls-48"
            x="281.47"
            y="144.23"
            width="7.2"
            height="7.42"
          />
          <rect
            class="cls-51"
            x="286.83"
            y="145.15"
            width="0.91"
            height="5.58"
          />
          <rect
            class="cls-51"
            x="282.41"
            y="145.15"
            width="0.91"
            height="5.58"
          />
          <rect
            class="cls-53"
            x="288.67"
            y="145.15"
            width="3.68"
            height="5.58"
          />
          <rect
            class="cls-53"
            x="277.79"
            y="145.15"
            width="3.68"
            height="5.58"
          />
          <path
            class="cls-51"
            d="M262.09,161.44a.4.4,0,0,0-.17.78.41.41,0,0,0,.48-.31A.4.4,0,0,0,262.09,161.44Z"
          />
          <path
            class="cls-51"
            d="M280.67,165.46a.4.4,0,0,0-.47.3.39.39,0,0,0,.3.47.39.39,0,1,0,.17-.77Z"
          />
          <path
            class="cls-51"
            d="M265.19,162.11a.39.39,0,0,0-.47.3.4.4,0,0,0,.3.48.4.4,0,0,0,.17-.78Z"
          />
          <path
            class="cls-51"
            d="M268.29,162.78a.4.4,0,0,0-.48.3.41.41,0,0,0,.31.48.4.4,0,0,0,.47-.31A.4.4,0,0,0,268.29,162.78Z"
          />
          <path
            class="cls-51"
            d="M271.38,163.45a.4.4,0,0,0-.47.3.4.4,0,0,0,.3.48.41.41,0,0,0,.48-.31A.4.4,0,0,0,271.38,163.45Z"
          />
          <path
            class="cls-51"
            d="M274.48,164.12a.39.39,0,0,0-.47.3.39.39,0,1,0,.77.17A.4.4,0,0,0,274.48,164.12Z"
          />
          <path
            class="cls-51"
            d="M277.58,164.79a.4.4,0,0,0-.48.3.4.4,0,1,0,.48-.3Z"
          />
          <path
            class="cls-51"
            d="M308.61,161.75a.39.39,0,0,0-.47-.31.4.4,0,1,0,.47.31Z"
          />
          <path
            class="cls-51"
            d="M289.56,165.46a.39.39,0,0,0-.3.47.39.39,0,1,0,.3-.47Z"
          />
          <path
            class="cls-51"
            d="M305,162.11a.4.4,0,0,0-.3.47.4.4,0,0,0,.47.31.41.41,0,0,0,.31-.48A.4.4,0,0,0,305,162.11Z"
          />
          <path
            class="cls-51"
            d="M302,162.78a.4.4,0,1,0,.47.3A.4.4,0,0,0,302,162.78Z"
          />
          <path
            class="cls-51"
            d="M298.85,163.45a.4.4,0,0,0,.17.78.4.4,0,0,0,.3-.48A.39.39,0,0,0,298.85,163.45Z"
          />
          <path
            class="cls-51"
            d="M295.75,164.12a.4.4,0,0,0-.3.47.4.4,0,0,0,.47.31.41.41,0,0,0,.31-.48A.4.4,0,0,0,295.75,164.12Z"
          />
          <path
            class="cls-51"
            d="M292.66,164.79a.39.39,0,1,0,.17.77.39.39,0,0,0,.3-.47A.4.4,0,0,0,292.66,164.79Z"
          />
          <path
            class="cls-54"
            d="M303.67,99,291,95.87h-11.8s-9.36,2.32-12.7,3.09c-2.66.61-4.42,1-5,3.27h47.28C308.16,100,306.49,99.64,303.67,99Z"
          />
          <path
            class="cls-48"
            d="M308.72,102.23H261.44a8.19,8.19,0,0,0-.23,2.08v20.34l23.86,6.83,23.87-6.83V104.31A8.6,8.6,0,0,0,308.72,102.23Z"
          />
          <path
            class="cls-49"
            d="M308.72,102.23h-.83a8.12,8.12,0,0,1,.21,2v19.64l-23,6.58-23-6.58V104.23a7.62,7.62,0,0,1,.22-2h-.83a8.19,8.19,0,0,0-.23,2.08v20.34l23.86,6.83,23.87-6.83V104.31A8.6,8.6,0,0,0,308.72,102.23Z"
          />
          <path
            class="cls-55"
            d="M261.21,126.47v13.74h47.73V126.47l-23.87,6.83Z"
          />
          <path
            class="cls-56"
            d="M285.07,133.3l-23.86-6.83v13.74h47.73V126.47Zm23.09,6.07H262V127.64l23.08,6.53,23.09-6.53Z"
          />
          <path
            class="cls-57"
            d="M285.07,131.48l-23.86-6.83v1.82l23.86,6.83,23.87-6.83v-1.82Z"
          />
          <path
            class="cls-58"
            d="M261.21,125.71v.76l23.86,6.83,23.87-6.83v-.76l-23.87,6.83Z"
          />
          <polygon
            class="cls-59"
            points="262.77 102.78 285.07 102.78 285.07 129.52 262.77 123.46 262.77 102.78"
          />
          <polygon
            class="cls-60"
            points="262.77 128.1 285.07 134.94 285.07 138.61 262.77 138.61 262.77 128.1"
          />
          <rect
            class="cls-49"
            x="286.15"
            y="103.69"
            width="20.71"
            height="18.79"
            rx="2.47"
          />
          <rect
            class="cls-51"
            x="286.73"
            y="104.22"
            width="19.54"
            height="17.73"
            rx="2.33"
          />
          <rect
            class="cls-61"
            x="287.74"
            y="105.13"
            width="17.53"
            height="15.91"
            rx="2.09"
          />
          <rect
            class="cls-49"
            x="263"
            y="103.69"
            width="20.71"
            height="18.79"
            rx="2.47"
          />
          <rect
            class="cls-51"
            x="263.58"
            y="104.22"
            width="19.54"
            height="17.73"
            rx="2.33"
          />
          <rect
            class="cls-61"
            x="264.58"
            y="105.13"
            width="17.53"
            height="15.91"
            rx="2.09"
          />
          <path
            class="cls-62"
            d="M265.23,119.5V107.81a2.08,2.08,0,0,1,2.08-2.09h13.31C281.77,105.72,265.23,120.65,265.23,119.5Z"
          />
          <path
            class="cls-62"
            d="M288.32,119.5V107.81a2.08,2.08,0,0,1,2.08-2.09h13.31C304.86,105.72,288.32,120.65,288.32,119.5Z"
          />
          <path
            class="cls-63"
            d="M274.18,137.31a1.46,1.46,0,0,1-1.45,1.47h-9.2a1.45,1.45,0,0,1-1.44-1.47v-5a1.45,1.45,0,0,1,1.44-1.47h9.2a1.46,1.46,0,0,1,1.45,1.47Z"
          />
          <rect
            class="cls-51"
            x="262.27"
            y="131.06"
            width="11.73"
            height="7.54"
            rx="1.4"
          />
          <rect
            class="cls-64"
            x="262.77"
            y="131.62"
            width="10.72"
            height="6.43"
            rx="1.28"
          />
          <path
            class="cls-47"
            d="M272.21,131.62h-.52a1.12,1.12,0,0,1,1.12,1.11v3.39a1.12,1.12,0,0,1-1.12,1.11h-7.12a1.12,1.12,0,0,1-1.12-1.11v-3.39a1.12,1.12,0,0,1,1.12-1.11h-.52a1.28,1.28,0,0,0-1.28,1.28v3.87a1.28,1.28,0,0,0,1.28,1.28h8.16a1.28,1.28,0,0,0,1.28-1.28V132.9A1.28,1.28,0,0,0,272.21,131.62Z"
          />
          <path
            class="cls-47"
            d="M267.49,135a1.71,1.71,0,1,1-1.7-1.7A1.7,1.7,0,0,1,267.49,135Z"
          />
          <path
            class="cls-47"
            d="M272.4,135a1.71,1.71,0,1,1-1.7-1.7A1.7,1.7,0,0,1,272.4,135Z"
          />
          <path
            class="cls-65"
            d="M267.32,134.83a1.71,1.71,0,1,1-1.7-1.7A1.71,1.71,0,0,1,267.32,134.83Z"
          />
          <path
            class="cls-65"
            d="M272.23,134.83a1.7,1.7,0,1,1-1.7-1.7A1.71,1.71,0,0,1,272.23,134.83Z"
          />
          <path
            class="cls-66"
            d="M264,134.3l2.15,2.15a1.73,1.73,0,0,0,.64-.38l-2.41-2.41A1.73,1.73,0,0,0,264,134.3Z"
          />
          <path
            class="cls-67"
            d="M263.91,134.83a1.47,1.47,0,0,0,.06.42l1.23,1.23a1.47,1.47,0,0,0,.42.06,1.51,1.51,0,0,0,.53-.09L264,134.3A1.9,1.9,0,0,0,263.91,134.83Z"
          />
          <path
            class="cls-66"
            d="M265.2,136.48,264,135.25A1.7,1.7,0,0,0,265.2,136.48Z"
          />
          <path
            class="cls-67"
            d="M267.16,135.56l-2.27-2.26a1.68,1.68,0,0,0-.51.36l2.41,2.41A1.91,1.91,0,0,0,267.16,135.56Z"
          />
          <path
            class="cls-67"
            d="M265.65,133.13l1.67,1.67A1.71,1.71,0,0,0,265.65,133.13Z"
          />
          <path
            class="cls-68"
            d="M267.32,134.83v0l-1.67-1.67h0a1.63,1.63,0,0,0-.73.17l2.27,2.26A1.77,1.77,0,0,0,267.32,134.83Z"
          />
          <path
            class="cls-66"
            d="M268.91,134.3l2.16,2.15a1.69,1.69,0,0,0,.63-.38l-2.41-2.41A1.73,1.73,0,0,0,268.91,134.3Z"
          />
          <path
            class="cls-67"
            d="M268.83,134.83a1.93,1.93,0,0,0,.05.42l1.24,1.23a1.4,1.4,0,0,0,.41.06,1.61,1.61,0,0,0,.54-.09l-2.16-2.15A1.89,1.89,0,0,0,268.83,134.83Z"
          />
          <path
            class="cls-66"
            d="M270.12,136.48l-1.24-1.23A1.7,1.7,0,0,0,270.12,136.48Z"
          />
          <path
            class="cls-67"
            d="M272.07,135.56l-2.27-2.26a1.82,1.82,0,0,0-.51.36l2.41,2.41A1.71,1.71,0,0,0,272.07,135.56Z"
          />
          <path
            class="cls-67"
            d="M270.56,133.13l1.67,1.67A1.7,1.7,0,0,0,270.56,133.13Z"
          />
          <path
            class="cls-68"
            d="M272.23,134.83v0l-1.67-1.67h0a1.63,1.63,0,0,0-.73.17l2.27,2.26A1.77,1.77,0,0,0,272.23,134.83Z"
          />
          <path
            class="cls-69"
            d="M270.53,133.13a1.71,1.71,0,1,0,1.7,1.7A1.7,1.7,0,0,0,270.53,133.13Zm-.17,2.9a1.37,1.37,0,1,1,1.37-1.37A1.37,1.37,0,0,1,270.36,136Z"
          />
          <path
            class="cls-69"
            d="M265.62,133.13a1.71,1.71,0,1,0,1.7,1.7A1.71,1.71,0,0,0,265.62,133.13Zm-.18,2.9a1.37,1.37,0,1,1,1.38-1.37A1.37,1.37,0,0,1,265.44,136Z"
          />
          <path
            class="cls-63"
            d="M308,137.31a1.46,1.46,0,0,1-1.44,1.47H297.3a1.46,1.46,0,0,1-1.44-1.47v-5a1.46,1.46,0,0,1,1.44-1.47h9.21a1.46,1.46,0,0,1,1.44,1.47Z"
          />
          <rect
            class="cls-51"
            x="296.04"
            y="131.06"
            width="11.73"
            height="7.54"
            rx="1.4"
          />
          <rect
            class="cls-64"
            x="296.55"
            y="131.62"
            width="10.72"
            height="6.43"
            rx="1.28"
          />
          <path
            class="cls-47"
            d="M301.26,135a1.71,1.71,0,1,1-1.7-1.7A1.7,1.7,0,0,1,301.26,135Z"
          />
          <path
            class="cls-47"
            d="M306.18,135a1.71,1.71,0,1,1-1.71-1.7A1.71,1.71,0,0,1,306.18,135Z"
          />
          <path
            class="cls-65"
            d="M301.1,134.83a1.71,1.71,0,1,1-3.41,0,1.71,1.71,0,0,1,3.41,0Z"
          />
          <path
            class="cls-65"
            d="M306,134.83a1.71,1.71,0,1,1-1.71-1.7A1.71,1.71,0,0,1,306,134.83Z"
          />
          <path
            class="cls-47"
            d="M306,131.62h-.51a1.11,1.11,0,0,1,1.11,1.11v3.39a1.11,1.11,0,0,1-1.11,1.11h-7.13a1.12,1.12,0,0,1-1.12-1.11v-3.39a1.12,1.12,0,0,1,1.12-1.11h-.51a1.28,1.28,0,0,0-1.28,1.28v3.87a1.28,1.28,0,0,0,1.28,1.28H306a1.28,1.28,0,0,0,1.28-1.28V132.9A1.28,1.28,0,0,0,306,131.62Z"
          />
          <path
            class="cls-66"
            d="M297.77,134.3l2.16,2.15a1.69,1.69,0,0,0,.63-.38l-2.41-2.41A1.88,1.88,0,0,0,297.77,134.3Z"
          />
          <path
            class="cls-67"
            d="M297.69,134.83a1.93,1.93,0,0,0,.05.42l1.24,1.23a1.4,1.4,0,0,0,.41.06,1.61,1.61,0,0,0,.54-.09l-2.16-2.15A1.89,1.89,0,0,0,297.69,134.83Z"
          />
          <path
            class="cls-66"
            d="M299,136.48l-1.24-1.23A1.7,1.7,0,0,0,299,136.48Z"
          />
          <path
            class="cls-67"
            d="M300.93,135.56l-2.27-2.26a1.82,1.82,0,0,0-.51.36l2.41,2.41A1.71,1.71,0,0,0,300.93,135.56Z"
          />
          <path
            class="cls-67"
            d="M299.42,133.13l1.67,1.67A1.7,1.7,0,0,0,299.42,133.13Z"
          />
          <path
            class="cls-68"
            d="M301.1,134.83v0l-1.67-1.67h0a1.63,1.63,0,0,0-.73.17l2.27,2.26A1.78,1.78,0,0,0,301.1,134.83Z"
          />
          <path
            class="cls-66"
            d="M302.69,134.3l2.15,2.15a1.69,1.69,0,0,0,.63-.38l-2.4-2.41A1.73,1.73,0,0,0,302.69,134.3Z"
          />
          <path
            class="cls-67"
            d="M302.6,134.83a1.93,1.93,0,0,0,0,.42l1.24,1.23a1.4,1.4,0,0,0,.41.06,1.61,1.61,0,0,0,.54-.09l-2.15-2.15A1.6,1.6,0,0,0,302.6,134.83Z"
          />
          <path
            class="cls-66"
            d="M303.89,136.48l-1.24-1.23A1.7,1.7,0,0,0,303.89,136.48Z"
          />
          <path
            class="cls-67"
            d="M305.84,135.56l-2.27-2.26a1.64,1.64,0,0,0-.5.36l2.4,2.41A1.55,1.55,0,0,0,305.84,135.56Z"
          />
          <path
            class="cls-67"
            d="M304.34,133.13,306,134.8A1.71,1.71,0,0,0,304.34,133.13Z"
          />
          <path
            class="cls-68"
            d="M306,134.83v0l-1.67-1.67h0a1.66,1.66,0,0,0-.73.17l2.27,2.26A1.63,1.63,0,0,0,306,134.83Z"
          />
          <path
            class="cls-69"
            d="M304.3,133.13a1.71,1.71,0,1,0,1.71,1.7A1.7,1.7,0,0,0,304.3,133.13Zm-.17,2.9a1.37,1.37,0,1,1,1.37-1.37A1.37,1.37,0,0,1,304.13,136Z"
          />
          <path
            class="cls-69"
            d="M299.39,133.13a1.71,1.71,0,0,0,0,3.41,1.71,1.71,0,1,0,0-3.41Zm-.17,2.9a1.37,1.37,0,1,1,1.37-1.37A1.37,1.37,0,0,1,299.22,136Z"
          />
          <rect
            class="cls-48"
            x="258.75"
            y="105.45"
            width="2.46"
            height="1.84"
          />
          <rect
            class="cls-51"
            x="259.98"
            y="107.28"
            width="1.23"
            height="28.49"
          />
          <rect
            class="cls-48"
            x="258.75"
            y="135.77"
            width="2.46"
            height="1.84"
          />
          <rect
            class="cls-49"
            x="260.59"
            y="105.45"
            width="0.61"
            height="1.84"
          />
          <rect
            class="cls-49"
            x="260.59"
            y="135.77"
            width="0.61"
            height="1.84"
          />
          <rect
            class="cls-48"
            x="308.94"
            y="105.45"
            width="2.46"
            height="1.84"
          />
          <rect
            class="cls-49"
            x="308.94"
            y="105.45"
            width="0.61"
            height="1.84"
          />
          <rect
            class="cls-51"
            x="308.94"
            y="107.28"
            width="1.23"
            height="28.49"
          />
          <rect
            class="cls-48"
            x="308.94"
            y="135.77"
            width="2.46"
            height="1.84"
          />
          <rect
            class="cls-49"
            x="308.94"
            y="135.77"
            width="0.61"
            height="1.84"
          />
          <polygon
            class="cls-47"
            points="261.21 140.91 261.21 150.97 261.61 150.97 261.61 148.17 274.32 148.17 275.37 142.74 294.78 142.74 295.83 148.17 308.53 148.17 308.53 150.97 308.94 150.97 308.94 140.91 261.21 140.91"
          />
          <polygon
            class="cls-70"
            points="258.7 140.21 258.7 147.47 273.85 147.47 274.94 142.04 295.2 142.04 296.3 147.47 311.45 147.47 311.45 140.21 258.7 140.21"
          />
          <polygon
            class="cls-71"
            points="310.56 140.21 310.56 145.73 296.65 145.73 295.71 141.25 274.43 141.25 273.49 145.73 259.59 145.73 259.59 140.21 258.7 140.21 258.7 147.47 273.85 147.47 274.94 142.04 295.2 142.04 296.3 147.47 311.45 147.47 311.45 140.21 310.56 140.21"
          />
          <path
            class="cls-72"
            d="M303.67,99,291,95.87h-11.8s-9.36,2.32-12.7,3.09c-2.66.61-4.42,1-5,3.27h47.28C308.16,100,306.49,99.64,303.67,99Zm-39.78,2.83c.55-1.78,2.13-2.13,4.51-2.61,2.2-.45,7.34-1.57,9.89-2.13v2.2h13.54V97l9.91,2.14c2.53.54,4,.86,4.53,2.61Z"
          />
          <path
            class="cls-73"
            d="M265,101.42s.75-1,4.43-1.77c4.74-1,7.7-1.46,7.7-1.46l-8.34,2.37,36.9.86Z"
          />
          <path
            class="cls-74"
            d="M260.87,151.8h48.57S284.93,152.76,260.87,151.8Z"
          />
          <polygon
            class="cls-75"
            points="263.19 153.79 261.61 161.16 263.76 154.65 284.54 153.79 263.19 153.79"
          />
          <path
            class="cls-76"
            d="M285.1,150.73c-3.09,0-3.57-3.92-3.63-5.67v6.59h7.2v-6.39C288.6,147.1,288.07,150.73,285.1,150.73Z"
          />
          <path
            class="cls-76"
            d="M279.64,150c-1.58,0-1.82-3.32-1.85-4.81v5.59h3.68v-5.42C281.43,146.87,281.16,150,279.64,150Z"
          />
          <path
            class="cls-76"
            d="M290.53,150.28c-1.58,0-1.83-3.32-1.86-4.8v5.59h3.69v-5.42C292.32,147.21,292.05,150.28,290.53,150.28Z"
          />
          <path
            class="cls-49"
            d="M310.59,162l-25.52,5.53L259.56,162l-.4,1.76,25.91,5.63L311,164Zm-25.52,7.08-25.61-5.66.33-1.11,25.28,5.44Z"
          />
          <rect
            class="cls-64"
            x="278.57"
            y="93.06"
            width="13.01"
            height="5.61"
          />
          <path
            class="cls-77"
            d="M285.07,97.91c-6.06,0-6.48-3.91-6.5-4.71v5.47h13V93.2C291.55,94,291.14,97.91,285.07,97.91Z"
          />
          <path
            class="cls-77"
            d="M284,96.09a1.93,1.93,0,1,1-1.93-1.92A1.92,1.92,0,0,1,284,96.09Z"
          />
          <path
            class="cls-77"
            d="M289.91,96.09A1.93,1.93,0,1,1,288,94.17,1.92,1.92,0,0,1,289.91,96.09Z"
          />
          <path
            class="cls-50"
            d="M284,95.87a1.93,1.93,0,1,1-1.93-1.93A1.92,1.92,0,0,1,284,95.87Z"
          />
          <path
            class="cls-77"
            d="M283.28,95.87a1.17,1.17,0,1,1-1.17-1.16A1.16,1.16,0,0,1,283.28,95.87Z"
          />
          <path
            class="cls-57"
            d="M283.06,95.87a.95.95,0,1,1-.95-1A.95.95,0,0,1,283.06,95.87Z"
          />
          <path
            class="cls-50"
            d="M289.91,95.87A1.93,1.93,0,1,1,288,93.94,1.92,1.92,0,0,1,289.91,95.87Z"
          />
          <path
            class="cls-77"
            d="M289.14,95.87A1.16,1.16,0,1,1,288,94.71,1.16,1.16,0,0,1,289.14,95.87Z"
          />
          <path
            class="cls-57"
            d="M288.93,95.87a.95.95,0,1,1-.95-1A.95.95,0,0,1,288.93,95.87Z"
          />
          <path
            class="cls-78"
            d="M278.71,93.2h12.73A51.67,51.67,0,0,1,278.71,93.2Z"
          />
          <polygon
            class="cls-74"
            points="284.85 168.8 260.04 162.5 284.85 167.94 284.85 168.8"
          />
        </g>
        <g id="Medidas">
          <text class="cls-79" transform="translate(273.56 227.03)">{{ paramSelected.values_params.earthwork.avg_earthwork_width }} m</text>
          <text class="cls-79" transform="translate(143.62 235.37)">{{ paramSelected.values_params.others.aquisition_clearance }} m</text>
          <line class="cls-80" x1="136" y1="223.19" x2="175.08" y2="223.19" />
          <polygon
            class="cls-57"
            points="137.99 224.88 136.29 223.19 137.99 221.49 136.55 221.49 134.86 223.19 136.55 224.88 137.99 224.88"
          />
          <polygon
            class="cls-57"
            points="173.08 224.88 174.78 223.19 173.08 221.49 174.52 221.49 176.22 223.19 174.52 224.88 173.08 224.88"
          />
          <line
            class="cls-81"
            x1="134.86"
            y1="220.09"
            x2="134.86"
            y2="226.29"
          />
          <line
            class="cls-81"
            x1="176.22"
            y1="220.09"
            x2="176.22"
            y2="226.29"
          />
          <line
            class="cls-80"
            x1="227.36"
            y1="216.32"
            x2="341.01"
            y2="216.32"
          />
          <polygon
            class="cls-57"
            points="229.35 218.01 227.66 216.32 229.35 214.62 227.91 214.62 226.22 216.32 227.91 218.01 229.35 218.01"
          />
          <polygon
            class="cls-57"
            points="339.01 218.01 340.71 216.32 339.01 214.62 340.45 214.62 342.15 216.32 340.45 218.01 339.01 218.01"
          />
          <line
            class="cls-81"
            x1="226.22"
            y1="213.22"
            x2="226.22"
            y2="219.42"
          />
          <line
            class="cls-81"
            x1="342.15"
            y1="213.22"
            x2="342.15"
            y2="219.42"
          />
          <line
            class="cls-57"
            x1="134.86"
            y1="199.32"
            x2="284.54"
            y2="199.35"
          />
          <line class="cls-80" x1="136" y1="199.32" x2="283.4" y2="199.35" />
          <polygon
            class="cls-57"
            points="137.99 201.01 136.29 199.32 137.99 197.62 136.55 197.62 134.86 199.32 136.55 201.01 137.99 201.01"
          />
          <polygon
            class="cls-57"
            points="281.41 201.04 283.1 199.35 281.41 197.65 282.85 197.65 284.54 199.35 282.85 201.04 281.41 201.04"
          />
          <line
            class="cls-81"
            x1="134.86"
            y1="196.22"
            x2="134.86"
            y2="202.42"
          />
          <line
            class="cls-81"
            x1="435.37"
            y1="196.22"
            x2="435.37"
            y2="202.42"
          />
          <text class="cls-79" transform="translate(206.87 211.33)">{{paramSelected.values_params.others.aquisition_offset}} m</text>
          <line
            class="cls-81"
            x1="285.15"
            y1="196.21"
            x2="285.15"
            y2="202.41"
          />
          <text class="cls-79" transform="translate(335.44 210.81)">{{paramSelected.values_params.others.aquisition_offset}} m</text>
          <line class="cls-57" x1="285.76" y1="199.3" x2="435.45" y2="199.33" />
          <line class="cls-80" x1="286.91" y1="199.3" x2="434.31" y2="199.33" />
          <polygon
            class="cls-57"
            points="288.9 200.99 287.2 199.3 288.9 197.6 287.46 197.6 285.76 199.3 287.46 200.99 288.9 200.99"
          />
          <polygon
            class="cls-57"
            points="432.31 201.02 434.01 199.33 432.31 197.63 433.75 197.63 435.45 199.33 433.75 201.02 432.31 201.02"
          />
          <text class="cls-79" transform="translate(403.46 235.07)">{{ paramSelected.values_params.others.aquisition_clearance }} m</text>
          <line
            class="cls-80"
            x1="395.84"
            y1="222.88"
            x2="434.92"
            y2="222.88"
          />
          <polygon
            class="cls-57"
            points="397.83 224.58 396.14 222.88 397.83 221.19 396.39 221.19 394.7 222.88 396.39 224.58 397.83 224.58"
          />
          <polygon
            class="cls-57"
            points="432.92 224.58 434.62 222.88 432.92 221.19 434.36 221.19 436.06 222.88 434.36 224.58 432.92 224.58"
          />
          <line class="cls-81" x1="394.7" y1="219.78" x2="394.7" y2="225.98" />
          <line
            class="cls-81"
            x1="436.06"
            y1="219.78"
            x2="436.06"
            y2="225.98"
          />
        </g>
      </g>
    </svg>
    <v-row>
      <v-col>
        <v-row id="ValuesParamsOthersAquisitionOffset">
          <v-col style="align-self: center"> {{ selectedLanguage['platformAquisition1Lane_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.others.aquisition_offset
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkAvgEarthworkWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['platformAquisition1Lane_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.avg_earthwork_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsOthersAquisitionClearance">
          <v-col style="align-self: center"> {{ selectedLanguage['platformAquisition1Lane_component_text7'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.others.aquisition_clearance
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["paramSelected","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
.cls-1,
.cls-6 {
  fill: #eff2ff;
}
.cls-1,
.cls-2 {
  clip-rule: evenodd;
}
.cls-2,
.cls-80 {
  fill: none;
}
.cls-3 {
  isolation: isolate;
}
.cls-4 {
  fill: #b3daf6;
}
.cls-5 {
  fill: #d6e4f6;
  opacity: 0.35;
}
.cls-12,
.cls-14,
.cls-15,
.cls-16,
.cls-18,
.cls-19,
.cls-20,
.cls-21,
.cls-22,
.cls-23,
.cls-24,
.cls-25,
.cls-26,
.cls-28,
.cls-29,
.cls-30,
.cls-32,
.cls-33,
.cls-34,
.cls-35,
.cls-36,
.cls-38,
.cls-5,
.cls-6 {
  fill-rule: evenodd;
}
.cls-7 {
  clip-path: url(#clip-path);
}
.cls-8 {
  clip-path: url(#clip-path-2);
}
.cls-9 {
  clip-path: url(#clip-path-3);
}
.cls-10 {
  clip-path: url(#clip-path-4);
}
.cls-11 {
  opacity: 0.92;
}
.cls-12 {
  fill: #c96757;
}
.cls-13 {
  clip-path: url(#clip-path-5);
}
.cls-14 {
  fill: #b55d4e;
}
.cls-15 {
  fill: #da6a57;
}
.cls-16 {
  fill: #af5242;
}
.cls-17 {
  clip-path: url(#clip-path-6);
}
.cls-18 {
  fill: #9e4e40;
}
.cls-19 {
  fill: #bb5d4d;
}
.cls-20 {
  fill: #1b5474;
}
.cls-21 {
  fill: #1f5d7e;
}
.cls-22 {
  fill: #164662;
}
.cls-23 {
  fill: #265f74;
}
.cls-24 {
  fill: #1f687e;
}
.cls-25 {
  fill: #164e62;
}
.cls-26 {
  fill: #754159;
}
.cls-27 {
  clip-path: url(#clip-path-7);
}
.cls-28 {
  fill: #6f3b53;
}
.cls-29 {
  fill: #7e4d64;
}
.cls-30 {
  fill: #78405c;
}
.cls-31 {
  clip-path: url(#clip-path-8);
}
.cls-32 {
  fill: #5a2b47;
}
.cls-33 {
  fill: #522842;
}
.cls-34 {
  fill: #602e4b;
}
.cls-35 {
  fill: #542943;
}
.cls-36 {
  fill: #3f1f35;
}
.cls-37 {
  clip-path: url(#clip-path-9);
}
.cls-38 {
  fill: #4f2640;
}
.cls-39 {
  clip-path: url(#clip-path-10);
}
.cls-40 {
  clip-path: url(#clip-path-11);
}
.cls-41 {
  fill: #7c7c7c;
  stroke: #c9c9c9;
  opacity: 0.31;
}
.cls-41,
.cls-80,
.cls-81 {
  stroke-miterlimit: 10;
}
.cls-42 {
  fill: #875116;
}
.cls-43 {
  fill: #ccc;
}
.cls-44 {
  fill: #666;
}
.cls-45 {
  fill: #3d3d3d;
}
.cls-46 {
  fill: #494949;
}
.cls-47,
.cls-64,
.cls-77,
.cls-78 {
  fill: #565655;
}
.cls-47,
.cls-56,
.cls-71,
.cls-76 {
  opacity: 0.6;
}
.cls-47,
.cls-49,
.cls-56,
.cls-58,
.cls-63,
.cls-66,
.cls-68,
.cls-69,
.cls-71,
.cls-72,
.cls-76,
.cls-77 {
  mix-blend-mode: multiply;
}
.cls-48,
.cls-49,
.cls-59,
.cls-74,
.cls-76 {
  fill: #e6971a;
}
.cls-50,
.cls-75 {
  fill: #303030;
}
.cls-51 {
  fill: #000200;
}
.cls-52 {
  fill: #393939;
}
.cls-53 {
  fill: #be7c19;
}
.cls-54,
.cls-72,
.cls-73 {
  fill: #191919;
}
.cls-55,
.cls-56,
.cls-60 {
  fill: #17317e;
}
.cls-57,
.cls-79,
.cls-81 {
  fill: #fff;
}
.cls-58 {
  fill: #c1c1c1;
}
.cls-59,
.cls-62,
.cls-77 {
  opacity: 0.5;
}
.cls-59,
.cls-60,
.cls-62,
.cls-67,
.cls-73,
.cls-74,
.cls-75,
.cls-78 {
  mix-blend-mode: screen;
}
.cls-60 {
  opacity: 0.4;
}
.cls-61,
.cls-62 {
  fill: #507b88;
}
.cls-63 {
  fill: #bf0a1a;
}
.cls-63,
.cls-72 {
  opacity: 0.8;
}
.cls-65,
.cls-66,
.cls-67,
.cls-68,
.cls-69 {
  fill: #f6b510;
}
.cls-66 {
  opacity: 0.1;
}
.cls-67,
.cls-68 {
  opacity: 0.3;
}
.cls-70,
.cls-71 {
  fill: #151515;
}
.cls-79 {
  font-size: 12px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
.cls-80,
.cls-81 {
  stroke: #fff;
}
</style>