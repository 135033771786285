<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.05 87.01" id="ValuesParamsPlatformSVG5">
      <title>bridge_section_2lanes</title>
      <g id="Lastro">
        <polyline
          class="cls-1"
          points="5.77 27.12 5.77 9.43 6.23 9.43 6.23 9.43 6.23 9.43 6.23 9.43 6.23 9.43 286.86 9.72 286.86 9.72 286.86 9.72 286.86 9.72 286.86 9.72 286.86 9.72 286.86 27.12"
        />
      </g>
      <g id="Dormente">
        <rect class="cls-2" x="30.31" y="9.2" width="79.34" height="6.88" />
        <rect class="cls-2" x="183.48" y="9.43" width="79.11" height="6.65" />
      </g>
      <g id="Estrutura">
        <path
          class="cls-3"
          d="M303.49,76.3"
          transform="translate(-9.47 -10.87)"
        />
        <path
          class="cls-3"
          d="M10.21,33.92"
          transform="translate(-9.47 -10.87)"
        />
        <path
          class="cls-3"
          d="M10.05,48c16.73,0,15.49,1,32.25,1,3.73,0,109.78.46,113.51.46h0l100-.23h0c3.73,0,42-1.61,45.75-1.61"
          transform="translate(-9.47 -10.87)"
        />
        <polygon
          class="cls-2"
          points="0.57 37.09 0.57 46.12 5.77 46.12 5.77 46.12 33.06 65.59 33.06 65.59 146.33 65.38 146.33 65.38 260.52 65.38 260.52 65.38 286.89 47.37 286.89 47.37 292.05 47.27 292.05 36.72 292.05 36.72 292.05 0 286.86 0 286.86 23.33 286.86 23.33 146.33 25.71 5.77 23.19 5.77 23.19 5.77 0.11 0.57 0.11 0.57 37.09 0.57 37.09"
        />
      </g>
      <g id="Trilho">
        <polygon
          class="cls-4"
          points="53.37 8.45 53.37 9.93 48.47 9.93 48.47 8.45 50.26 8.45 50.26 4.25 49.6 4.25 49.6 1.61 52.24 1.61 52.24 4.25 51.58 4.25 51.58 8.45 53.37 8.45"
        />
        <polygon
          class="cls-4"
          points="95.37 8.45 95.37 9.93 90.47 9.93 90.47 8.45 92.26 8.45 92.26 4.25 91.6 4.25 91.6 1.61 94.24 1.61 94.24 4.25 93.58 4.25 93.58 8.45 95.37 8.45"
        />
        <polygon
          class="cls-4"
          points="207.37 8.45 207.37 9.93 202.47 9.93 202.47 8.45 204.26 8.45 204.26 4.25 203.6 4.25 203.6 1.61 206.24 1.61 206.24 4.25 205.58 4.25 205.58 8.45 207.37 8.45"
        />
        <polygon
          class="cls-4"
          points="249.37 8.45 249.37 9.93 244.47 9.93 244.47 8.45 246.26 8.45 246.26 4.25 245.6 4.25 245.6 1.61 248.24 1.61 248.24 4.25 247.58 4.25 247.58 8.45 249.37 8.45"
        />
      </g>
      <g id="Medidas">
        <line class="cls-5" x1="0.25" y1="77.79" x2="0.25" y2="87.01" />
        <line class="cls-5" x1="291.54" y1="77.79" x2="291.54" y2="86.55" />
        <text class="cls-6" transform="translate(134.36 80.25)">{{paramSelected.values_params.structure.bridge_2lanes_width}} m</text>
        <line class="cls-5" x1="1.82" y1="82.99" x2="289.97" y2="82.37" />
        <polygon
          points="2.82 83.83 1.97 82.99 2.82 82.14 2.1 82.14 1.25 82.99 2.1 83.83 2.82 83.83"
        />
        <polygon
          points="288.98 83.22 289.82 82.37 288.97 81.52 289.69 81.52 290.54 82.37 289.7 83.22 288.98 83.22"
        />
      </g>
    </svg>
    <v-row>
      <v-col>
        <v-row id="ValuesParamsStructureBridgeWidth">
          <v-col style="align-self: center;"> {{ selectedLanguage['platformBridge1Lane_component_text5'] }} </v-col>
          <v-col style="align-self: center;">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.structure.bridge_2lanes_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center;"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["paramSelected","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #ccc;
}
.cls-2 {
  fill: #666;
}
.cls-3,
.cls-5 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
}
.cls-4 {
  fill: #3d3d3d;
}
.cls-5 {
  stroke-width: 0.5px;
}
.cls-6 {
  font-size: 14px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
</style>