<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <svg
      id="ValuesParamsPlatformSVG7"
      data-name="Seção 2 Pistas"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 293.44 150.59"
    >
      <title>tunnel_section_2lanes</title>
      <g id="Lastro">
        <polyline
          class="cls-1"
          points="38.47 130.73 38.47 116.29 38.47 116.29 60.13 116.29 60.34 116.29 268.77 116.29 268.77 116.29 290.44 116.38 290.44 116.38 290.44 130.06"
        />
      </g>
      <g id="Estrutura">
        <polyline
          class="cls-2"
          points="38.47 65.26 38.47 131.36 290.44 131.36 290.44 63.2"
        />
        <path
          class="cls-2"
          d="M42,73c0-34.63,56.41-62.71,126-62.71S294,38.32,294,73"
          transform="translate(-3.53 -7.24)"
        />
      </g>
      <g id="Dormente">
        <rect class="cls-3" x="59.93" y="116.5" width="71.4" height="6.88" />
        <rect class="cls-3" x="197.78" y="116.29" width="71.2" height="6.65" />
      </g>
      <g id="Trilho">
        <polygon
          class="cls-4"
          points="77.08 115.02 77.08 116.5 72.18 116.5 72.18 115.02 73.97 115.02 73.97 110.81 73.31 110.81 73.31 108.18 75.95 108.18 75.95 110.81 75.29 110.81 75.29 115.02 77.08 115.02"
        />
        <polygon
          class="cls-4"
          points="119.08 115.02 119.08 116.5 114.18 116.5 114.18 115.02 115.97 115.02 115.97 110.81 115.31 110.81 115.31 108.18 117.95 108.18 117.95 110.81 117.29 110.81 117.29 115.02 119.08 115.02"
        />
        <polygon
          class="cls-4"
          points="215.49 115.02 215.49 116.5 210.59 116.5 210.59 115.02 212.38 115.02 212.38 110.81 211.72 110.81 211.72 108.18 214.36 108.18 214.36 110.81 213.7 110.81 213.7 115.02 215.49 115.02"
        />
        <polygon
          class="cls-4"
          points="257.49 115.02 257.49 116.5 252.59 116.5 252.59 115.02 254.38 115.02 254.38 110.81 253.72 110.81 253.72 108.18 256.36 108.18 256.36 110.81 255.7 110.81 255.7 115.02 257.49 115.02"
        />
      </g>
      <g id="Medidas">
        <path
          class="cls-5"
          d="M295.65,153.21"
          transform="translate(-3.53 -7.24)"
        />
        <path
          class="cls-5"
          d="M39.67,153.21"
          transform="translate(-3.53 -7.24)"
        />
        <line class="cls-5" x1="36.54" y1="141.38" x2="36.54" y2="150.59" />
        <line class="cls-5" x1="291.71" y1="141.38" x2="291.71" y2="150.14" />
        <text class="cls-6" transform="translate(153.45 143.84) scale(0.9 1)">
          {{paramSelected.values_params.structure.tunnel_2lanes_width}} m
        </text>
        <text class="cls-6" transform="translate(0 33.99) scale(0.9 1)">
          3.25m
        </text>
        <text class="cls-6" transform="translate(5.58 96.97) scale(0.9 1)">
          5.1m
        </text>
        <path
          class="cls-5"
          d="M31.85,141.68"
          transform="translate(-3.53 -7.24)"
        />
        <path
          class="cls-5"
          d="M31.85,69.23"
          transform="translate(-3.53 -7.24)"
        />
        <path
          class="cls-5"
          d="M31.85,11.24"
          transform="translate(-3.53 -7.24)"
        />
        <line class="cls-5" x1="25.29" y1="61.34" x2="31.62" y2="61.34" />
        <line class="cls-5" x1="25.07" y1="3" x2="31.41" y2="3" />
        <line class="cls-5" x1="25.42" y1="134.43" x2="31.76" y2="134.43" />
        <line class="cls-5" x1="28.31" y1="60.36" x2="28.31" y2="3.74" />
        <polygon
          points="29.16 59.36 28.31 60.21 27.47 59.36 27.47 60.08 28.31 60.93 29.16 60.08 29.16 59.36"
        />
        <polygon
          points="29.16 4.74 28.31 3.89 27.47 4.74 27.47 4.02 28.31 3.17 29.16 4.02 29.16 4.74"
        />
        <line class="cls-5" x1="28.65" y1="133.79" x2="28.37" y2="62.19" />
        <polygon
          points="29.49 132.79 28.64 133.65 27.79 132.8 27.8 133.52 28.65 134.36 29.49 133.51 29.49 132.79"
        />
        <polygon
          points="29.23 63.19 28.38 62.34 27.53 63.19 27.53 62.47 28.37 61.62 29.22 62.47 29.23 63.19"
        />
        <line class="cls-5" x1="37.11" y1="145.6" x2="291.14" y2="146.14" />
        <polygon
          points="38.1 146.45 37.26 145.6 38.11 144.75 37.39 144.75 36.54 145.59 37.38 146.44 38.1 146.45"
        />
        <polygon
          points="290.14 146.98 290.99 146.14 290.14 145.29 290.86 145.29 291.71 146.14 290.86 146.98 290.14 146.98"
        />
      </g>
    </svg>
    <v-row>
      <v-col>
        <v-row id="ValuesParamsStructureTunnelWidth">
          <v-col style="align-self: center"> {{selectedLanguage['platformTunnel1Lane_component_text5']}} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.structure.tunnel_2lanes_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["paramSelected","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #ccc;
}
.cls-2,
.cls-5 {
  fill: none;
  stroke-miterlimit: 10;
}
.cls-2 {
  stroke: #666;
  stroke-width: 6px;
}
.cls-3 {
  fill: #666;
}
.cls-4 {
  fill: #3d3d3d;
}
.cls-5 {
  stroke: #000;
  stroke-width: 0.5px;
}
.cls-6 {
  font-size: 12px;
  font-family: MyriadPro-Regular, Myriad Pro;
}
</style>