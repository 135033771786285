<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsEarthSVG2Title">
        <strong> {{ selectedLanguage['earthWorkFill1_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 417.16 199.38" id="ValuesParamsEarthSVG2">
      <title>fill_1lane</title>
      <g id="Terreno">
        <polyline class="cls-1" points="0 199.38 413.49 199.38 413.49 130.04" />
        <line class="cls-2" x1="417.16" y1="130.13" y2="199.38" />
      </g>
      <g id="Aterro">
        <polyline
          class="cls-3"
          points="7.61 199.2 76.47 127.49 126.37 130.75 221.29 20.1 318.37 20.1 410.03 130.75"
        />
      </g>
      <g id="Lastro">
        <polygon
          class="cls-4"
          points="318.37 20.1 300.43 20.06 239.09 20.06 221.29 20.1 230.79 8.53 231.01 8.32 308.05 8.32 308.27 8.53 318.37 20.1"
        />
      </g>
      <g id="Dormente">
        <rect class="cls-5" x="247.41" y="8.32" width="45.49" height="4.25" />
      </g>
      <g id="Trilho">
        <polygon
          id="Direita"
          class="cls-6"
          points="289.08 6.84 289.08 8.32 284.18 8.32 284.18 6.84 285.97 6.84 285.97 2.64 285.31 2.64 285.31 0 287.94 0 287.94 2.64 287.29 2.64 287.29 6.84 289.08 6.84"
        />
        <polygon
          id="Esquerda"
          class="cls-6"
          points="256.31 6.84 256.31 8.32 251.41 8.32 251.41 6.84 253.2 6.84 253.2 2.64 252.54 2.64 252.54 0 255.17 0 255.17 2.64 254.51 2.64 254.51 6.84 256.31 6.84"
        />
      </g>
      <g id="Medidas">
        <text class="cls-7" transform="translate(33.28 78.35)">{{ paramSelected.values_params.earthwork.fill_height }} m</text>
        <text class="cls-8" transform="translate(90.99 152.47)">{{ paramSelected.values_params.earthwork.fill_enbankment_width }} m</text>
        <text class="cls-7" transform="translate(361.23 45.35)">{{ Math.round(
              (1 / paramSelected.values_params.earthwork.fill_factor) * 10
            ) / 10 }}</text>
        <text class="cls-7" transform="translate(379.43 69.12)">1</text>
        <text class="cls-7" transform="translate(89.23 116.16)">{{ paramSelected.values_params.earthwork.fill_enbankment_slope }} %</text>
        <path
          class="cls-9"
          d="M368.36,96.17V68.78l-21.14,0Z"
          transform="translate(8.07 -18.67)"
        />
        <rect x="61.19" y="130.52" width="6.69" height="0.46" />
        <rect x="61.19" y="19.87" width="6.69" height="0.46" />
        <rect x="76.89" y="122.29" width="48.96" height="0.46" />
        <polygon
          points="124.93 123.3 125.71 122.52 124.93 121.74 125.59 121.74 126.37 122.52 125.59 123.3 124.93 123.3"
        />
        <line class="cls-10" x1="78.41" y1="133.81" x2="125.43" y2="133.88" />
        <polygon
          class="cls-11"
          points="79.4 134.66 78.56 133.81 79.41 132.97 78.69 132.96 77.84 133.81 78.68 134.66 79.4 134.66"
        />
        <polygon
          class="cls-11"
          points="124.43 134.73 125.28 133.88 124.43 133.03 125.15 133.03 126 133.88 125.15 134.73 124.43 134.73"
        />
        <line class="cls-12" x1="64.5" y1="129.81" x2="64.5" y2="20.95" />
        <polygon
          points="65.35 128.82 64.5 129.66 63.65 128.82 63.65 129.54 64.5 130.38 65.35 129.54 65.35 128.82"
        />
        <polygon
          points="65.35 21.95 64.5 21.1 63.65 21.95 63.65 21.23 64.5 20.38 65.35 21.23 65.35 21.95"
        />
        <rect class="cls-11" x="76.92" y="130.47" width="0.46" height="6.69" />
        <rect class="cls-11" x="125.92" y="130.47" width="0.46" height="6.69" />
      </g>
    </svg>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsEarthworkFillHeight">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.earthwork.fill_height"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillFactor">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.earthwork.fill_factor"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text7'] }} </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillEnbankmentSlope">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text8'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.fill_enbankment_slope
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillEnbankmentWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text9'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.fill_enbankment_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["paramSelected","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.cls-1 {
  fill: #3e2d00;
}
.cls-10,
.cls-12,
.cls-2,
.cls-9 {
  fill: none;
}
.cls-3 {
  fill: #875116;
}
.cls-4 {
  fill: #ccc;
}
.cls-5 {
  fill: #666;
}
.cls-6 {
  fill: #3d3d3d;
}
.cls-7,
.cls-8 {
  font-size: 18px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
.cls-11,
.cls-8 {
  fill: #fff;
}
.cls-12,
.cls-9 {
  stroke: #000;
}
.cls-10,
.cls-12,
.cls-9 {
  stroke-miterlimit: 10;
}
.cls-10 {
  stroke: #fff;
}
.cls-10,
.cls-12 {
  stroke-width: 0.5px;
}
</style>