<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsPlatformSVG1Title">
        <strong> {{ selectedLanguage['platform1Lane_railway_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <br />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 322.37 94.73" id="ValuesParamsPlatformStructureSVG2">
      <title>{{ selectedLanguage['platform1Lane_railway_component_text2'] }}</title>
      <g id="Sublastro">
        <polyline
          class="cls-1"
          points="1 51.85 9.77 41.13 9.97 40.94 81.2 40.8 271.41 40.6 280.77 51.29"
        />
      </g>
      <g id="Lastro">
        <polygon
          class="cls-2"
          points="250.51 41.48 233.92 41.44 177.2 41.44 160.74 41.48 169.53 30.77 169.73 30.59 240.96 30.59 241.17 30.77 250.51 41.48"
        />
        <polygon
          class="cls-2"
          points="122.72 41.2 106.13 41.16 49.41 41.16 32.95 41.2 41.74 30.49 41.94 30.3 113.17 30.3 113.38 30.49 122.72 41.2"
        />
      </g>
      <g id="Dormente">
        <rect class="cls-3" x="57.1" y="30.31" width="42.06" height="3.93" />
        <rect class="cls-3" x="184.89" y="30.59" width="42.06" height="3.93" />
      </g>
      <g id="Trilho">
        <polygon
          class="cls-4"
          points="223.42 29.22 223.42 30.59 218.89 30.59 218.89 29.22 220.55 29.22 220.55 25.33 219.94 25.33 219.94 22.89 222.38 22.89 222.38 25.33 221.77 25.33 221.77 29.22 223.42 29.22"
        />
        <polygon
          class="cls-4"
          points="95.63 28.94 95.63 30.3 91.1 30.3 91.1 28.94 92.76 28.94 92.76 25.05 92.15 25.05 92.15 22.61 94.58 22.61 94.58 25.05 93.98 25.05 93.98 28.94 95.63 28.94"
        />
        <polygon
          class="cls-4"
          points="65.33 28.94 65.33 30.3 60.8 30.3 60.8 28.94 62.46 28.94 62.46 25.05 61.85 25.05 61.85 22.61 64.28 22.61 64.28 25.05 63.67 25.05 63.67 28.94 65.33 28.94"
        />
        <polygon
          class="cls-4"
          points="193.12 29.22 193.12 30.59 188.59 30.59 188.59 29.22 190.25 29.22 190.25 25.33 189.64 25.33 189.64 22.89 192.07 22.89 192.07 25.33 191.47 25.33 191.47 29.22 193.12 29.22"
        />
      </g>
      <g id="Medidas">
        <line class="cls-5" x1="54.71" y1="22.23" x2="59.23" y2="24.84" />
        <path
          d="M94.94,31.47a3.65,3.65,0,0,1,.45-2.37,6.8,6.8,0,0,0,1.26,3.36,7.22,7.22,0,0,0-3.54.58A3.83,3.83,0,0,1,94.94,31.47Z"
          transform="translate(-35.88 -6.73)"
        />
        <text class="cls-6" transform="translate(20.52 18.65)">{{ paramSelected.values_params.superstructure.rail_density }} kg/m</text>
        <line class="cls-5" x1="122.74" y1="65.48" x2="110.31" y2="65.48" />
        <polygon
          points="111.31 64.63 110.46 65.48 111.31 66.33 110.59 66.33 109.74 65.48 110.59 64.63 111.31 64.63"
        />
        <line class="cls-5" x1="159.39" y1="65.14" x2="171.81" y2="65.14" />
        <polygon
          points="170.82 65.98 171.67 65.14 170.82 64.29 171.54 64.29 172.38 65.14 171.54 65.98 170.82 65.98"
        />
        <text class="cls-6" transform="translate(109.35 61.47)">{{ paramSelected.values_params.platform.platform_slope }} %</text>
        <text class="cls-6" transform="translate(158.78 61.47)">{{ paramSelected.values_params.platform.platform_slope }} %</text>
        <line class="cls-5" x1="122.24" y1="31.65" x2="122.24" y2="40.87" />
        <line class="cls-5" x1="160.53" y1="32.65" x2="160.53" y2="41.42" />
        <text class="cls-6" transform="translate(131.35 33.12)">{{ paramSelected.values_params.platform.median_width }} m</text>
        <line class="cls-5" x1="123.81" y1="36.86" x2="158.96" y2="37.22" />
        <polygon
          points="124.8 37.72 123.96 36.86 124.81 36.02 124.09 36.01 123.24 36.85 124.08 37.71 124.8 37.72"
        />
        <polygon
          points="157.96 38.06 158.81 37.22 157.97 36.37 158.69 36.37 159.53 37.23 158.68 38.07 157.96 38.06"
        />
        <line class="cls-5" x1="1.74" y1="54.15" x2="1.76" y2="63.37" />
        <line class="cls-5" x1="32.03" y1="54.88" x2="32.03" y2="63.64" />
        <text class="cls-6" transform="translate(6.85 71.35)">{{ paramSelected.values_params.platform.leftover }} m</text>
        <line class="cls-5" x1="3.31" y1="59.09" x2="30.46" y2="59.45" />
        <polygon
          points="4.29 59.95 3.46 59.09 4.32 58.25 3.6 58.24 2.74 59.08 3.58 59.94 4.29 59.95"
        />
        <polygon
          points="29.45 60.28 30.31 59.45 29.48 58.59 30.2 58.6 31.03 59.46 30.17 60.29 29.45 60.28"
        />
        <line class="cls-5" x1="249.74" y1="54.88" x2="249.74" y2="64.1" />
        <line class="cls-5" x1="280.03" y1="55.88" x2="280.03" y2="64.64" />
        <text class="cls-6" transform="translate(254.85 72.35)">{{ paramSelected.values_params.platform.leftover }} m</text>
        <line class="cls-5" x1="251.31" y1="60.09" x2="278.46" y2="60.45" />
        <polygon
          points="252.29 60.95 251.46 60.09 252.32 59.25 251.6 59.24 250.74 60.08 251.57 60.94 252.29 60.95"
        />
        <polygon
          points="277.45 61.28 278.31 60.45 277.48 59.59 278.19 59.6 279.03 60.46 278.17 61.29 277.45 61.28"
        />
        <line class="cls-5" x1="170.57" y1="10.69" x2="170.57" y2="19.91" />
        <line class="cls-5" x1="240.87" y1="11.69" x2="240.87" y2="20.45" />
        <text class="cls-6" transform="translate(190.65 10.04) rotate(0.4)">
          {{ paramSelected.values_params.superstructure.ballast_width }} m
        </text>
        <line class="cls-5" x1="172.15" y1="15.89" x2="239.3" y2="16.26" />
        <polygon
          points="173.14 16.75 172.29 15.89 173.15 15.05 172.43 15.05 171.57 15.89 172.42 16.74 173.14 16.75"
        />
        <polygon
          points="238.29 17.11 239.15 16.26 238.3 15.41 239.02 15.42 239.87 16.27 239.01 17.11 238.29 17.11"
        />
        <rect class="cls-7" x="282.54" y="40.94" width="6.69" height="0.46" />
        <rect class="cls-7" x="282.54" y="50.94" width="6.69" height="0.46" />
        <line class="cls-5" x1="285.89" y1="50.05" x2="285.89" y2="42.3" />
        <polygon
          points="286.73 49.05 285.88 49.9 285.04 49.05 285.04 49.77 285.88 50.62 286.73 49.77 286.73 49.05"
        />
        <polygon
          points="286.73 43.3 285.88 42.45 285.04 43.3 285.04 42.58 285.88 41.73 286.73 42.58 286.73 43.3"
        />
        <text class="cls-6" transform="translate(295.02 50.28)">{{ paramSelected.values_params.superstructure.subballast_height }} m</text>
        <rect class="cls-7" x="251.37" y="30.94" width="6.69" height="0.46" />
        <rect class="cls-7" x="251.37" y="40.94" width="6.69" height="0.46" />
        <line class="cls-5" x1="254.71" y1="40.05" x2="254.71" y2="32.3" />
        <polygon
          points="255.56 39.05 254.71 39.9 253.86 39.05 253.86 39.77 254.71 40.62 255.56 39.77 255.56 39.05"
        />
        <polygon
          points="255.56 33.3 254.71 32.45 253.86 33.3 253.86 32.58 254.71 31.73 255.56 32.58 255.56 33.3"
        />
        <text class="cls-6" transform="translate(262.84 39.28)">{{paramSelected.values_params.superstructure.left_rail_ballast_height}} m</text>
        <line class="cls-5" x1="107.43" y1="21.81" x2="108.78" y2="26.86" />
        <path
          d="M144.61,33.39a3.65,3.65,0,0,1,2-1.35,6.71,6.71,0,0,0-1.48,3.26,7.25,7.25,0,0,0-2.91-2.09A3.79,3.79,0,0,1,144.61,33.39Z"
          transform="translate(-35.88 -6.73)"
        />
        <text class="cls-6" transform="translate(100.11 18.87)">{{ paramSelected.values_params.superstructure.ballast_density }} t/m³</text>
        <text class="cls-6" transform="translate(0 38.51)">1:1.5</text>
        <line class="cls-5" x1="1.15" y1="74.54" x2="1.17" y2="83.76" />
        <line class="cls-5" x1="280.44" y1="75.27" x2="280.44" y2="84.03" />
        <text class="cls-6" transform="translate(134.26 91.73)">{{ platformWidth }} m</text>
        <line class="cls-5" x1="2.72" y1="79.47" x2="278.87" y2="79.84" />
        <polygon
          points="3.71 80.32 2.87 79.47 3.72 78.62 3 78.62 2.15 79.47 3 80.32 3.71 80.32"
        />
        <polygon
          points="277.87 80.69 278.72 79.84 277.88 79 278.6 79 279.44 79.84 278.59 80.69 277.87 80.69"
        />
        <polygon
          class="cls-5"
          points="30.17 38.93 30.17 30.93 30.67 30.93 36.67 30.93 37.17 30.93 30.17 38.93 30.17 38.93"
        />
      </g>
    </svg>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsPlatformMedianWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text15'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.platform.median_width"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsPlatformLeftover">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text3'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.platform.leftover"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsSuperstructureBallastWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text4'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.superstructure.ballast_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsPlatformPlatformSlope">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.platform.platform_slope"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsSuperstructureRailDensity">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.superstructure.rail_density
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> kg/m </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureBallastDensity">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text7'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.superstructure.ballast_density
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> t/m³ </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureLeftRailBallastHeight">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text8'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.superstructure.left_rail_ballast_height
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> m </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureBallastSlope">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text9'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.superstructure.ballast_slope
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text10'] }} </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureSubballastHeight">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text11'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.superstructure.subballast_height
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> m </v-col>
    </v-row>
    <v-row id="ValuesParamsOthersDmtBallastComercialPavedRoad">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text12'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.others.dmt_ballast_comercial_paved_road
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> Km </v-col>
    </v-row>
    <v-row id="ValuesParamsOthersDmtBallastComercialUnpavedRoad">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text13'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.others.dmt_ballast_comercial_unpaved_road
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> Km </v-col>
    </v-row>
    <v-row id="ValuesParamsOthersDmtBallastLocalGroundRoad">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text14'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.others.dmt_ballast_local_ground_road
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> Km </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["paramSelected","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
    platformWidth: function () {
      let slope_width =
        this.paramSelected.values_params.superstructure.left_rail_ballast_height *
        this.paramSelected.values_params.superstructure.ballast_slope;
      return (
        slope_width * 4 +
        this.paramSelected.values_params.superstructure.ballast_width * 2 +
        this.paramSelected.values_params.platform.leftover * 2 +
        this.paramSelected.values_params.platform.median_width
      ).toFixed(2);
    },
  },
  data() {
    return {
      side: ["Both", "Right", "Left"],
      side_selected: "Both",
    };
  },
  methods: {},
};
</script>

<style scoped>
.cls-1 {
  fill: #c69c6d;
}
.cls-2 {
  fill: #ccc;
}
.cls-3 {
  fill: #666;
}
.cls-4 {
  fill: #3d3d3d;
}
.cls-5 {
  fill: none;
}
.cls-5,
.cls-7 {
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}
.cls-6 {
  font-size: 12px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
</style>